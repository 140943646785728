import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import ShopCell from "./Shop/ShopCell";
import axios from "axios";
import {
	BASE_URL,
	titleForStatus,
	colorCodeForStatus,
	iconForStatus,
} from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import DividerText from "./Components/DividerText";
import ConfirmationView from "./ConfirmationView";
import NeoButton from "./Button/NeoButton";
import ErrorView from "./ErrorView";
import { nameForInsurance } from "./Constants";

class VendorOfferDetails extends React.Component {
	state = {
		isLoading: true,
		offer: [],
		id_card_front: "",
		id_card_back: "",
		isSending: false,
		isSending2: false,
		offerNotFound: false,
	};

	constructor(props) {
		super(props);

		this.loadIDCards = this.loadIDCards.bind(this);
		this.withdrawOffer = this.withdrawOffer.bind(this);
		this.idCardReadable = this.idCardReadable.bind(this);
		this.idCardNotReadable = this.idCardNotReadable.bind(this);
		this.confirmIDCard = this.confirmIDCard.bind(this);
		this.showUploadDocuments = this.showUploadDocuments.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.showFile = this.showFile.bind(this);
		this.showUploadData = this.showUploadData.bind(this);
	}

	componentDidMount() {
		this.loadOfferDetails();
	}

	loadOfferDetails() {
		const { id } = this.props.match.params;

		axios
			.get(BASE_URL + "/vendor/offer/" + id)
			.then((result) => {
				const offer = result.data.data;

				this.setState({
					offer,
					isLoading: false,
				});

				if (offer.status === "id_card_check") {
					this.loadIDCards();
				}
			})
			.catch((error) => {

				if (error.response.status === 404) {
					this.setState({ offerNotFound: true, isLoading: false });
				}
			});
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Anfragedetails" />

				<div className="neo-content">
					{this.state.isLoading ? (
						this.LoadingView()
					) : this.state.displayProfileCompletionView ? (
						<ConfirmationView
							text="Um dieses Angebot anzunehmen, muss Ihr Profil vollständig ausgefüllt sein."
							icon="credit_check"
							route="/profile"
							buttonTitle="Profil öffnen"
						/>
					) : this.state.offerNotFound ? (
						<ErrorView message="Dieses Angebot konnte nicht gefunden werden." />
					) : (
						this.OfferDetails()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	OfferDetails() {
		return (
			<div>
				<p className="neo-largeTitle">{this.state.offer.object_name}</p>

				<p className="neo-callout">
					{this.state.offer.object_manufacturer}

					{this.state.offer.object_number
						? " - Leasingobjekt-ID: " + this.state.offer.object_number
						: null}
				</p>

				{this.state.offer.equipment ? (
					<p className="neo-callout">
						Inklusive Zubehör:
						<br />
						{this.state.offer.equipment}
					</p>
				) : null}

				<p
					className="neo-smallTitle"
					style={{ fontWeight: 400, marginTop: "30px" }}
				>
					Objektpreis: {this.state.offer.object_price} €
				</p>
				<p className="neo-smallTitle" style={{ fontWeight: 400 }}>
					Zubehörpreis: {this.state.offer.equipment_price} €
				</p>
				<p className="neo-smallTitle" style={{ marginBottom: "30px" }}>
					Gesamtpreis: {this.state.offer.total_price} €
				</p>

				<p className="neo-smallTitle">
					{this.state.offer.leasing_rate} € mtl.*
				</p>
				<p className="neo-smallTitle">
					Leasingdauer: {this.state.offer.leasing_duration} Monate
				</p>
				<p className="neo-smallTitle">
					Lieferung: {this.state.offer.delivery == "pickup" ? "Abholung" : "Versand"}
				</p>
				<p className="neo-smallTitle">
					Versicherung: {nameForInsurance(this.state.offer.insurance)} (
					{this.state.offer.insurance_fee} € mtl.)
				</p>

				<div
					className="neo-statusIndicator"
					style={{ color: colorCodeForStatus(this.state.offer.status) }}
				>
					<div
						className="neo-statusIcon"
						style={{
							backgroundImage:
								"url('/" + iconForStatus(this.state.offer.status) + "')",
						}}
					/>
					Status: {titleForStatus(this.state.offer.status)}
				</div>

				{this.StatusArea()}

				{
					(this.state.offer.status === "completed") ?
						this.state.offer.handing_hover != null ?
							this.state.offer.handing_hover.deliver_date != null ?
								<p className="neo-smallTitle" style={{marginBottom:40}}>
									Tatsächliches Übergabedatum: {new Date(this.state.offer.handing_hover.deliver_date).toLocaleDateString()}
									<br />
									Abgeholt von: {this.state.offer.handing_hover.abholer}<br/>
									Personalausweisnummer: {this.state.offer.handing_hover.ausweisnummer}
								</p>
								:
								<NeoButton title="Eingabe Liefer-/ Übergabedatum" onClick={this.showUploadData}/>

						: null
					: null
				}

				<ShopCell
					disableClick={true}
					title={this.state.offer.user.company_name}
					subtitle1={this.state.offer.user.address.street}
					subtitle2={
						this.state.offer.user.address.zip +
						" " +
						this.state.offer.vendor.address.city
					}
					uuid={this.state.offer.user.uuid}
				/>

				{this.IDCards()}

				{this.Files()}

				{this.ButtonArea()}
			</div>
		);
	}

	StatusArea() {
		if (this.state.offer.status === "id_card_check") {
			return (
				<div>
					<p className="neo-callout">
						Der Personalausweis muss auf Lesbarkeit geprüft werden, bevor die
						Bonitätsprüfung durchgeführt werden kann.
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		}
	}

	ButtonArea() {
		if (this.state.offer.status === "open") {
			return (
				<div>
					<div className="neo-spacer20" />
					<NeoButton
						title="Angebot zurückziehen"
						onClick={this.withdrawOffer}
					/>
				</div>
			);
		} else if (this.state.offer.status === "id_card_check") {
			return (
				<div>
					<div className="neo-spacer20" />
					<NeoButton
						title="Ausweis ist lesbar"
						onClick={this.idCardReadable}
						showLoadingState={this.state.isSending}
					/>
					<NeoButton
						title="Ausweis ist unlesbar"
						onClick={this.idCardNotReadable}
						showLoadingState={this.state.isSending2}
					/>
				</div>
			);
		} else if (this.state.offer.status === "completed") {
			return (
				<div>
					<NeoButton
						title="Dokumente hochladen"
						onClick={this.showUploadDocuments}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		}
	}

	IDCards() {
		if (this.state.offer.status === "id_card_check") {
			return (
				<div>
					<DividerText text="Personalausweis" />

					<div style={{ position: "relative", zIndex: 1 }}>
						<img
							className="neo-idCardPreview"
							src={`data:image/jpeg;base64,${this.state.id_card_front}`}
							alt=""
						/>
					</div>

					<div style={{ position: "relative", zIndex: 1 }}>
						<img
							className="neo-idCardPreview"
							src={`data:image/jpeg;base64,${this.state.id_card_back}`}
							alt=""
						/>
					</div>
				</div>
			);
		}
	}

	loadIDCards() {
		axios
			.get(this.state.offer.id_cards[0].url, {
				responseType: "arraybuffer",
			})
			.then((response) => {
				let buffer = Buffer.from(response.data, "binary").toString("base64");

				this.setState({
					id_card_front: buffer,
				});
			})
			.catch(function (error) {});

		axios
			.get(this.state.offer.id_cards[1].url, {
				responseType: "arraybuffer",
			})
			.then((response) => {
				let buffer = Buffer.from(response.data, "binary").toString("base64");

				this.setState({
					id_card_back: buffer,
				});
			})
			.catch(function (error) {});
	}

	Files() {
		if (this.state.offer.status === "completed") {
			return (
				<div>
					<DividerText text="Dokumente" />

					<p className="neo-text" id="leading">
						Hier finden Sie Rechnung, Abholschein, Abtretungsurkunde und andere
						Dokumente zu diesem Vertrag.
					</p>

					{this.state.offer.documents.map((file, index) => (
						<div
							key={file.url}
							style={{ marginBottom: "30px" }}
							onClick={() => this.showFile(index)}
						>
							<div style={{ position: "relative", zIndex: 1 }}>
								<img className="neo-idCardPreview" src={file.url} alt="" />

								<div className="neo-overlay">
									<div
										className="neo-deleteButton"
										onClick={() => this.deleteFile(index)}
									/>
								</div>
							</div>

							<p
								className="neo-callout"
								style={{
									margin: "0",
									padding: "0",
									marginTop: "-10px",
									textAlign: "left",
								}}
							>
								{file.original_filename}
							</p>
						</div>
					))}
				</div>
			);
		}
	}

	showFile(index) {
		let offer = this.state.offer;
		let file = offer.documents[index];

		window.open(file.url, "_blank");
	}

	deleteFile(index) {
		let offer = this.state.offer;
		let file = offer.documents[index];

		axios
			.get(BASE_URL + "/vendor/offer-delete-documents/" + file.id)
			.then((result) => {})
			.catch(() => {});

		if (index !== -1) {
			offer.documents.splice(index, 1);
			this.setState({ offer: offer });
		}
	}

	withdrawOffer() {
		const { id } = this.props.match.params;

		this.props.history.push("/vendor/offers/" + id + "/withdraw");
	}

	showUploadDocuments() {
		const { id } = this.props.match.params;

		this.props.history.push("/vendor/offers/" + id + "/upload");
	}

	showUploadData() {
		const { id } = this.props.match.params;

		this.props.history.push("/vendor/offers/" + id + "/data");
	}

	idCardReadable() {
		this.confirmIDCard(true);
	}

	idCardNotReadable() {
		this.confirmIDCard(false);
	}

	confirmIDCard(isReadable) {
		const { id } = this.props.match.params;

		if (isReadable) {
			this.setState({ isSending: true });
		} else {
			this.setState({ isSending2: true });
		}

		axios
			.post(
				BASE_URL +
					(isReadable
						? "/vendor/offer-id-card-readable"
						: "/vendor/offer-id-card-not-readable"),
				{
					offer_id: id,
				}
			)
			.then(() => {
				this.props.history.push("/vendor/idCheck");
			})
			.catch(() => {
				this.setState({ isSending: false, isSending2: false });
			});
	}
}

export default VendorOfferDetails;
