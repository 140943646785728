import React from 'react';
import '../Stylesheets/App.css';
import '../Stylesheets/neo.css';
import '../Stylesheets/NeoForm.css';

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import {BASE_URL} from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import ConfirmationView from "../ConfirmationView";
import ErrorBox from "../Components/ErrorBox";
import NeoButton from "../Button/NeoButton";

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: null,
            subject: "",
            message: "",

            errors: [],
            errorMessage: null,
            isSent: false,
            isSending: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    isVendorPage() {
        return this.props.history.location.pathname.includes("/vendor");
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile()  {
        axios.get(BASE_URL + (this.isVendorPage() ? "/vendor/profile" : "/user/profile"))
            .then(result => {
                const profile = result.data.data;

                this.setState({ email: profile.email });
            }).catch(() => {

        });
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: []
        });
    }

    sendMessage() {
        this.setState({ isSending: true });

        axios.post(BASE_URL + '/globals/contact', {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        }).then(() => {
            this.setState({ isSent: true, isSending: false });
        }).catch(error => {
            if (error.response) {
                this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
            }
        });
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    ContactForm() {
        return (
                <div>
                    <input type="text" placeholder="E-Mail" name="email" value={this.state.email} onChange={this.handleChange} />
                    <input type="text" placeholder="Betreff" name="subject" value={this.state.subject} onChange={this.handleChange} />

                    <textarea placeholder="Ihre Anfrage" name="message" value={this.state.message} onChange={this.handleChange} />

                    {
                        this.ErrorsView()
                    }

                    <NeoButton showLoadingState={ this.state.isSending } title="Senden" onClick={ this.sendMessage } disabled={this.state.subject.length === 0 || this.state.message.length === 0|| this.state.email.length === 0} />
                </div>
        );
    }

    render() {
        return (
            <div className="neo-app">
                <NavigationBar title="Kontakt" />

                <div className="neo-content">
                    <p className="neo-bar-title">Kontakt</p>

                    {
                        this.state.isSent ? <ConfirmationView text="Wir haben Ihre Anfrage erhalten und melden uns schnellstmöglich bei Ihnen." icon="confirmation.png"/> : this.state.email != null ? this.ContactForm() : this.LoadingView()
                    }
                </div>

                <Footer/>
            </div>
        );
    }
}

export default Contact;
