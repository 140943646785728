import React from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {GOOGLE_MAPS_API_KEY} from "../Constants";

function MapView(props) {
    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
    }

    return (
        <div className="neo-map">
            <Map google={window.google} zoom={14}
                 initialCenter={{
                     lat: props.lat,
                     lng: props.lng
                 }}
                 containerStyle={ containerStyle }>
                <Marker
                    title={'Shop'}
                    name={'Shop'}
                    position={{lat: props.lat, lng: props.lng}} />
            </Map>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: ( GOOGLE_MAPS_API_KEY ),
})(MapView)