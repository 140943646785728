import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./Constants.js";
import { titleForStatus, iconForStatus } from "./Constants";

function OfferCell(props) {
	let history = useHistory();

	function isVendorPage() {
		return history.location.pathname.includes("/vendor");
	}

	function showDetails() {
		if (props.offer.company !== null && props.offer.company.is_company_admin) {
			history.push("/offers/company_group/" + props.offer.id);
		} else {
			history.push(
				isVendorPage()
					? "/vendor/offers/" + props.offer.id
					: "/offers/" + props.offer.id
			);
		}
	}

	function Status() {
		if (props.offer.status === "open") {
			var remainingTime = Math.floor(props.offer.expires_at / 60 / 60);
			remainingTime += remainingTime === 1 ? " Stunde" : " Stunden";

			return (
				<div>
					<div
						className="neo-cell-statusIcon"
						style={{
							backgroundImage:
								"url('/" + iconForStatus(props.offer.status) + "')",
						}}
					/>
					<p className="neo-cell-statusLabel" id={props.offer.status}>
						{titleForStatus(props.offer.status) + " für " + remainingTime}
					</p>
				</div>
			);
		}

		return (
			<div>
				<div
					className="neo-cell-statusIcon"
					style={{
						backgroundImage:
							"url('/" + iconForStatus(props.offer.status) + "')",
					}}
				/>
				<p className="neo-cell-statusLabel" id={props.offer.status}>
					{titleForStatus(props.offer.status)}
				</p>
			</div>
		);
	}

	return (
		<div className="neo-cell" onClick={showDetails}>
			<div className="neo-cell-horizontalContainer">
				<div className="neo-cell-verticalContainer">
					<p className="neo-cell-title">{props.title}</p>
					<p className="neo-cell-subtitle">{props.subtitle1}</p>
					<p className="neo-cell-subtitle">{props.subtitle2}</p>

					{Status()}
				</div>

				<div className="neo-cell-verticalContainer">
					<p className="neo-cell-rightLabelBold">
						{props.offer.leasing_rate} € mtl.
					</p>
					<p className="neo-cell-rightLabel">
						{props.offer.leasing_duration} Monate
					</p>
				</div>
			</div>
		</div>
	);
}

OfferCell.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle1: PropTypes.string.isRequired,
};

export default OfferCell;
