import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import ConfirmationView from "./ConfirmationView";
import NeoButton from "./Button/NeoButton";
import Cookies from "js-cookie";
import ErrorBox from "./Components/ErrorBox";
import NeoEditField from "./EditField/NeoEditField";

class UploadData extends React.Component {
    state = {
        errorMessage: null,
        errors: null,
        isSending: false,
        isSent: false,
        handover_date: new Date(),
        deliver_date: new Date(),
        deliverCode: "",
        frameNumber: "",
        codeIsSending: false,
        pin: "",
        pin_confirm: ""
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.sendOffer = this.sendOffer.bind(this);
        this.sendCode = this.sendCode.bind(this);
    }

    render() {
        const { id } = this.props.match.params;

        return (
            <div className="neo-app">
                <NavigationBar title="Leasingübergabe bestätige" />

                <div className="neo-content">
                    {this.state.isSent ? (
                        <ConfirmationView
                            text="Sie haben die Übergabe erfolreich gespeichert."
                            icon="confirmation.png"
                            route={"/offers/" + id}
                        />
                    ) : (
                        this.Data()
                    )}
                </div>

                <Footer />
            </div>
        );
    }

    LoadingView() {
        return <LoadingSpinner title="Laden..." />;
    }

    chooseFile() {
        this.state.inputElement.click();
    }

    handleChange(event) {
        const value = event.target.value;

        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: [],
        });

        if (event.target.name === "objectPrice") {
            this.setState({ rate: 0, insurance_fee: 0, insurance_available: null });
        }
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return errorMessage !== null ? (
            <ErrorBox errorMessage={errorMessage} errors={errors} />
        ) : null;
    }

    sendCode() {
        const { id } = this.props.match.params;

        if(this.state.frameNumber == "" || this.state.handover_date == "" || this.state.deliver_date == "") {
            this.setState({
                errorMessage: "Fehler",
            })

            let errors = [];
            if(this.state.frameNumber == "") {
                errors.push("Rahmennummer ist ein Pflichtfeld.");
            }

            if(this.state.deliver_date == "") {
                errors.push("Lieferdatum ist ein Pflichtfeld.");
            }

            if(this.state.handover_date == "") {
                errors.push("Übergabedatum ist ein Pflichtfeld.");
            }

            this.setState({
                errors: errors,
            })
            return false
        }else{
            this.setState({
                errorMessage: "",
            })
        }

        axios
            .post(
                BASE_URL + "/user/offer-code/" + id,
                {}
            )
            .then((data) => {
                this.setState({ codeIsSending: true, isSending: false, pin_confirm: data.data.code });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        isSending: false,
                        errors: error.response.data.errors,
                        errorMessage: error.response.data.message,
                    });
                }
            });

    }

    sendOffer() {
        const { id } = this.props.match.params;

        if(this.state.pin_confirm != this.state.pin) {
            this.setState({
                isSending: false,
                errors: ["Der Pin ist ungültig."],
                errorMessage: "Fehler",
            });
            return false;
        }

        this.setState({ isSending: true });

        let branchID = Cookies.get("CurrentStoreID");

        axios
            .post(
                BASE_URL +
                (this.state.useCompanyGroup
                    ? "/user/offer-update/company/"+id
                    : "/user/offer-update/"+id),
                {
                    handover_date: this.state.handover_date,
                    deliver_date: this.state.deliver_date,
                    deliver_code: this.state.pin,
                    frame_number: this.state.frameNumber,
                    branch_id: branchID,
                }
            )
            .then(() => {
                this.setState({ isSending: false, isSent: true });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        isSending: false,
                        errors: error.response.data.errors,
                        errorMessage: error.response.data.message,
                    });
                }
            });
    }

    Data() {
        return (
            <div>
                <p className="neo-mediumTitle" id="leading">
                    Leasingübergabe bestätigen
                </p>
                <p className="neo-text" id="leading">
                    Hier können Sie die Rahmennummer und das Übergabedatum bestätigen.
                </p>

                <input
                    type="text"
                    placeholder="Rahmennummer"
                    name="frameNumber"
                    value={this.state.frameNumber}
                    onChange={this.handleChange}
                />

                <p>Lieferdatum</p>
                <NeoEditField
                    label=""
                    type="date"
                    name="deliver_date"
                    value={this.state.deliver_date ?? ""}
                    onChange={this.handleChange}
                />

                <p>Übergabedatum</p>
                <NeoEditField
                    label=""
                    type="date"
                    name="handover_date"
                    value={this.state.handover_date ?? ""}
                    onChange={this.handleChange}
                />

                <div className="neo-spacer20" />

                <div className="neo-divider" />

                <div className="neo-spacer20" />

                {this.ErrorsView()}

                <NeoButton
                    title={!this.state.isSending ? "Speichern" : "Neuen Pin senden"}
                    showLoadingState={this.state.isSending}
                    onClick={this.sendCode}
                />
                {
                    this.state.codeIsSending ?
                        <div>
                            <div className="neo-spacer20" />
                            <p className="neo-text" id="leading">
                                Sie haben per E-Mail einen Übergangscode erhalten. Bestätigen Sie die Übergabe mit dem Pin aus der E-Mail.
                            </p>

                            <input
                                type="text"
                                placeholder="Pin eingeben"
                                name="pin"
                                value={this.state.pin}
                                onChange={this.handleChange}
                            />

                            <NeoButton
                                title="Übergabe bestätigen"
                                showLoadingState={this.state.isSending}
                                onClick={this.sendOffer}
                            />
                        </div>
                        : null
                }

            </div>
        );
    }
}

export default UploadData;
