import React from "react";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import NeoButton from "../Button/NeoButton";
import { useState, useEffect } from "react";

function CompanyGroupUsersView(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [invites, setInvites] = useState([]);
	const [email, setEmail] = useState("");
	const [showInviteSuccessBanner, setShowInviteSuccessBanner] = useState(false);

	useEffect(() => {
		fetchUsers();
	}, []);

	function fetchUsers() {
		setIsLoading(true);

		axios
			.get(BASE_URL + "/user/company")
			.then((data) => {
				let users = data.data.data.users;

				setUsers(users);
				setInvites(data.data.data.invites);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);

				if (error.response) {
					console.log(error.response);
				}
			});
	}

	function handleInviteTextField(e) {
		let value = e.target.value;
		setEmail(value);
	}

	function inviteUser() {
		setIsLoading(true);

		axios
			.post(BASE_URL + "/user/company/invite-user", {
				email: email,
			})
			.then((data) => {
				setEmail("");
				setIsLoading(false);

				fetchUsers();

				setTimeout(
					function () {
						setShowInviteSuccessBanner(true);
					}.bind(this),
					600
				);

				setTimeout(
					function () {
						setShowInviteSuccessBanner(false);
					}.bind(this),
					3000
				);
			})
			.catch((error) => {
				setEmail("");
				setIsLoading(false);
			});
	}

	function confirmRemoveUser(id) {
		if (
			window.confirm(
				"Möchten Sie diesen Nutzer wirklich aus der Unternehmensgruppe entfernen? Alle offene Anfragen dieses Nutzers werden abgebrochen."
			)
		) {
			removeUser(id);
		}
	}

	function removeUser(id) {
		setIsLoading(true);

		axios
			.get(BASE_URL + "/user/company/remove-user/" + id)
			.then((data) => {
				fetchUsers();
			})
			.catch((error) => {
				setIsLoading(false);

				if (error.response) {
					console.log(error.response);
				}
			});
	}

	function List() {
		return (
			<div>
				<p
					className="neo-smallTitle"
					style={{ textAlign: "left", marginBottom: "20px" }}
				>
					Teilnehmer
				</p>

				<div className="neo-card" style={{ paddingBottom: "10px" }}>
					<p className="neo-text" style={{ maxWidth: "500px" }}>
						Nutzer einladen
					</p>

					<input
						type="email"
						placeholder="E-Mail"
						name="email"
						value={email}
						onChange={handleInviteTextField}
					/>

					<NeoButton
						title="Einladung senden"
						disabled={email.length === 0}
						onClick={inviteUser}
					/>
				</div>

				{users.map((user) => (
					<div className="neo-cell-static" key={user.id}>
						<div className="neo-cell-horizontalContainer">
							<div className="neo-cell-verticalContainer">
								{user.profile === null ? (
									<p className="neo-cell-title">Anonym</p>
								) : (
									<p className="neo-cell-title">
										{user.profile.firstname} {user.profile.lastname}
									</p>
								)}
								<p className="neo-cell-subtitle">
									{user.is_company_admin === 1 ? "Gruppenersteller" : "Nutzer"}
								</p>
							</div>

							{user.is_company_admin === 0 && props.isAdmin && (
								<NeoButton
									isDestructive={true}
									title="Löschen..."
									style={{
										marginTop: "20px",
										marginBottom: "20px",
										width: "150px",
									}}
									onClick={() => confirmRemoveUser(user.id)}
								/>
							)}
						</div>
					</div>
				))}

				{invites.length == 0 ? null:
					<div style={{clear: "both"}}>
						<p
							className="neo-smallTitle"
							style={{ textAlign: "left", paddingTop: "20px", marginBottom: "20px" }}
						>
							Eingeladene Nutzer ohne bisherige Annahme
						</p>

						{invites.map((user) => (
							<div className="neo-cell-static" key={user.id}>
								<div className="neo-cell-horizontalContainer">
									<div className="neo-cell-verticalContainer">
										<p className="neo-cell-title">
											{user.email}
										</p>
										<p className="neo-cell-subtitle">
											{user.created_at}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>
		);
	}

	function LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	return (
		<div>
			<div
				className="copyBanner"
				style={{
					opacity: showInviteSuccessBanner ? 1 : 0,
					display: !showInviteSuccessBanner && "none",
				}}
			>
				Nutzer wurde eingeladen!
			</div>
			{isLoading ? LoadingView() : List()}
		</div>
	);
}

export default CompanyGroupUsersView;
