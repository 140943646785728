import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import MapView from "../MapView/MapView";
import "../Stylesheets/Store.css";

class ShopDetails extends React.Component {
	state = {
		isLoading: true,
		shop: null,
	};

	constructor(props) {
		super(props);

		this.openWebsite = this.openWebsite.bind(this);
		this.startCall = this.startCall.bind(this);
		this.startMail = this.startMail.bind(this);
		this.openMaps = this.openMaps.bind(this);
		this.showCreateOffer = this.showCreateOffer.bind(this);
	}

	componentDidMount() {
		this.loadShop();
	}

	loadShop() {
		const { id } = this.props.match.params;

		axios
			.get(BASE_URL + "/user/vendor/branch/" + id)
			.then((result) => {
				const shop = result.data.data;
				console.log(shop);
				this.setState({ shop, isLoading: false });
			})
			.catch(() => {});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	Details() {
		return (
			<div>
				<MapView
					lat={this.state.shop.address.latitude}
					lng={this.state.shop.address.longitude}
				/>

				<div className="content">
					<p className="text">{this.state.shop.vendor.company_name}</p>
					<p className="largeTitle">{this.state.shop.name}</p>
					<p className="text">{this.state.shop.address.street}</p>
					<p className="text">
						{this.state.shop.address.zip} {this.state.shop.address.city}
					</p>

					<button
						className="neo-button"
						onClick={this.showCreateOffer}
						style={{ marginTop: "40px" }}
					>
						Anfrage erstellen
					</button>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />

					<button className="neo-button" onClick={this.openMaps}>
						Route anzeigen
					</button>

					{this.state.shop.telephone != null ? (
						<button className="neo-button" onClick={this.startCall}>
							Anrufen
						</button>
					) : null}

					{this.state.shop.email != null ? (
						<button className="neo-button" onClick={this.startMail}>
							E-Mail schreiben
						</button>
					) : null}

					{this.state.shop.web != null ? (
						<button className="neo-button" onClick={this.openWebsite}>
							Webseite öffnen
						</button>
					) : null}
				</div>
			</div>
		);
	}

	openWebsite() {
		window.open(this.state.shop.web, "_blank");
	}

	startCall() {
		window.location = "tel://" + this.state.shop.telephone;
	}

	startMail() {
		window.location = "mailto:" + this.state.shop.email;
	}

	openMaps() {
		window.open(
			"https://maps.google.com?q=" +
				this.state.shop.address.latitude +
				"," +
				this.state.shop.address.longitude
		);
	}

	showCreateOffer() {
		this.props.history.push(
			"/direct/" + this.state.shop.vendor.uuid + "/" + this.state.shop.id
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Shopdetails" />

				<div style={{ marginTop: "70px" }}>
					{this.state.isLoading ? this.LoadingView() : this.Details()}
				</div>

				<Footer />
			</div>
		);
	}
}

export default ShopDetails;
