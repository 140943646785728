import Cookies from "js-cookie";
import axios from "axios";

class AuthManager {
	login(token, expires_at) {
		let seconds = Number(expires_at);
		var days = Math.floor(seconds / (3600 * 24));

		Cookies.set("AccessToken", token, {
			expires: days,
		});

		axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
			"AccessToken"
		)}`;
	}

	logout() {
		Cookies.remove("AccessToken");
		Cookies.remove("CurrentStoreName");
		Cookies.remove("CurrentStoreID");
	}

	isAuthenticated() {
		return Cookies.get("AccessToken") != null;
	}
}

export default AuthManager;
