import React from "react";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import ShopCell from "../Shop/ShopCell";
import NeoDropdown from "../Dropdown/NeoDropdown";
import ErrorBox from "../Components/ErrorBox";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import ErrorView from "../ErrorView";
import NeoButton from "../Button/NeoButton";
import ConfirmationView from "../ConfirmationView";
import NeoCheckbox from "../Checkbox/NeoCheckbox";

class CreateCustomerOffer extends React.Component {
	state = {
		company_name: "",
		street: "",
		zip: "",
		city: "",
		hasCompanyGroup: false,

		rate: 0,
		objectName: "",
		objectNumber: "",
		objectManufacturer: "",
		leasingDuration: 36,
		accessories: "",
		insurance: "business_and_private",

		useCompanyGroup: false,
		useGeneralAgreement: false,
		generalagreement: false,
		socialSecurityBilling: 10,
		delivery: "pickup",

		errors: [],
		errorMessage: null,
		isSent: false,
		isLoading: true,
		isLoadingProfile: true,
		noVendorFound: false,
		isSending: false,
		isProfileComplete: true,
		selectedStore: null,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.sendOffer = this.sendOffer.bind(this);
		this.loadVendorInfo = this.loadVendorInfo.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onRadioSocialChange = this.onRadioSocialChange.bind(this);
		this.onRadioDeliverChange = this.onRadioDeliverChange.bind(this);
	}

	componentDidMount() {
		this.loadVendorInfo();
		this.loadProfile();
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	loadProfile() {
		axios
			.get(BASE_URL + "/user/profile")
			.then((result) => {
				const profile = result.data.data;

				this.setState({
					generalagreement: (profile.generalagreement) == null ? false : profile.generalagreement.status != "accept" ? false : true,
					insurance: (profile.generalagreement == null) ? "business_and_private" : "business_and_private",
					isProfileComplete: profile.profile !== null,
					isLoadingProfile: false,
				});

				if (profile.profile !== null) {
					if (profile.profile.company !== null)
						this.setState({
							hasCompanyGroup: true,

							useGeneralAgreement: true
						});
				}
			})
			.catch(() => {});
	}

	getCurrentStore(stores, id) {
		return stores.find((element) => {
			return element.id === parseInt(id, 10);
		});
	}

	loadVendorInfo() {
		const { id } = this.props.match.params;
		const { uuid } = this.props.match.params;

		axios
			.get(BASE_URL + "/user/vendor/" + uuid)
			.then((result) => {
				const profile = result.data.data;

				this.setState({
					branches: profile.branches,
					insurance_only_business: profile.insurances.only_business,
					insurance_business_and_private:
						profile.insurances.business_and_private,
				});

				if (id !== undefined) {
					this.setState({
						selectedStore: {
							name: this.getCurrentStore(profile.branches, id).name,
							value: id,
						},
					});
				}

				if (profile.address === null) {
					this.setState({
						company_name: profile.company_name ?? profile.email,
						isLoading: false,
					});
				} else {
					this.setState({
						company_name: profile.company_name ?? profile.email,
						street: profile.address.street ?? "",
						zip: profile.address.zip ?? "",
						city: profile.address.city ?? "",
						isLoading: false,
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ noVendorFound: true, isLoading: false });
				}
			});
	}

	sendOffer() {
		const { id } = this.props.match.params;
		const { uuid } = this.props.match.params;

		this.setState({ isSending: true });

		axios
			.post(
				BASE_URL +
					(this.state.useCompanyGroup
						? "/user/offer-request/company"
						: "/user/offer-request"),
				{
					object_name: this.state.objectName,
					object_manufacturer: this.state.objectManufacturer,
					equipment: this.state.accessories,
					leasing_duration: this.state.leasingDuration,
					vendor_uuid: uuid,
					insurance: this.state.insurance,
					branch_id: this.state.selectedStore.value,
					social_security_billing: this.state.socialSecurityBilling,
					delivery: this.state.delivery
				}
			)
			.then(() => {
				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
	}

	handleCheckbox(event) {
		const value = event.target.checked;

		this.setState({
			useCompanyGroup: value,
			errors: [],
		});
	}

	handleChangeDuration = (duration) => {
		this.setState({ leasingDuration: duration.value });

		//this.calculateLeasingRate(duration.value);
	};

	render() {
		return (
			<div className="neo-app">
				<NavigationBar showBackButton={false} />

				<div className="neo-content">
					<p className="neo-bar-title">Anfrage stellen</p>

					{this.state.isLoading || this.state.isLoadingProfile ? (
						this.LoadingView()
					) : this.state.noVendorFound === true ? (
						<ErrorView message="Dieser Händler konnte nicht gefunden werden." />
					) : this.state.isSent ? (
						<ConfirmationView
							text="Sie haben die Anfrage erfolgreich an den Händler übermittelt."
							icon="confirmation.png"
						/>
					) : this.state.isProfileComplete === false ? (
						<ConfirmationView
							text="Um eine Anfrage zu erstellen, muss Ihr Profil vollständig ausgefüllt sein."
							icon="credit_check.png"
							route="/profile"
							buttonTitle="Profil öffnen"
						/>
					) : (
						this.OfferForm()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	onRadioDeliverChange(event) {
		this.setState({
			deliver: event.target.value,
		});
	}

	onRadioSocialChange(event) {
		this.setState({
			socialSecurityBilling: event.target.value,
		});
	}

	onRadioChange(event) {
		this.setState({
			insurance: event.target.value,
		});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	handleChangeStore = (item) => {
		this.setState({ selectedStore: item });
	};

	StoreItems() {
		var storeItems = [];

		var i;
		for (i = 0; i < this.state.branches.length; i++) {
			storeItems.push({
				name: this.state.branches[i].name,
				value: this.state.branches[i].id,
			});
		}

		return storeItems;
	}

	OfferForm() {
		const { id } = this.props.match.params;

		return (
			<div>
				<ShopCell
					title="Händler"
					subtitle1={this.state.company_name}
					uuid={id}
					disableClick={true}
				/>

				<NeoDropdown
					title="Filiale"
					items={this.StoreItems()}
					value={
						this.state.selectedStore === null
							? "Auswählen..."
							: this.state.selectedStore.name
					}
					onChangeValue={this.handleChangeStore}
					trailingString=""
				/>

				<div className="neo-spacer20" />

				<input
					type="text"
					placeholder="Name des Leasingobjekts"
					name="objectName"
					value={this.state.objectName}
					onChange={this.handleChange}
				/>

				<input
					type="text"
					placeholder="Hersteller"
					name="objectManufacturer"
					value={this.state.objectManufacturer}
					onChange={this.handleChange}
				/>

				<textarea
					placeholder="Zubehör (optional)"
					name="accessories"
					value={this.state.accessories}
					onChange={this.handleChange}
				/>

				<div className="neo-spacer20" />

				<NeoDropdown
					title="Leasingdauer"
					items={[{ name: "36", value: 36 }]}
					value={this.state.leasingDuration}
					onChangeValue={this.handleChangeDuration}
					trailingString="Monate"
				/>

				<div className="neo-card">
					<p className="neo-smallTitle">Versicherung</p>
					<p className="neo-text">
						Wählen Sie aus, ob Sie eine Versicherung für das Leasingobjekt
						buchen möchten:
					</p>


						<div
							className="neo-card-cell"
							onClick={() =>
								this.setState({ insurance: "business_and_private" })
							}
						>
							<input
								type="radio"
								id="business_and_private"
								name="insurance"
								value="business_and_private"
								checked={this.state.insurance === "business_and_private"}
								onChange={this.onRadioChange}
							/>
							<label for="business_and_private" className="neo-text">
								<span>Fahrradversicherung für Dienstradleasing</span>
							</label>
						</div>


					{this.state.insurance_only_business && (
						<div
							className="neo-card-cell"
							onClick={() => this.setState({ insurance: "only_business" })}
						>
							<input
								type="radio"
								id="only_business"
								name="insurance"
								value="only_business"
								checked={this.state.insurance === "only_business"}
								onChange={this.onRadioChange}
							/>
							<label for="only_business" className="neo-text">
								<span>Fahrradversicherung für Poolfahrzeuge zur gewerblichen Nutzung</span>
							</label>
						</div>
					)}

					<div
						className="neo-card-cell"
						onClick={() => this.state.generalagreement ? null : this.setState({ insurance: "none" })}
					>
						<input
							type="radio"
							id="none"
							name="insurance"
							value="none"
							disabled={this.state.generalagreement}
							checked={this.state.insurance === "none"}
							onChange={this.onRadioChange}
						/>
						<label for="none" className="neo-text">
							<span>
								Ich versichere selbst (Ist der Leasinggesellschaft nachzuweisen)
							</span>
						</label>
					</div>
				</div>

				<div className="neo-card">
					<p className="neo-smallTitle" style={{marginBottom: 25}}>Abholung oder Lieferung</p>

					<div
						className="neo-card-cell"
						onClick={() => this.setState({delivery: "pickup"})}
					>
						<input
							type="radio"
							id="deliverypickup"
							name="delivery"
							value="pickup"
							checked={this.state.delivery == "pickup"}
							onChange={this.onRadioDeliverChange}
						/>
						<label htmlFor="deliverypickup" className="neo-text">
							<span>
								Abholung beim Händler
							</span>
						</label>
					</div>

					<div
						className="neo-card-cell"
						onClick={() => this.setState({delivery: "deliver"})}
					>
						<input
							type="radio"
							id="deliverydeliver"
							name="delivery"
							value="deliver"
							checked={this.state.delivery === "deliver"}
							onChange={this.onRadioDeliverChange}
						/>
						<label htmlFor="deliverydeliver" className="neo-text">
							<span>
								Lieferung nach Hause
							</span>
						</label>
					</div>
				</div>

				{this.state.hasCompanyGroup && (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />

						<NeoCheckbox
							showTermsButton={true}
							name="useCompanyGroup"
							label="Diese Anfrage stelle ich als Angesteller."
							value={this.state.useCompanyGroup}
							checked={this.state.useCompanyGroup}
							onChange={this.handleCheckbox}
						/>

						<p className="neo-text" id="leading" onClick={() => this.setState({useCompanyGroup: !this.state.useCompanyGroup})}>
							Sie und Ihr Arbeitgeber erhalten das Angebot vom Händler. Nur Ihr
							Arbeitgeber kann es annehmen oder ablehnen.
						</p>
					</div>
				)}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				{this.ErrorsView()}

				<NeoButton
					title="Anfrage senden"
					showLoadingState={this.state.isSending}
					onClick={this.sendOffer}
					disabled={
						this.state.objectName.length === 0 ||
						this.state.objectManufacturer.length === 0 ||
						this.state.selectedStore === null
					}
				/>
			</div>
		);
	}
}

export default CreateCustomerOffer;
