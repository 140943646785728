import React from "react";
import "./Stylesheets/App.css";
import Routing from "./Routing";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function App(props) {
	let history = useHistory();
	let location = useLocation();

	axios.defaults.headers.common.Accept = "application/json";
	axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
		"AccessToken"
	)}`;

	function isVendorPage() {
		return history.location.pathname.includes("/vendor");
	}

	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (
				error.response.status === 401 &&
				!error.response.config.url.includes("login") &&
				!error.response.config.url.includes("/vendor/branch")
			) {
				showLogin();
			} else {
				return Promise.reject(error);
			}
		}
	);

	function showLogin() {
		history.push("/vendor/welcome");
		return true;
		if (isVendorPage()) {
			history.push("/vendor/welcome");
		} else {
			history.push({
				pathname: "/welcome",
				state: { from: location },
			});
		}
	}

	return <Routing />;
}

export default App;
