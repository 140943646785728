import React from 'react';
import '../Stylesheets/App.css';
import '../Stylesheets/neo.css';
import Footer from "./Footer";
import NavigationBar from "../NavigationBar/NavigationBar";

function PageNotFound() {
    return (
        <div className="neo-app">
            <NavigationBar/>

            <div className="neo-content" style={{ height: "200px" }}>
               <p className="neo-mediumTitle" style={{ marginTop: "80px" }}>
                   Diese Seite wurde nicht gefunden.
               </p>
           </div>

            <Footer/>
        </div>
    );
}

export default PageNotFound;
