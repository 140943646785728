import React, {useState} from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import NeoEditField from "../EditField/NeoEditField";
import NeoButton from "../Button/NeoButton";
import NeoSelectField from "../SelectField/NeoSelectField";
import ErrorBox from "../Components/ErrorBox";
import ManageCompanyGroupView from "../CompanyGroups/ManageCompanyGroupView";
import { format, parse } from "date-fns";
import moment from "moment";
import CompanyGroupUsersView from "../CompanyGroups/CompanyGroupUsersView";

class MyCompanyView extends React.Component {
	state = {
		isLoading: true,
		profile: [],
		errors: null,
		errorMessage: null,
		isSending: false,
		showDeleteConfirmation: false,
		generalagreement: null,
		hasCard: false
	};

	constructor(props) {
		super(props);

		this.createCompanyGroup = this.createCompanyGroup.bind(this);
	}

	componentDidMount() {
		this.loadProfile();
		this.loadCurrentIDCard();
	}

	loadCurrentIDCard() {


		axios
			.get(BASE_URL + "/user/identity-card")
			.then((result) => {
				console.log(result);

				if (result.status === 200) {
					this.setState({hasCard: true})
				}

			})
			.catch((error) => {

			});
	}

	loadProfile() {
		axios
			.get(BASE_URL + "/user/profile")
			.then((result) => {
				const profile = result.data.data;
				console.log(profile);

				if (profile.profile !== null) {
					this.setState({
						profile: {
							company: profile.company,
							cr_number: profile.profile.cr_number,
							sti_number: profile.profile.sti_number,
							company_name: profile.company_name
						},
						generalagreement: profile.generalagreement,
						isLoading: false,
						errors: null,
						errorMessage: null,
					});
				} else {
					this.setState({
						profile: {
							company: profile.company,
							company_name: null,
							cr_number: "",
							sti_number: "",
						},
						isLoading: false,
						errors: null,
						errorMessage: null,
					});
				}
			})
			.catch(() => {});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	createCompanyGroup() {
		this.setState({ isLoading: true });

		axios
			.post(BASE_URL + "/user/company", {
				name: this.state.profile.company_name,
				cr_number: this.state.profile.cr_number,
				sti_number: this.state.profile.sti_number,
			})
			.then(() => {
				this.loadProfile();
			})
			.catch((error) => {
				console.log(error.response);
				if (error.response) {
					this.setState({
						isLoading: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	CreateCompanyGroupCard() {
		return (
			<div className="neo-card" style={{ paddingBottom: 0 }}>
				<p className="neo-text" style={{ maxWidth: "500px" }}>
					Sie können Ihre Mitarbeiter in eine Unternehmensgruppe einladen, damit
					diese Anfragen im Namen Ihres Unternehmens stellen können. Sie können
					die erhaltenen Angebote dann prüfen und verwalten.
				</p>

				<NeoButton
					title="Unternehmensgruppe erstellen"
					onClick={this.createCompanyGroup}
					disabled={this.state.profile.company_name === null}
				/>

				{this.state.profile.company_name === null && (
					<p
						className="neo-text"
						style={{
							maxWidth: "500px",
							fontSize: "13px",
							marginTop: "0",
							paddingBottom: "20px",
						}}
					>
						Geben Sie in Ihrem Profil einen Firmennamen, sowie optional
						HR-Nummer und Ust-ID an, um zu beginnen.
					</p>
				)}
			</div>
		);
	}

	Profile() {
		const {showDeleteConfirmation} = this.state;
		function deleteCompanyGroup() {
			axios
				.get(BASE_URL + "/user/company/delete")
				.then(() => {
					//history.push("/");
					window.location.reload();
				})
				.catch((error) => {
					console.log(error.response);
					if (error.response !== null) {
						alert(error.response.data.message);
					}
				});
		}

		return (
			<div>
				<p className="neo-bar-title">Mein Unternehmen</p>

				{this.state.profile.company === null ? (
					this.CreateCompanyGroupCard()
				) : (
					<div>
						<ManageCompanyGroupView
							isAdmin={this.state.profile.company.is_company_admin}
							company={this.state.profile.company}
							hasCard={this.state.hasCard}
							generalagreement={this.state.generalagreement}
							socialSecurityBilling={this.state.profile.company.social_security_billing}
						/>
						<CompanyGroupUsersView
							isAdmin={this.state.profile.company.is_company_admin}
						/>


						<NeoButton
							isDestructive={true}
							title="Unternehmensgruppe löschen..."
							onClick={() => this.setState({showDeleteConfirmation: true})}
						/>

						{showDeleteConfirmation ? (
							<div className="modal">
								<div className="flexContainer-vertical">
									<div className="modal-content" style={{ padding: "20px" }}>
										<p className="neo-text">
											Möchten Sie die Unternehmensgruppe wirklich löschen? Alle
											enthaltenen Teilnehmer werden entfernt und offene Anfragen
											werden abgebrochen.
										</p>

										<NeoButton
											isDestructive={true}
											title="Löschen"
											onClick={deleteCompanyGroup}
										/>

										<NeoButton
											isDestructive={false}
											title="Abbrechen"
											onClick={() => this.setState({showDeleteConfirmation: false})}
										/>
									</div>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Mein Profil" />
				<div className="neo-content">
					{this.state.isLoading ? this.LoadingView() : this.Profile()}
				</div>

				<Footer />
			</div>
		);
	}
}

export default MyCompanyView;
