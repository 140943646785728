import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoForm.css";

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL, CONTRACT_URL } from "../Constants";
import NeoCheckbox from "../Checkbox/NeoCheckbox";
import ErrorBox from "../Components/ErrorBox";
import NeoEditField from "../EditField/NeoEditField";
import NeoSelectField from "../SelectField/NeoSelectField";
import ConfirmationView from "../ConfirmationView";
import NeoButton from "../Button/NeoButton";
import { Link } from "react-router-dom";
import Imprint from "../Imprint";

class CreateVendorAccount extends React.Component {
    state = {
        email: "",
        password: "",
        password_confirmation: "",
        termsChecked: false,
        contractChecked: false,
        errors: [],
        errorMessage: null,
        termsVisible: false,

        gender: "male",
        firstname: "",
        lastname: "",
        web: "",
        telephone: "",
        contact_email: "",
        cr_number: "",
        sti_number: "",
        company_name: "",
        street: "",
        zip: "",
        city: "",

        isSent: false,
        isSending: false,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTermsCheckbox = this.handleTermsCheckbox.bind(this);
        this.handleContractCheckbox = this.handleContractCheckbox.bind(this);
        this.register = this.register.bind(this);
        this.showTerms = this.showTerms.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;

        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: [],
        });
    }

    handleTermsCheckbox(event) {
        const value = event.target.checked;

        this.setState({
            ...this.state,
            termsChecked: value,
            errors: [],
        });
    }

    handleContractCheckbox(event) {
        const value = event.target.checked;

        this.setState({
            ...this.state,
            contractChecked: value,
            errors: [],
        });
    }

    handleSubmit(event) {
        this.register();

        event.preventDefault();
    }

    register() {
        this.setState({ isSending: true });

        axios
            .post(BASE_URL + "/user/auth/register_general_agreement", {
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation,
                gender: this.state.gender,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                web: this.state.web,
                telephone: this.state.telephone,
                contact_email: this.state.contact_email,
                cr_number: this.state.cr_number,
                sti_number: this.state.sti_number,
                company_name: this.state.company_name,
                street: this.state.street,
                zip: this.state.zip,
                city: this.state.city,
            })
            .then(() => {
                this.setState({
                    isSent: true,
                    isSending: false,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response) {
                        this.setState({
                            isSending: false,
                            errors: error.response.data.errors,
                            errorMessage: error.response.data.message,
                        });
                    }
                }
            });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return errorMessage !== null ? (
            <ErrorBox errorMessage={errorMessage} errors={errors} />
        ) : null;
    }

    showTerms() {
        console.log("SHOW TERMS");
        this.setState({ termsVisible: !this.state.termsVisible });
    }

    RegisterForm() {
        return (
            <div>
                <input
                    type="text"
                    placeholder="E-Mail Adresse"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                />
                <input
                    type="password"
                    placeholder="Passwort"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                />
                <input
                    type="password"
                    placeholder="Passwort wiederholen"
                    name="password_confirmation"
                    value={this.state.password_confirmation}
                    onChange={this.handleChange}
                />

                <p className="neo-textSmall">
                    Das Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie
                    Buchstaben enthalten.
                </p>

                <NeoCheckbox
                    showTermsButton={true}
                    name="termsChecked"
                    label="Mit der Registrierung bestätigen Sie unsere AGB und Datenschutzbestimmungen."
                    value={this.state.termsChecked}
                    onChange={this.handleTermsCheckbox}
                />
                <a class="termsButton" onClick={this.showTerms}>
                    Ansehen
                </a>

                <div className="neo-spacer20" />
                <div className="neo-spacer20" />

                <NeoCheckbox
                    showTermsButton={true}
                    name="contractChecked"
                    label="Mit der Registrierung bestätigen Sie die Bedingungen des Händlervertrags."
                    value={this.state.contractChecked}
                    onChange={this.handleContractCheckbox}
                />
                <a
                    className="termsButton"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={CONTRACT_URL}
                >
                    Ansehen
                </a>

                <div className="neo-spacer20" />
                <div className="neo-spacer20" />

                <NeoEditField
                    label="Firma"
                    name="company_name"
                    value={this.state.company_name ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="HR-Nummer"
                    name="cr_number"
                    value={this.state.cr_number ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="Ust-ID"
                    name="sti_number"
                    value={this.state.sti_number ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="Straße"
                    name="street"
                    value={this.state.street ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="PLZ"
                    name="zip"
                    value={this.state.zip ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="Ort"
                    name="city"
                    value={this.state.city ?? ""}
                    onChange={this.handleChange}
                />

                <div className="neo-spacer20" />

                <NeoSelectField
                    name="gender"
                    label="Geschlecht"
                    onChange={this.handleChange}
                    value={this.state.gender}
                    items={[
                        <option key="male" value="male">
                            Männlich
                        </option>,
                        <option key="female" value="female">
                            Weiblich
                        </option>,
                        <option key="diverse" value="diverse">
                            Divers
                        </option>,
                    ]}
                />

                <NeoEditField
                    label="Vorname"
                    name="firstname"
                    value={this.state.firstname ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="Nachname"
                    name="lastname"
                    value={this.state.lastname ?? ""}
                    onChange={this.handleChange}
                />

                <div className="neo-spacer20" />

                <NeoEditField
                    label="Webseite"
                    name="web"
                    value={this.state.web ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="Telefon"
                    name="telephone"
                    value={this.state.telephone ?? ""}
                    onChange={this.handleChange}
                />
                <NeoEditField
                    label="Kontakt E-Mail"
                    name="contact_email"
                    value={this.state.contact_email ?? ""}
                    onChange={this.handleChange}
                />

                <div className="neo-spacer20" />

                <p className="neo-textSmall">
                    Diese E-Mail-Adresse wird öffentlich für Interessenten auf der
                    Händlerdetailseite angezeigt.
                </p>

                <div className="neo-spacer20" />
                <div className="neo-spacer20" />

                {this.ErrorsView()}

                <NeoButton
                    onClick={this.register}
                    title="Registrieren"
                    showLoadingState={this.state.isSending}
                    disabled={
                        this.state.email.length === 0 ||
                        this.state.password.length === 0 ||
                        this.state.password_confirmation.length === 0 ||
                        this.state.company_name.length === 0 ||
                        this.state.cr_number.length === 0 ||
                        this.state.sti_number.length === 0 ||
                        this.state.street.length === 0 ||
                        this.state.zip.length === 0 ||
                        this.state.city.length === 0 ||
                        this.state.gender.length === 0 ||
                        this.state.firstname.length === 0 ||
                        this.state.lastname.length === 0 ||
                        this.state.web.length === 0 ||
                        this.state.telephone.length === 0 ||
                        this.state.contact_email.length === 0 ||
                        this.state.termsChecked === false ||
                        this.state.contractChecked === false
                    }
                />

                {this.state.termsVisible ? (
                    <div className="modal">
                        <div className="modal-content">
                            <div className="modal-closeButton" onClick={this.showTerms} />

                            <Imprint showNavBar={false} />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    render() {
        return (
            <div className="neo-app">
                <NavigationBar title="Konto erstellen" />

                <div className="neo-content">
                    <p className="neo-bar-title">Anfrage zum gslease-Rahmenvertrag</p>

                    {this.state.isSent ? (
                        <ConfirmationView
                            text="Sie haben Ihr Konto bei German SmartLease erfolgreich erstellt. Sobald wir Ihre Daten überprüft haben, wird Ihr Konto freigeschaltet und Sie werden per E-Mail benachrichtigt."
                            icon="confirmation.png"
                        />
                    ) : (
                        this.RegisterForm()
                    )}
                </div>

                <Footer />
            </div>
        );
    }
}

export default CreateVendorAccount;
