import React from "react";
import "./NeoButton.css";

import PropTypes from "prop-types";

function NeoButton(props) {
	return (
		<button
			style={props.style}
			className="neo-button"
			id={
				props.isDestructive
					? "destructive"
					: props.showLoadingState
					? "loading"
					: null
			}
			disabled={props.disabled}
			onClick={props.showLoadingState ? null : props.onClick}
		>
			{props.showLoadingState ? (
				<div className="neo-button-loadingContainer">
					<div className="neo-loadingSpinner-small">
						<div className="double-bounce1" />
						<div className="double-bounce2" />
					</div>
				</div>
			) : (
				props.title
			)}
		</button>
	);
}

NeoButton.propTypes = {
	title: PropTypes.string.isRequired,
	isDestructive: PropTypes.bool,
	showLoadingState: PropTypes.bool,
};

export default NeoButton;
