import { React, useState } from "react";
import { useHistory } from "react-router-dom";
import NeoButton from "../Button/NeoButton";
import axios from "axios";
import { BASE_URL } from "../Constants";
import moment from "moment";

function ManageCompanyGroupView(props) {
	let history = useHistory();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [socialSecurityBilling, setSocialSecurityBilling] = useState(props.socialSecurityBilling);
	const [generalagreement, setGeneralagreement] = useState(props.generalagreement);

	const [isSending, setisSending] = useState(false);
	const [hasCard, sethasCard] = useState(props.hasCard);



	function leaveCompanyGroup() {
		axios
			.get(BASE_URL + "/user/company/leave")
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				console.log(error.response);
				if (error.response !== null) {
					alert(error.response.data.message);
				}
			});
	}

	function deleteCompanyGroup() {
		axios
			.get(BASE_URL + "/user/company/delete")
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				console.log(error.response);
				if (error.response !== null) {
					alert(error.response.data.message);
				}
			});
	}

	function showUsersList() {
		history.push("/profile/companygroup/users");
	}

	function AdminView() {

		function toggleRahmenMode() {
			setisSending(true)

			axios
				.post(BASE_URL + "/user/profile-generalagreement", {})
				.then((data) => {
					//this.state.profile.generalagreement = data.data;
					window.location.reload();
					setisSending(false)
					//this.setState({ isSending: false, profile: this.state.profile });
				})
				.catch((error) => {
					setisSending(false)
					if (error.response !== null) {
						alert(error.response.data.message);
					}
				});

		}

		function updateSocialSecurityBilling(data) {
			setSocialSecurityBilling(data)
			axios
				.post(BASE_URL + "/user/profile-social_security_billing", {data:data})
		}

		let fileData = [];
		fileData['request'] = "angefragt";
		fileData['accept'] = "angenommen";
		fileData['declined'] = "abgelehnt";
		fileData['locked'] = "gesperrt";
		fileData['waiting'] = "wartend";
		return (
			<div>
				<div className="neo-card" style={{ paddingBottom: "10px" }}>
					<p className="neo-text" style={{ maxWidth: "500px" }}>
						Unternehmensgruppe verwalten
					</p>


					{
						generalagreement != null
							?
							<NeoButton
								showLoadingState={isSending}
								title={"Rahmenvertrag "+fileData[generalagreement.status]}
							/>
							:
							null
					}

					{
						generalagreement == null ?
							<NeoButton
								showLoadingState={isSending}
								title={"Rahmenvertrag beantragen"}
								onClick={toggleRahmenMode}
							/>
						: null
					}

					{
						generalagreement != null && !hasCard ?
							<div style={{marginTop: 20, textAlign: "center"}}>
								Du hast noch kein Personalausweis hochgeladen.

								<NeoButton
									style={{marginTop: 20}}
									title="Personalausweis hochladen..."
									onClick={() => history.push("/profile/id-card")}
								/>
							</div>: null
					}



				</div>
				{
					generalagreement != null
						?
						generalagreement.status	== "accept" ?
							<div className="neo-card">
								<p className="neo-smallTitle" style={{marginBottom: 25}}>Vertragsendemanagement</p>


								<div
									className="neo-card-cell"
									onClick={() => updateSocialSecurityBilling(10)}
								>
									<input
										type="radio"
										id="socialSecurityBilling10"
										name="socialSecurityBilling"
										value="10"
										checked={socialSecurityBilling == 10}
										onChange={(e) => updateSocialSecurityBilling(e.target.value)}
									/>
									<label htmlFor="socialSecurityBilling10" className="neo-text">
													<span>
														10% Restwert
													</span>
									</label>
								</div>


								<div
									className="neo-card-cell"
									onClick={() => updateSocialSecurityBilling(18)}
								>
									<input
										type="radio"
										id="socialSecurityBilling18"
										name="socialSecurityBilling"
										value="18"
										checked={socialSecurityBilling === 18}
										onChange={(e) => updateSocialSecurityBilling(18)}
									/>
									<label htmlFor="socialSecurityBilling18" className="neo-text">
													<span>
														18% Restwert - Gslease übernimmt die Pauschalversteuerung am Ende der Vertragslaufzeit
													</span>
									</label>
								</div>
						</div>
						:
						null
						:
						null
					}
			</div>
		);
	}

	function UserView() {
		return (
			<div className="neo-card" style={{ paddingBottom: 0 }}>
				<p className="neo-text" style={{ maxWidth: "500px" }}>
					Unternehmensgruppe: {props.company.name}
				</p>

				<NeoButton
					isDestructive={true}
					title="Verlassen..."
					onClick={() => setShowDeleteConfirmation(true)}
				/>

				{showDeleteConfirmation ? (
					<div className="modal">
						<div className="flexContainer-vertical">
							<div className="modal-content" style={{ padding: "20px" }}>
								<p className="neo-text">
									Möchten Sie die Unternehmensgruppe wirklich verlassen? Alle
									offenen Anfragen werden abgebrochen.
								</p>

								<NeoButton
									isDestructive={true}
									title="Verlassen"
									onClick={leaveCompanyGroup}
								/>

								<NeoButton
									isDestructive={false}
									title="Abbrechen"
									onClick={() => setShowDeleteConfirmation(false)}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	if (props.isAdmin) {

		return AdminView();
	}

	return UserView();
}

export default ManageCompanyGroupView;
