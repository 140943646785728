import axios from "axios";
import { BASE_URL } from "../Constants";
import { useState, useEffect } from "react";
import NeoButton from "../Button/NeoButton";
import { useHistory } from "react-router-dom";
import ErrorBox from "../Components/ErrorBox";

function IDCardView(props) {
	const [identityCardFront, setIdentityCardFront] = useState(null);
	const [identityCardBack, setIdentityCardBack] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	let history = useHistory();

	useEffect(() => {
		loadCurrentIDCard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadCurrentIDCard() {
		setIsLoading(true);

		axios
			.get(BASE_URL + "/user/identity-card")
			.then((result) => {
				console.log(result);

				if (result.status === 200) {
					setIdentityCardFront(result.data.front);
					setIdentityCardBack(result.data.back);
				}

				setIsLoading(false);
			})
			.catch((error) => {
				setIdentityCardFront(null);
				setIdentityCardBack(null);
				setIsLoading(false);
			});
	}

	function deleteIDCard() {
		setShowDeleteModal(false);

		axios
			.delete(BASE_URL + "/user/identity-card")
			.then((result) => {
				if (result.data.all_deleted === true) {
					loadCurrentIDCard();
				} else {
					setError(
						"Sie können Ihren Personalausweis derzeit nicht löschen, da er in einer offenen Anfrage verwendet wird."
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log(error.response);
			});
	}

	function showUploadForm() {
		history.push("/profile/id-card");
	}

	function ErrorsView() {
		return error !== null ? (
			<ErrorBox errorMessage={error} errors={null} />
		) : null;
	}

	function showLargePicture(url) {
		window.open(url, "_blank");
	}

	function IdentityCardPreview() {
		return (
			<div>
				<p className="neo-text" style={{ maxWidth: "400px" }}>
					Mein Personalausweis
				</p>

				{ErrorsView()}

				<p className="neo-text" style={{ maxWidth: "400px", fontSize: "13px" }}>
					{props.text === undefined
						? "Ihr Personalausweis wird verwendet, um Ihre Anfragen zu prüfen. Er ist 12 Monate in Ihrem Konto verfügbar, bevor er erneut hochgeladen werden muss."
						: props.text}
				</p>

				<div className="id-cards-container">
					<img
						className="id-card-preview"
						src={identityCardFront.url}
						alt=""
						onClick={() => showLargePicture(identityCardFront.url)}
					/>
					<img
						className="id-card-preview"
						src={identityCardBack.url}
						alt=""
						onClick={() => showLargePicture(identityCardBack.url)}
					/>
				</div>

				{props.hideDeleteButton === undefined && (
					<NeoButton
						title="Löschen..."
						isDestructive={true}
						onClick={() => setShowDeleteModal(true)}
					/>
				)}
			</div>
		);
	}

	function IdentityCardPlaceholder() {
		return (
			<div>
				<p className="neo-text" style={{ maxWidth: "400px" }}>
					Mein Personalausweis
				</p>

				<p className="neo-text" style={{ maxWidth: "400px", fontSize: "13px" }}>
					Ihr Personalausweis wird verwendet, um Ihre Anfragen zu prüfen. Sie
					können ihn hier oder beim Erstellen einer Anfrage hinzufügen. Er ist
					dann 12 Monate in Ihrem Konto verfügbar, bevor er erneut hochgeladen
					werden muss.
				</p>

				<NeoButton
					title="Personalausweis hochladen..."
					onClick={showUploadForm}
				/>
			</div>
		);
	}

	if (!isLoading) {
		return (
			<div className="neo-card" style={{ paddingBottom: "10px" }}>
				{identityCardFront === null
					? IdentityCardPlaceholder()
					: IdentityCardPreview()}

				{showDeleteModal ? (
					<div className="modal">
						<div className="flexContainer-vertical">
							<div className="modal-content" style={{ padding: "20px" }}>
								<p className="neo-text">
									Möchten Sie Ihren Personalausweis wirklich löschen?
								</p>

								<NeoButton
									isDestructive={true}
									title="Löschen"
									onClick={deleteIDCard}
								/>

								<NeoButton
									isDestructive={false}
									title="Abbrechen"
									onClick={() => setShowDeleteModal(false)}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	return <div />;
}

export default IDCardView;
