import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";

class PrivacyBanner extends React.Component {
	state = {
		display: true,
	};

	constructor(props) {
		super(props);

		this.close = this.close.bind(this);
	}

	componentDidMount() {
		if (localStorage.getItem("MultiStoreBannerConfirmed")) {
			this.setState({ display: false });
		}
	}

	render() {
		return (
			<div
				className="neo-multistoreBanner"
				style={{
					display: this.state.display ? "true" : "none",
				}}
			>
				<p
					className="neo-smallTitle"
					style={{ color: "black", textShadow: "none", textAlign: "left" }}
				>
					Filialen-Auswahl
				</p>
				<p
					className="neo-text"
					style={{ fontSize: "15px", color: "gray", textShadow: "none" }}
					id="leading"
				>
					Sie können nun mit der Filialauswahl zwischen mehreren Filialen
					wechseln und nur deren Datensätze anzeigen. Klicken Sie dazu auf die
					blaue Leiste und wählen eine Filiale aus oder erstellen Sie weitere.
					Standardmäßig wird Ihre Hauptfiliale angezeigt.
				</p>

				<div className="neo-privacyBanner-button" onClick={this.close}>
					OK
				</div>
			</div>
		);
	}

	close() {
		this.setState({ display: false });

		localStorage.setItem("MultiStoreBannerConfirmed", 1);
	}
}

export default PrivacyBanner;
