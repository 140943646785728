import React from 'react';
import '../Stylesheets/App.css';
import '../Stylesheets/neo.css';
import PropTypes from "prop-types";

function DividerText(props) {
    return (
        <div className="separator">{ props.text }</div>
    );
}

DividerText.propTypes = {
    text: PropTypes.string.isRequired,
};

export default DividerText;
