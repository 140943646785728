import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import NeoButton from "../Button/NeoButton";
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Components/Footer";
import { BASE_URL } from "../Constants";
import axios from "axios";
import ConfirmationView from "../ConfirmationView";
import ErrorBox from "../Components/ErrorBox";
import NeoCheckbox from "../Checkbox/NeoCheckbox";
import { Link } from "react-router-dom";

class IDCardUploadForm extends React.Component {
	state = {
		isSent: false,
		file1: null,
		file2: null,
		termsChecked: false,
		termsVisible: false,

		errorMessage: null,
		errors: null,
		isSending: false,
	};

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.send = this.send.bind(this);
		this.chooseFile = this.chooseFile.bind(this);
		this.deleteImage1 = this.deleteImage1.bind(this);
		this.deleteImage2 = this.deleteImage2.bind(this);
		this.ErrorsView = this.ErrorsView.bind(this);
	}

	onChange(event) {
		if (this.state.file1 === null) {
			this.setState({
				file1: event.target.files[0],
				previewName1: URL.createObjectURL(event.target.files[0]),
			});
		} else {
			this.setState({
				file2: event.target.files[0],
				previewName2: URL.createObjectURL(event.target.files[0]),
			});
		}
	}

	AcceptOffer() {
		return (
			<div>
				<div
					className="neo-iconCircle"
					style={{ backgroundImage: "url('/credit_check.png')" }}
				/>

				<p className="neo-text">
					Um Angebote anzunehmen, muss Ihre Bonität geprüft werden. Sie können
					zu diesem Zweck die Vorder- und Rückseite Ihres Personalausweises
					bereits jetzt hochladen und bis zu 12 Monate verwenden.
				</p>

				{this.state.file1 !== null ? this.ImagePreview1() : null}

				{this.state.file2 !== null ? this.ImagePreview2() : null}

				{this.state.file1 === null || this.state.file2 === null ? (
					<div>
						<NeoButton title="Bild hochladen..." onClick={this.chooseFile} />

						<form>
							<input
								type="file"
								className="neo-hiddenUpload"
								accept=".png,.jpeg,.jpg"
								onChange={this.onChange}
								ref={(input) => (this.state.inputElement = input)}
							/>
						</form>
					</div>
				) : null}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				{this.ErrorsView()}

				<NeoButton
					title="Speichern"
					showLoadingState={this.state.isSending}
					onClick={this.send}
					disabled={this.state.file1 === null || this.state.file2 === null}
				/>
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">{this.AcceptOffer()}</div>

				<Footer />
			</div>
		);
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	chooseFile() {
		this.state.inputElement.click();
	}

	ImagePreview1() {
		return (
			<div style={{ position: "relative", zIndex: 1 }}>
				<img
					className="neo-idCardPreview"
					src={this.state.previewName1}
					alt=""
				/>

				<div className="neo-overlay">
					<div className="neo-deleteButton" onClick={this.deleteImage1} />
				</div>
			</div>
		);
	}

	ImagePreview2() {
		return (
			<div style={{ position: "relative", zIndex: 1 }}>
				<img
					className="neo-idCardPreview"
					src={this.state.previewName2}
					alt=""
				/>

				<div className="neo-overlay">
					<div className="neo-deleteButton" onClick={this.deleteImage2} />
				</div>
			</div>
		);
	}

	send() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		const url = BASE_URL + "/user/identity-card";
		const formData = new FormData();
		formData.append("id_card_front", this.state.file1);
		formData.append("id_card_back", this.state.file2);

		const config = {
			headers: {
				Accept: "application/json",
				"content-type": "multipart/form-data",
			},
		};

		return axios
			.post(url, formData, config)
			.then(() => {
				this.props.history.push("/profile");
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors ?? null,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	deleteImage1() {
		this.setState({
			file1: null,
			previewName1: null,
		});
	}

	deleteImage2() {
		this.setState({
			file2: null,
			previewName2: null,
		});
	}
}

export default IDCardUploadForm;
