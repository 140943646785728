import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoForm.css";

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import ErrorBox from "../Components/ErrorBox";
import NeoButton from "../Button/NeoButton";

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",

			errors: [],
			errorMessage: null,
			isSent: false,
			isSending: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.sendResetMail = this.sendResetMail.bind(this);
		this.showSetPassword = this.showSetPassword.bind(this);
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
	}

	sendResetMail() {
		this.setState({ isSending: true });

		var accountType = this.isVendorPage() ? "Vendor" : "User";

		axios
			.get(
				BASE_URL +
					"/globals/password-reset?email=" +
					this.state.email +
					"&provider=" +
					accountType
			)
			.then(() => {
				this.showSetPassword();
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	showSetPassword() {
		this.props.history.push(
			this.isVendorPage() ? "/vendor/forgotPassword/set" : "/forgotPassword/set"
		);
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	Form() {
		return (
			<div>
				<div
					className="neo-iconCircle"
					style={{ backgroundImage: "url('/reset_password.png')" }}
				/>

				<p className="neo-text">
					Geben Sie Ihre E-Mail Adresse ein, um einen PIN zu erhalten. Mit
					diesem können Sie ein neues Passwort erstellen.
				</p>

				<input
					type="text"
					placeholder="Deine E-Mail Adresse"
					name="email"
					value={this.state.email}
					onChange={this.handleChange}
				/>

				{this.ErrorsView()}

				<NeoButton
					title="Senden"
					showLoadingState={this.state.isSending}
					onClick={this.sendResetMail}
					disabled={this.state.email.length === 0}
				/>
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Passwort vergessen" />

				<div className="neo-content">{this.Form()}</div>

				<Footer />
			</div>
		);
	}
}

export default ForgotPassword;
