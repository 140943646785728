import React from 'react';
import '../Stylesheets/App.css';
import '../Stylesheets/neo.css';
import './NeoSelectField.css';

import PropTypes from "prop-types";

class NeoSelectField extends React.Component {

    render() {
        return (
            <div className="neo-editField-container">
                <p className="neo-editField-label">{ this.props.label }</p>
                <select className="neo-SelectField-select" id="select"
                        name={ this.props.name } onChange={ this.props.onChange }
                        disabled={ this.props.disabled } value={ this.props.value }
                >
                    {
                        this.props.items.map(item =>
                            item
                        )
                    }
                </select>
            </div>
        );
    }
}

NeoSelectField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};

export default NeoSelectField;
