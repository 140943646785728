import NeoButton from "../Button/NeoButton";
import LoadingSpinner from "../LoadingSpinner";
import { useState, useEffect } from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Constants";

function Stores(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [stores, setStores] = useState([]);

	let history = useHistory();

	useEffect(() => {
		fetchStores();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function showCreateStore() {
		history.push("/vendor/stores/create");
	}

	function showStore(id) {
		history.push("/vendor/stores/" + id);
	}

	function fetchStores() {
		setIsLoading(true);

		axios
			.get(BASE_URL + "/vendor/branch")
			.then((result) => {
				const data = result.data.data;
				setStores(data);
				setIsLoading(false);
			})
			.catch(() => {});
	}

	function List() {
		return (
			<div>
				<p className="neo-bar-title">Verkäufer</p>

				<div className="stores-card">
					<div className="stores-hero" />
					<p className="stores-smallTitle">Erstelle neue Verkäufer</p>


					<button className="stores-button" onClick={showCreateStore}>
						Neue Verkäufer erstellen
					</button>
				</div>

				{stores.map((store) => (
					<div
						key={store.id}
						className="stores-cell"
						onClick={() => showStore(store.id)}
					>
						<p className="stores-cell-title">{store.name}</p>
						<p className="stores-cell-text">{store.address.street}</p>
						<p className="stores-cell-text">
							{store.address.zip} {store.address.city}
						</p>
					</div>
				))}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>
		);
	}

	function LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	return (
		<div className="neo-app">
			<NavigationBar title="Mein Profil" />
			<div className="neo-content">
				<div>{isLoading ? LoadingView() : List()}</div>
			</div>
		</div>
	);
}

export default Stores;
