import React from "react";
import QrReader from "react-qr-reader";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import NeoButton from "./Button/NeoButton";
import { DEBUG_MODE_ENABLED } from "./Constants";

class QRScanner extends React.Component {
	state = {
		result: "No result",
	};

	constructor(props) {
		super(props);

		this.showCreateOffer = this.showCreateOffer.bind(this);
		this.showCreateOfferSimulate = this.showCreateOfferSimulate.bind(this);
	}

	handleScan = (data) => {
		if (data) {
			this.showCreateOffer(data);
		}
	};

	handleError = (err) => {
		console.error(err);
	};

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					<p className="neo-bar-title">Interessentencode scannen</p>

					<QrReader
						delay={300}
						onError={this.handleError}
						onScan={this.handleScan}
						style={{
							width: "100%",
							borderRadius: "20px",
							backgroundColor: "white",
							overflow: "hidden",
						}}
					/>

					{DEBUG_MODE_ENABLED ? (
						<NeoButton
							title="Scan simulieren"
							onClick={this.showCreateOfferSimulate}
						/>
					) : null}
				</div>

				<Footer />
			</div>
		);
	}

	showCreateOffer(uuid) {
		this.props.history.push("/vendor/offers/create/" + uuid);
	}

	showCreateOfferSimulate() {
		this.props.history.push(
			"/vendor/offers/create/cb983f22-27d9-46fd-9662-aa50ec6f6760"
		);
	}
}

export default QRScanner;
