import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";

import axios from "axios";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import OfferCell from "./OfferCell";
import LoadingSpinner from "./LoadingSpinner";
import { BASE_URL } from "./Constants";
import DividerText from "./Components/DividerText";
import EmptyStateView from "./EmptyStateView";
import NeoDropdown from "./Dropdown/NeoDropdown";

class Offers extends React.Component {
	state = {
		isLoading: true,
		offersFilter: 1,
		offers: [],
	};

	componentDidMount() {
		this.loadUserOffers();
	}

	isDirectOffers() {
		return this.props.history.location.pathname.includes("/direct");
	}

	loadUserOffers() {
		this.setState({ isLoading: true });

		axios
			.get(
				BASE_URL +
					(this.isDirectOffers() ? "/user/offer-request" : "/user/offers")
			)
			.then((result) => {
				const offers = result.data;

				this.setState({ offers, isLoading: false });
			});
	}

	loadCompanyGroupOffers() {
		this.setState({ isLoading: true });

		axios
			.get(
				BASE_URL +
					(this.isDirectOffers()
						? "/user/company/offer-requests"
						: "/user/company/offers")
			)
			.then((result) => {
				const offers = result.data;

				this.setState({ offers, isLoading: false });
			})
			.catch(() => {
				this.setState({
					offers: { active: [], inActive: [] },
					isLoading: false,
				});
			});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	Cell(offer) {
		return (
			<OfferCell
				key={offer.id}
				offer={offer}
				title={offer.object_name}
				subtitle1={offer.vendor.company_name}
				subtitle2={
					offer.user !== undefined &&
					this.state.offersFilter === 2 &&
					offer.user.profile.firstname + " " + offer.user.profile.lastname
				}
			/>
		);
	}

	ActiveOffersList() {
		return this.state.offers.active.map((offer) => this.Cell(offer));
	}

	InactiveOffersList() {
		return this.state.offers.inActive.map((offer) => this.Cell(offer));
	}

	showDetails(id) {
		this.props.history.push("/offers/direct/" + id);
	}

	OffersList() {
		return (
			<div>
				{this.state.offers.active.length > 0 ? (
					this.ActiveOffersList()
				) : (
					<EmptyStateView message="Keine offenen Angebote" />
				)}

				{this.state.offers.inActive.length > 0 ? (
					<div>
						<DividerText text="Historie" />
						{this.InactiveOffersList()}
					</div>
				) : null}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>
		);
	}

	DirectOffersList() {
		return (
			<div>
				{typeof this.state.offers.data == "undefined" ? <EmptyStateView message="Keine offenen Anfragen" /> : this.state.offers.data.length > 0 ? (
					this.state.offers.data.map((offer) => (
						<div
							className="neo-cell"
							key={offer.id}
							onClick={() => this.showDetails(offer.id)}
						>
							<div className="neo-cell-horizontalContainer">
								<div className="neo-cell-verticalContainer">
									<p className="neo-cell-title">{offer.object_manufacturer}</p>
									<p className="neo-cell-subtitle">{offer.object_name}</p>
									<p className="neo-cell-subtitle">
										{offer.vendor.company_name}
									</p>

									{offer.user !== undefined && (
										<p className="neo-cell-subtitle">
											{offer.user.profile.firstname}{" "}
											{offer.user.profile.lastname}
										</p>
									)}
								</div>
							</div>
						</div>
					))
				) : (
					<EmptyStateView message="Keine offenen Anfragen" />
				)}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>
		);
	}

	handleChangeFilter = (item) => {
		this.setState({ offersFilter: item.value });

		if (item.value === 1) {
			this.loadUserOffers();
		} else {
			this.loadCompanyGroupOffers();
		}
	};

	labelForFilter() {
		if (this.state.offersFilter === 1) {
			return "Mich";
		}

		return "Unternehmensgruppe";
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="" />

				<div className="neo-content">
					<p className="neo-bar-title">
						{this.isDirectOffers() ? "Anfragen" : "Angebote"}
					</p>

					<NeoDropdown
						title="Angebote für"
						items={[
							{ name: "Mich", value: 1 },
							{ name: "Unternehmensgruppe", value: 2 },
						]}
						value={this.labelForFilter()}
						onChangeValue={this.handleChangeFilter}
						trailingString=""
					/>

					{this.state.isLoading
						? this.LoadingView()
						: this.isDirectOffers()
						? this.DirectOffersList()
						: this.OffersList()}
				</div>

				<Footer />
			</div>
		);
	}
}

export default Offers;
