import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import ShopCell from "./ShopCell";
import LoadingSpinner from "../LoadingSpinner";
import NeoDropdown from "../Dropdown/NeoDropdown";
import axios from "axios";
import { BASE_URL } from "../Constants";
import EmptyStateView from "../EmptyStateView";
import Cookies from "js-cookie";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { GOOGLE_MAPS_API_KEY } from "../Constants";

const containerStyle = {
	position: "relative",
	width: "100%",
	height: "100%",
};

class ShopsMapView extends React.Component {
	state = {
		isLoading: true,
		locationDenied: false,
		radiusFilter: "Keine",
		shops: [],
	};

	constructor(props) {
		super(props);

		this.loadMore = this.loadMore.bind(this);
		this.loadShops = this.loadShops.bind(this);
		this.checkIfLocationIsAvailable =
			this.checkIfLocationIsAvailable.bind(this);
		this.getUserLocation = this.getUserLocation.bind(this);
		this.onMarkerClick = this.onMarkerClick.bind(this);
		this.showDetails = this.showDetails.bind(this);
	}

	componentDidMount() {
		this.checkIfLocationIsAvailable(this.state.radiusFilter);
	}

	checkIfLocationIsAvailable(value) {
		if ("geolocation" in navigator) {
			this.getUserLocation(value);
		} else {
			console.log("Location is not available.");
			this.setState({
				locationDenied: true,
				isLoading: false,
				latitude: 53,
				longitude: 18,
			});
		}
	}

	getUserLocation(value) {
		let self = this;

		navigator.geolocation.getCurrentPosition(
			(position) => {
				self.setState({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				});
				this.loadShops(value);
				self.setState({ locationDenied: false });
			},
			(error) => {
				console.log("Error getting user's location.");

				self.setState({ locationDenied: true, isLoading: false });
			}
		);
	}

	loadShops(value) {
		this.setState({ isLoading: true });
		var url =
			BASE_URL +
			"/user/vendor-list?lat=" +
			this.state.latitude +
			"&lng=" +
			this.state.longitude +
			"&radius=" +
			value;

		if (this.state.next != null) {
			url = this.state.next;
		}

		axios
			.get(url)
			.then((result) => {
				const shops = result.data.data;
				const next = result.data.links.next;
				console.log(shops);
				this.setState({ shops, next, isLoading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	LocationDeniedView() {
		return (
			<div>
				<p className="neo-mediumTitle" style={{ marginTop: "70px" }}>
					Standort nicht freigegeben
				</p>

				<p className="neo-text">
					Um Händler in der Nähe anzuzeigen, wird dein Standort benötigt.
				</p>
			</div>
		);
	}

	onMarkerClick(props, marker, e) {
		this.showDetails(props);
	}

	showDetails(props) {
		console.log(props);
		this.props.history.push("/shops/" + props.uuid);
	}

	ShopsList() {
		return (
			<div className="neo-map-large">
				<Map
					google={window.google}
					zoom={this.state.radiusFilter === 1000 ? 6.2 : 9}
					initialCenter={{
						lat:
							this.state.radiusFilter === 1000
								? 51.165691
								: this.state.latitude,
						lng:
							this.state.radiusFilter === 1000
								? 10.451526
								: this.state.longitude,
					}}
					containerStyle={containerStyle}
				>
					{this.state.shops.map((shop, index) => (
						<Marker
							onClick={this.onMarkerClick}
							key={index}
							uuid={shop.id}
							title={shop.name}
							name={shop.name}
							position={{
								lat: shop.address.latitude,
								lng: shop.address.longitude,
							}}
						/>
					))}
				</Map>
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Händler in der Nähe" />

				<div className="neo-content">
					<NeoDropdown
						title="Entfernung"
						items={[
							{ name: "5", value: 5 },
							{ name: "10", value: 10 },
							{ name: "30", value: 30 },
							{ name: "50", value: 50 },
							{ name: "100", value: 100 },
							{ name: "keine", value: "Keine" },
						]}
						value={
							this.state.radiusFilter === 1000 ? "∞" : this.state.radiusFilter
						}
						onChangeValue={this.handleChangeFilter}
						trailingString="km"
					/>

					{this.state.isLoading ? (
						this.LoadingView()
					) : this.state.locationDenied ? (
						this.LocationDeniedView()
					) : this.state.shops.length > 0 ? (
						this.ShopsList()
					) : (
						<EmptyStateView message="Keine Shops in Ihrer Nähe" />
					)}
				</div>

				<Footer />
			</div>
		);
	}

	loadMore() {
		this.loadShops();
	}

	handleChangeFilter = (item) => {
		this.setState({ radiusFilter: item.value });

		this.checkIfLocationIsAvailable(item.value);
	};
}

export default GoogleApiWrapper({
	apiKey: GOOGLE_MAPS_API_KEY,
})(ShopsMapView);
