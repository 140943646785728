import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import Cookies from "js-cookie";
import axios from "axios";
import {BASE_URL} from "../Constants";
import NeoCheckbox from "../Checkbox/NeoCheckbox";
import LoadingSpinner from "../LoadingSpinner";

class PrivacyBanner extends React.Component {
	state = {
		display: false,
		content: [],
		isLoading: true,
		termsChecked: false,
		privacyChecked: false,
		sendButton: true
	};

	constructor(props) {
		super(props);



		this.handleTermsCheckbox = this.handleTermsCheckbox.bind(this);
		this.handlePrivacyCheckbox = this.handlePrivacyCheckbox.bind(this);
		this.close = this.close.bind(this);
	}

	loadContent() {
		axios.get(BASE_URL + "/globals/content")
			.then(result => {
				const content = result.data.data;

				this.setState({ content, isLoading: false });
			})
	}

	componentDidMount() {
		this.loadContent();
		let display = false;
		if (!localStorage.getItem("CookieBannerConfirmed")) {
			if(Cookies.get("AccessToken")) {
				display = true;
				this.setState({ display: display });

			}

		}

		if (localStorage.getItem("CookieBannerConfirmed")) {
			this.setState({ display: false });
		}
	}

	handleTermsCheckbox(event) {
		const value = event.target.checked;

		this.setState({
			...this.state,
			termsChecked: value,
			errors: [],
		});
	}

	handlePrivacyCheckbox(event) {
		const value = event.target.checked;

		this.setState({
			...this.state,
			privacyChecked: value,
			errors: [],
		});
	}

	LoadingView() {
		return <div style={{marginTop: -80}}><LoadingSpinner title="Laden..." /></div>;
	}

	render() {



		return (this.state.isLoading ? "" :
				<div
				className="neo-privacyBanner"
				style={{
					display: this.state.display ? "true" : "none",
					bottom: "30px",
					height: "95vh"
				}}
			>
				{this.state.display}
				<p
					className="neo-smallTitle"
					style={{ color: "black", textShadow: "none", textAlign: "left" }}
				>
					Nutzungsbedingungen, Datenschutz & Cookies
				</p>
				<p
					className="neo-text"
					style={{ fontSize: "15px", color: "gray", textShadow: "none" }}
					id="leading"
				>

						<div style={{overflow: "scroll", height: "62vh"}}>
							<h3>Datenschutz</h3>
							{(this.state.content["privacy"][0].value)}

							<h3>Nutzungsbedingungen</h3>
							{(this.state.content["terms"][0].value)}
						</div>

				</p>

				<NeoCheckbox
					showTermsButton={true}
					name="termsChecked"
					label="Ich bin mit den GSL Datenschutz einverstanden."
					value={this.state.privacyChecked}
					onChange={this.handlePrivacyCheckbox}
				/>

				<NeoCheckbox
					showTermsButton={true}
					name="termsChecked"
					label="Ich bin mit den GSL Nutzungsbedingungen einverstanden."
					value={this.state.termsChecked}
					onChange={this.handleTermsCheckbox}
				/>

				{
					this.state.sendButton ? <div className="neo-privacyBanner-button" onClick={this.close} style={{marginTop: 30}}>
						OK
					</div> : this.LoadingView()
				}

			</div>
		);
	}

	close() {

		if(!this.state.termsChecked || !this.state.privacyChecked) {
			alert("Bitte akzeptieren Sie die Datenschutzerklärung & die Nutzungsbedingungen.");
			return false
		}
		this.setState({sendButton: false})

		axios
			.post(
				BASE_URL + "/vendor/privacy",
				{ready: true}
			)
			.then((result) => {
				this.setState({ display: false });
				localStorage.setItem("CookieBannerConfirmed", 1);
			})



	}
}

export default PrivacyBanner;
