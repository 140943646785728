import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoForm.css";

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import AuthManager from "../Auth/AuthManager";
import { BASE_URL } from "../Constants";
import NeoCheckbox from "../Checkbox/NeoCheckbox";
import ErrorBox from "../Components/ErrorBox";
import { Link } from "react-router-dom";
import NeoButton from "../Button/NeoButton";
import Imprint from "../Imprint";

class CreateAccount extends React.Component {
	state = {
		email: "",
		password: "",
		password_confirmation: "",
		termsChecked: false,
		errors: [],
		errorMessage: null,
		isSending: false,
		termsVisible: false,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.register = this.register.bind(this);
		this.showTerms = this.showTerms.bind(this);
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	showTerms() {
		this.setState({ termsVisible: !this.state.termsVisible });
	}

	handleChange(event) {
		const value = event.target.value;

		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
	}

	handleCheckbox(event) {
		const value = event.target.checked;

		this.setState({
			...this.state,
			termsChecked: value,
			errors: [],
		});
	}

	register() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		axios
			.post(BASE_URL + "/user/auth/register", {
				email: this.state.email,
				password: this.state.password,
				password_confirmation: this.state.password_confirmation,
				vendor_uuid: id,
			})
			.then((result) => {
				const loginResult = result.data;

				let authManager = new AuthManager();
				authManager.login(loginResult.access_token, loginResult.expires_at);

				if (this.props.location.state === undefined) {
					this.props.history.push("/");
				} else {
					this.props.history.push(this.props.location.state.from.pathname);
				}
			})
			.catch((error) => {
				if (error.response) {
					if (error.response) {
						this.setState({
							isSending: false,
							errors: error.response.data.errors,
							errorMessage: error.response.data.message,
						});
					}
				}
			});
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Konto erstellen" />

				<div className="neo-content">
					<p className="neo-bar-title">Konto erstellen</p>

					<input
						type="text"
						placeholder="E-Mail Adresse"
						name="email"
						value={this.state.email}
						onChange={this.handleChange}
					/>
					<input
						type="password"
						placeholder="Passwort"
						name="password"
						value={this.state.password}
						onChange={this.handleChange}
					/>
					<input
						type="password"
						placeholder="Passwort wiederholen"
						name="password_confirmation"
						value={this.state.password_confirmation}
						onChange={this.handleChange}
					/>

					<p className="neo-textSmall">
						Das Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie
						Buchstaben enthalten.
					</p>

					<NeoCheckbox
						showTermsButton={true}
						name="termsChecked"
						label="Mit der Registrierung bestätigen Sie unsere AGB und Datenschutzbestimmungen."
						value={this.state.termsChecked}
						onChange={this.handleCheckbox}
					/>
					<a class="termsButton" onClick={this.showTerms}>
						Ansehen
					</a>

					<div className="neo-spacer20" />
					<div className="neo-spacer20" />

					{this.ErrorsView()}

					<NeoButton
						showLoadingState={this.state.isSending}
						title="Registrieren"
						disabled={
							this.state.email.length === 0 ||
							this.state.password.length === 0 ||
							this.state.password_confirmation.length === 0 ||
							this.state.termsChecked === false
						}
						onClick={this.register}
					/>
				</div>

				<Footer />

				{this.state.termsVisible ? (
					<div className="modal">
						<div className="modal-content">
							<div className="modal-closeButton" onClick={this.showTerms} />

							<Imprint showNavBar={false} />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default CreateAccount;
