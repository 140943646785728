import React from 'react';
import '../Stylesheets/App.css';
import '../Stylesheets/neo.css';
import './NeoCheckbox.css';

import PropTypes from "prop-types";

class NeoCheckbox extends React.Component {

    render() {
        return (
            <div className="neo-checkbox-container">
                <input type="checkbox" placeholder="Keine Angabe" className="neo-checkbox-checkbox"
                       name={ this.props.name } checked={ this.props.checked }
                       onChange={ this.props.onChange } disabled={ this.props.disabled }
                />

                {
                    <p className="neo-checkbox-label">{ this.props.label }</p>
                }

            </div>
        );
    }
}

NeoCheckbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool
};

export default NeoCheckbox;
