import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/NeoCell.css';

import PropTypes from "prop-types";

function MenuButton(props) {
    return (
        <div className="neo-cell-narrow" onClick={ props.onClick }>
            <div className="neo-cell-horizontalContainer">

                <div className="neo-cell-horizontalContainer">
                    <div className="neo-menuButton-icon" style={{ backgroundImage: "url('/" + props.icon + "')"}}/>
                    <p className="neo-cell-title-centered">{props.title}</p>
                </div>

                {
                    props.badge > 0 ? <p className="neo-cell-badge">{ props.badge }</p> : null
                }
            </div>
        </div>
    );
}

MenuButton.propTypes = {
    title: PropTypes.string.isRequired,
    badge: PropTypes.string
};

export default MenuButton;
