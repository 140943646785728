import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import NeoEditField from "../EditField/NeoEditField";
import NeoButton from "../Button/NeoButton";
import NeoSelectField from "../SelectField/NeoSelectField";
import ErrorBox from "../Components/ErrorBox";
import { format, parse } from "date-fns";
import moment from "moment";
import IDCardView from "./IDCardView";

class Profile extends React.Component {
	state = {
		isLoading: true,
		profile: [],
		isEditing: false,
		errors: null,
		errorMessage: null,
		isSending: false,
	};

	constructor(props) {
		super(props);

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.toggleRahmenMode = this.toggleRahmenMode.bind(this);
		this.saveProfile = this.saveProfile.bind(this);
		this.showChangePassword = this.showChangePassword.bind(this);
		this.showDeleteAccount = this.showDeleteAccount.bind(this);
	}

	componentDidMount() {
		this.loadProfile();
	}

	loadProfile() {
		axios
			.get(BASE_URL + "/user/profile")
			.then((result) => {
				const profile = result.data.data;

				if (profile.profile !== null) {

					this.setState({
						isLoading: false,
						profile: {
							gender: profile.profile.gender ?? "male",
							firstname: profile.profile.firstname,
							lastname: profile.profile.lastname,
							web: "",
							telephone: profile.profile.telephone,
							contact_email: profile.profile.contact_email,
							cr_number: profile.profile.cr_number,
							sti_number: profile.profile.sti_number,
							company_name: profile.company_name,
							street: profile.address == null ? "" : profile.address.street,
							zip: profile.address == null ? "" : profile.address.zip,
							city: profile.address == null ? "" : profile.address.city,
							birthdate: this.isDateSupported()
								? profile.profile.birthdate
								: moment(profile.profile.birthdate).format("DD.MM.YYYY"),
							iban: profile.profile.iban,
							bic: profile.profile.bic,
							company: profile.company,
							generalagreement: profile.generalagreement
						},

						errors: null,
						errorMessage: null,
					});
				} else {
					this.setState({
						profile: {
							company: profile.company,
							company_name: null,
							cr_number: "",
							sti_number: "",
						},
						isLoading: false,
						errors: null,
						errorMessage: null,
					});
				}
			})
			.catch((e) => {
				console.log(e)
				this.setState({isLoading: false})
			});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	handleFieldChange(event) {
		const value = event.target.value;
		const name = event.target.name;

		this.setState((prevState) => ({
			profile: {
				...prevState.profile,
				[name]: value,
			},
		}));
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	isDateSupported() {
		var input = document.createElement("input");
		var value = "a";
		input.setAttribute("type", "date");
		input.setAttribute("value", value);
		return input.value !== value;
	}

	Profile() {
		return (
			<div>
				<p className="neo-bar-title">Mein Profil</p>

				{this.ErrorsView()}

				<IDCardView />

				<NeoEditField
					label="Firma"
					name="company_name"
					value={this.state.profile.company_name ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="HR-Nummer (optional)"
					name="cr_number"
					value={this.state.profile.cr_number ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Ust-ID (optional)"
					name="sti_number"
					value={this.state.profile.sti_number ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Straße"
					name="street"
					value={this.state.profile.street ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="PLZ"
					name="zip"
					value={this.state.profile.zip ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Ort"
					name="city"
					value={this.state.profile.city ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>

				<div className="neo-spacer20" />

				<NeoSelectField
					name="gender"
					label="Geschlecht"
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
					value={this.state.profile.gender ?? "male"}
					items={[
						<option key="male" value="male">
							Männlich
						</option>,
						<option key="female" value="female">
							Weiblich
						</option>,
						<option key="diverse" value="diverse">
							Divers
						</option>,
					]}
				/>

				<NeoEditField
					label="Vorname des wirtschaftlich Berechtigten"
					name="firstname"
					value={this.state.profile.firstname ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Nachname des wirtschaftlich Berechtigten"
					name="lastname"
					value={this.state.profile.lastname ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Telefon (optional)"
					name="telephone"
					value={this.state.profile.telephone ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="E-Mail"
					name="contact_email"
					value={this.state.profile.contact_email ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Geburtsdatum"
					type="date"
					name="birthdate"
					value={this.state.profile.birthdate ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>

				<div className="neo-spacer20" />

				<NeoEditField
					label="IBAN"
					name="iban"
					value={this.state.profile.iban ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="BIC (optional)"
					name="bic"
					value={this.state.profile.bic ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />




				<NeoButton
					showLoadingState={this.state.isSending}
					title={this.state.isEditing ? "Profil sichern" : "Profil bearbeiten"}
					onClick={this.toggleEditMode}
				/>

				{this.ErrorsView()}

				{!this.state.isEditing ? (
					<div>
						<NeoButton
							title="Mein Passwort ändern"
							onClick={this.showChangePassword}
						/>
						<NeoButton
							title="Konto löschen ..."
							onClick={this.showDeleteAccount}
							isDestructive={true}
						/>
					</div>
				) : null}
			</div>
		);
	}

	toggleRahmenMode() {
		this.setState({ isSending: true });

		axios
			.post(BASE_URL + "/user/profile-generalagreement", {})
			.then((data) => {
				this.state.profile.generalagreement = data.data;

				this.setState({ isSending: false, profile: this.state.profile });
			})
			.catch((error) => {
				console.log(error.response);
			});

	}

	toggleEditMode() {
		if (this.state.isEditing) {
			this.saveProfile();
		} else {
			this.setState({ isEditing: !this.state.isEditing });
		}
	}

	showChangePassword() {
		this.props.history.push("/profile/password");
	}

	showDeleteAccount() {
		this.props.history.push("/profile/delete");
	}

	saveProfile() {
		this.setState({ isSending: true });

		if (this.state.profile.company !== null) {
			if (this.state.profile.company.is_company_admin) {
				axios
					.post(BASE_URL + "/user/company/update", {
						name: this.state.profile.company_name,
						cr_number: this.state.profile.cr_number,
						sti_number: this.state.profile.sti_number,
					})
					.then(() => {})
					.catch((error) => {
						console.log(error.response);
					});
			}
		}

		axios
			.post(BASE_URL + "/user/profile", {
				gender: this.state.profile.gender ?? "male",
				firstname: this.state.profile.firstname,
				lastname: this.state.profile.lastname,
				telephone: this.state.profile.telephone,
				contact_email: this.state.profile.contact_email,
				cr_number: this.state.profile.cr_number,
				sti_number: this.state.profile.sti_number,
				company_name: this.state.profile.company_name,
				street: this.state.profile.street,
				zip: this.state.profile.zip,
				city: this.state.profile.city,
				birthdate: this.isDateSupported()
					? this.state.profile.birthdate
					: this.state.profile.birthdate
					? format(
							parse(this.state.profile.birthdate, "dd.MM.yyyy", new Date()),
							"yyyy-MM-dd"
					  )
					: null,
				iban: this.state.profile.iban,
				bic: this.state.profile.bic,
			})
			.then(() => {
				this.setState({
					isSending: false,
					isEditing: !this.state.isEditing,
					errors: null,
					errorMessage: null,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Mein Profil" />
				<div className="neo-content">
					{this.state.isLoading ? this.LoadingView() : this.Profile()}
				</div>

				<Footer />
			</div>
		);
	}
}

export default Profile;
