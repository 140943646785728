import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import NeoButton from "./Button/NeoButton";
import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Components/Footer";
import { BASE_URL } from "./Constants";
import axios from "axios";
import ConfirmationView from "./ConfirmationView";
import ErrorBox from "./Components/ErrorBox";
import NeoCheckbox from "./Checkbox/NeoCheckbox";
import { Link } from "react-router-dom";
import ExampleContract from "./ExampleContract";
import IDCardView from "./Profile/IDCardView";
import LoadingSpinner from "./LoadingSpinner";

class AcceptOffer extends React.Component {
	state = {
		isSent: false,
		file1: null,
		file2: null,
		termsChecked: false,
		termsVisible: false,

		errorMessage: null,
		errors: null,
		isSending: false,

		identityCardFront: null,
		identityCardBack: null,
		isLoading: true,
	};

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.send = this.send.bind(this);
		this.chooseFile = this.chooseFile.bind(this);
		this.deleteImage1 = this.deleteImage1.bind(this);
		this.deleteImage2 = this.deleteImage2.bind(this);
		this.ErrorsView = this.ErrorsView.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.showTerms = this.showTerms.bind(this);
	}

	componentDidMount() {
		this.loadCurrentIDCard();
	}

	loadCurrentIDCard() {
		this.setState({ isLoading: true });

		axios
			.get(BASE_URL + "/user/identity-card")
			.then((result) => {
				console.log(result);

				if (result.status === 200) {
					this.setState({
						identityCardFront: result.data.front,
						identityCardBack: result.data.back,
						file1: result.data.front,
						file2: result.data.back,
					});
				}

				this.setState({ isLoading: false });
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
					identityCardFront: null,
					identityCardBack: null,
					file1: null,
					file2: null,
				});
			});
	}

	showTerms() {
		this.setState({ termsVisible: !this.state.termsVisible });
	}

	onChange(event) {
		if (this.state.file1 === null) {
			this.setState({
				file1: event.target.files[0],
				previewName1: URL.createObjectURL(event.target.files[0]),
			});
		} else {
			this.setState({
				file2: event.target.files[0],
				previewName2: URL.createObjectURL(event.target.files[0]),
			});
		}
	}

	handleCheckbox(event) {
		const value = event.target.checked;

		this.setState({
			...this.state,
			termsChecked: value,
			errors: [],
		});
	}

	UploadView() {
		return (
			<div>
				{this.state.file1 !== null ? this.ImagePreview1() : null}

				{this.state.file2 !== null ? this.ImagePreview2() : null}

				{this.state.file1 === null || this.state.file2 === null ? (
					<div>
						<NeoButton title="Bild hochladen..." onClick={this.chooseFile} />

						<form>
							<input
								type="file"
								className="neo-hiddenUpload"
								accept=".png,.jpeg,.jpg"
								onChange={this.onChange}
								ref={(input) => (this.state.inputElement = input)}
							/>
						</form>
					</div>
				) : null}
			</div>
		);
	}

	AcceptOffer() {
		return (
			<div>
				<div
					className="neo-iconCircle"
					style={{ backgroundImage: "url('/credit_check.png')" }}
				/>

				<p className="neo-text">
					Um dieses Angebot anzunehmen, muss Ihre Bonität geprüft werden. Laden
					Sie bitte zu diesem Zweck die Vorder- und Rückseite Ihres
					Personalausweises hoch.
				</p>

				{this.state.identityCardFront === null ? (
					this.UploadView()
				) : (
					<IDCardView
						text="Ihr gespeicherter Personalausweis wird verwendet, um Ihre Anfragen zu prüfen. Er ist 12 Monate in Ihrem Konto verfügbar, bevor er erneut hochgeladen werden muss. Sie können ihn in Ihrem Profil löschen und erneut hochladen."
						hideDeleteButton
					/>
				)}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<div className="neo-card" style={{ marginBottom: "20px" }}>
					<NeoCheckbox
						showTermsButton={true}
						name="termsChecked"
						label="Ich willige ein, dass die German SmartLease GmbH der Creditreform Hamburg von der Decken KG, Wandalenweg 8-10, 20097 Hamburg, www.creditreform.de/hamburg und der Schufa Holding AG, Hagenauer Strasse 44, 65203 Wiesbaden Daten über die Beantragung, die Aufnahme (Leasingnehmer, Bürge, Summe aller Leasingraten, Laufzeit, Ratenbeginn) und vereinbarungsgemäße Abwicklung (z.B. vorzeitige Vertragsbeendigung, Laufzeitverlängerung) dieser Geschäftsverbindung übermittelt."
						value={this.state.termsChecked}
						onChange={this.handleCheckbox}
					/>
					<a class="termsButton" onClick={this.showTerms}>
						Ansehen
					</a>
				</div>

				{this.ErrorsView()}

				<NeoButton
					title="Verbindliche Leasinganfrage senden"
					showLoadingState={this.state.isSending}
					onClick={this.send}
					disabled={
						this.state.file1 === null ||
						this.state.file2 === null ||
						this.state.termsChecked === false
					}
				/>

				{this.state.termsVisible ? (
					<div className="modal">
						<div className="modal-content">
							<div className="modal-closeButton" onClick={this.showTerms} />

							<ExampleContract showNavBar={false} />
						</div>
					</div>
				) : null}
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					{this.state.isLoading ? (
						<LoadingSpinner title="Laden..." />
					) : this.state.isSent ? (
						<ConfirmationView
							text="Wir benachrichtigen Sie per E-Mail, wenn die Prüfung Ihrer Angaben abgeschlossen ist und der Leasingvertrag zur Unterschrift bereitsteht."
							icon="confirmation.png"
						/>
					) : (
						this.AcceptOffer()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	chooseFile() {
		this.state.inputElement.click();
	}

	ImagePreview1() {
		return (
			<div style={{ position: "relative", zIndex: 1 }}>
				<img
					className="neo-idCardPreview"
					src={this.state.previewName1}
					alt=""
				/>

				<div className="neo-overlay">
					<div className="neo-deleteButton" onClick={this.deleteImage1} />
				</div>
			</div>
		);
	}

	ImagePreview2() {
		return (
			<div style={{ position: "relative", zIndex: 1 }}>
				<img
					className="neo-idCardPreview"
					src={this.state.previewName2}
					alt=""
				/>

				<div className="neo-overlay">
					<div className="neo-deleteButton" onClick={this.deleteImage2} />
				</div>
			</div>
		);
	}

	send() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		const url = BASE_URL + "/user/offer-accept";
		const formData = new FormData();

		if (this.state.identityCardFront === null) {
			formData.append("id_card_front", this.state.file1);
			formData.append("id_card_back", this.state.file2);
		}

		formData.append("offer_id", id);

		const config = {
			headers: {
				Accept: "application/json",
				"content-type": "multipart/form-data",
			},
		};

		return axios
			.post(url, formData, config)
			.then(() => {
				this.setState({
					isSent: true,
					isSending: false,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors ?? null,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	deleteImage1() {
		this.setState({
			file1: null,
			previewName1: null,
		});
	}

	deleteImage2() {
		this.setState({
			file2: null,
			previewName2: null,
		});
	}
}

export default AcceptOffer;
