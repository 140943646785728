import React from "react";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import ShopCell from "../Shop/ShopCell";
import NeoDropdown from "../Dropdown/NeoDropdown";
import ErrorBox from "../Components/ErrorBox";
import axios from "axios";
import { BASE_URL } from "../Constants";
import Currency from "react-currency-formatter";
import LoadingSpinner from "../LoadingSpinner";
import ErrorView from "../ErrorView";
import NeoButton from "../Button/NeoButton";
import ConfirmationView from "../ConfirmationView";
import { nameForInsurance } from "../Constants";

class ReviewCustomerOffer extends React.Component {
	state = {
		company_name: "",
		street: "",
		zip: "",
		city: "",

		rate: 0,
		insurance_fee: 0,
		objectName: "",
		objectNumber: "",
		objectManufacturer: "",
		objectPrice: "",
		leasingDuration: 36,
		accessories: "",
		accessoriesPrice: "",
		delivery: "pickup",
		social_security_billing: 10,

		errors: [],
		errorMessage: null,
		isSent: false,
		isLoading: true,
		noUserFound: false,
		isSending: false,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.sendOffer = this.sendOffer.bind(this);
		this.loadOffer = this.loadOffer.bind(this);
		this.deleteOffer = this.deleteOffer.bind(this);
		this.calculateLeasingRate = this.calculateLeasingRate.bind(this);
		this.recalculateLeasingRate = this.recalculateLeasingRate.bind(this);
	}

	componentDidMount() {
		this.loadOffer();
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	loadOffer() {
		const { id } = this.props.match.params;

		axios
			.get(BASE_URL + "/vendor/offer-request/" + id)
			.then((result) => {
				const offer = result.data.data;
				const user = result.data.data.user;
				console.log(offer);

				this.setState({
					objectName: offer.object_name,
					objectManufacturer: offer.object_manufacturer,
					accessories: offer.equipment,
					leasingDuration: offer.leasing_duration,
					userUUID: user.uuid,
					offer_request_id: offer.id,
					insurance: offer.insurance,
					branch_id: offer.branch.id,
					delivery: offer.delivery,
					social_security_billing: offer.social_security_billing
				});

				if (user.address === null) {
					this.setState({
						company_name: user.company_name ?? user.email,
						isLoading: false,
					});
				} else {
					this.setState({
						company_name: user.company_name ?? user.email,
						street: user.address.street ?? "",
						zip: user.address.zip ?? "",
						city: user.address.city ?? "",
						isLoading: false,
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ noUserFound: true, isLoading: false });
				}
			});
	}

	sendOffer() {
		this.setState({ isSending: true });

		let branchID = this.state.branch_id;

		axios
			.post(BASE_URL + "/vendor/offer-create", {
				object_name: this.state.objectName,
				object_price: this.objectPrice(),
				object_number: this.state.objectNumber,
				object_manufacturer: this.state.objectManufacturer,
				equipment: this.state.accessories,
				equipment_price: this.accessoriesPrice(),
				leasing_duration: this.state.leasingDuration,
				total_price: this.totalPrice(),
				user_uuid: this.state.userUUID,
				offer_request_id: this.state.offer_request_id,
				insurance: this.state.insurance_available
					? this.state.insurance
					: "none",
				insurance_fee: this.state.insurance_fee,
				branch_id: branchID,
				delivery: this.state.delivery,
				social_security_billing: this.state.social_security_billing
			})
			.then(() => {
				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	calculateLeasingRate(duration) {
		axios
			.post(BASE_URL + "/vendor/calc-rate", {
				leasing_duration: duration,
				total_price: this.totalPrice(),
				insurance: this.state.insurance,
			})
			.then((result) => {
				const data = result.data;
				this.setState({
					rate: data.rate,
					insurance_fee: data.insurance,
					insurance_available: data.insurance_available,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	recalculateLeasingRate() {
		this.calculateLeasingRate(this.state.leasingDuration);
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
	}

	handleChangeDuration = (duration) => {
		this.setState({ leasingDuration: duration.value });

		this.calculateLeasingRate(duration.value);
	};

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					<p className="neo-bar-title">Angebot erstellen</p>

					{this.state.isLoading ? (
						this.LoadingView()
					) : this.state.noUserFound === true ? (
						<ErrorView message="Dieser Interessent konnte nicht gefunden werden." />
					) : this.state.isSent ? (
						<ConfirmationView
							text="Sie haben das Angebot erfolgreich an den Kunden übermittelt."
							icon="confirmation.png"
						/>
					) : (
						this.OfferForm()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	deleteOffer() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		axios
			.delete(BASE_URL + "/vendor/offer-request/" + id, {})
			.then(() => {
				this.props.history.push("/vendor/offers/direct");
			})
			.catch(() => {
				this.setState({ isSending: false });
			});
	}

	OfferForm() {
		return (
			<div>
				<NeoButton title="Anfrage löschen" onClick={this.deleteOffer} />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<ShopCell
					title={this.state.company_name}
					subtitle1={this.state.street}
					subtitle2={this.state.zip + " " + this.state.city}
					uuid={"UUID"}
					disableClick={true}
				/>
				<div className="neo-spacer20" />
				<input
					type="text"
					placeholder="Name des Leasingobjekts"
					name="objectName"
					value={this.state.objectName}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Nummer des Leasingobjekts (optional)"
					name="objectNumber"
					value={this.state.objectNumber}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Hersteller"
					name="objectManufacturer"
					value={this.state.objectManufacturer}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Preis des Leasingobjekts netto"
					name="objectPrice"
					value={this.state.objectPrice}
					onChange={this.handleChange}
				/>
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<textarea
					placeholder="Zubehör ohne Arbeitsleistung/Auslieferungskosten (optional)"
					name="accessories"
					value={this.state.accessories}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Preis des Zubehörs ohne Arbeitsleistung/Auslieferungskosten (optional)"
					name="accessoriesPrice"
					value={this.state.accessoriesPrice}
					onChange={this.handleChange}
				/>
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<NeoDropdown
					title="Leasingdauer"
					items={[{ name: "36", value: 36 }]}
					value={this.state.leasingDuration}
					onChangeValue={this.handleChangeDuration}
					trailingString="Monate"
				/>
				<div className="neo-card">
					<p className="neo-mediumTitle" style={{ marginBottom: 0 }}>
						Gesamt:{" "}
						<Currency quantity={this.totalPrice()} currency="EUR" decimal="," />
					</p>

					<div className="neo-card-cell" style={{marginTop: "20px"}}>
						<label htmlFor="none" className="neo-text">
							<span>Leasingrate</span>
							<span style={{color: "gray"}}>
								{" "}
								<Currency
									quantity={this.state.rate}
									currency="EUR"
									decimal=","
								/>{" "}
								mtl.
							</span>
						</label>
					</div>

					<div className="neo-card-cell" style={{ marginTop: "20px" }}>
						<label for="none" className="neo-text">
							<span>Abholung oder Lieferung</span>
							<span style={{ color: "gray" }}>
								{this.state.delivery == "pickup" ? "Ich hole das Leasingobjekt ab": "Ich möchte das Leasingobjekt zugeschickt bekommen"}
							</span>
						</label>
					</div>

					<div className="neo-card-cell">
						<label for="none" className="neo-text">
							<span>
								Gewählte Versicherung: {nameForInsurance(this.state.insurance)}
							</span>
							{this.state.insurance_available ? (
								<span style={{ color: "gray" }}>
									<Currency
										quantity={this.state.insurance_fee}
										currency="EUR"
										decimal=","
									/>{" "}
									mtl.
								</span>
							) : (
								<span style={{ color: "gray" }}>
									Versicherung nicht verfügbar
								</span>
							)}
						</label>
					</div>

					<NeoButton title="Berechnen" onClick={this.recalculateLeasingRate} />
				</div>
				{this.ErrorsView()}
				<NeoButton
					title="Angebot senden"
					showLoadingState={this.state.isSending}
					onClick={this.sendOffer}
					disabled={
						this.state.objectName.length === 0 ||
						this.state.objectManufacturer.length === 0 ||
						this.state.objectPrice.length === 0 ||
						this.state.rate === 0 ||
						this.totalPrice() === 0
					}
				/>
			</div>
		);
	}

	totalPrice() {
		let objectPrice = this.state.objectPrice ? this.state.objectPrice : "0";
		let accessoriesPrice = this.state.accessoriesPrice
			? this.state.accessoriesPrice
			: "0";



		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");

		accessoriesPrice = accessoriesPrice.replace(".", "");
		accessoriesPrice = accessoriesPrice.replace(",", ".");
console.log(objectPrice)
		if (isNaN(objectPrice) || isNaN(accessoriesPrice)) {
			return 0;
		}

		return parseFloat(objectPrice) + parseFloat(accessoriesPrice);
	}

	objectPrice() {
		let objectPrice = this.state.objectPrice ? this.state.objectPrice : "0";
		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");

		return parseFloat(objectPrice);
	}

	accessoriesPrice() {
		let accessoriesPrice = this.state.accessoriesPrice
			? this.state.accessoriesPrice
			: "0";
		accessoriesPrice = accessoriesPrice.replace(".", "");
		accessoriesPrice = accessoriesPrice.replace(",", ".");

		return parseFloat(accessoriesPrice);
	}
}

export default ReviewCustomerOffer;
