import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import {EXAMPLE_CONTRACT_URL} from "./Constants";
import axios from "axios";
import {BASE_URL} from "./Constants";
import LoadingSpinner from "./LoadingSpinner";

class ExampleContract extends React.Component {

    state = {
        isLoading: true,
        content: null,
    };

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        axios.get(BASE_URL + "/globals/content")
            .then(result => {
                const content = result.data;

                this.setState({ content, isLoading: false });
            });
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    MainView() {
        return (
            this.props.showNavBar ?? true ?
                <div className="neo-app">
                    <NavigationBar/>

                    <div className="neo-content">
                        {
                            this.state.isLoading ? this.LoadingView() : this.state.content.data != null ? this.Content() : null
                        }
                    </div>

                    <Footer/>
                </div>
                :
                <div className="neo-content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.state.content.data != null ? this.Content() : null
                    }
                </div>
        );
    }

    Content() {
        return (
            <div>
                <p className="neo-text">
                    Hier finden Sie einen Mustervertrag, der dem Leasingvertrag bei der Annahme eines Angebots entspricht.
                </p>

                <button className="neo-button" onClick={ this.downloadContract }>Herunterladen</button>

                <p className="neo-text" id="leading" style={{ marginTop: "50px" }}>
                    { this.state.content.data.creditreform[0].value ?? "" }
                </p>
            </div>
        )
    }

    render() {
        return (
            this.MainView()
        );
    }

    downloadContract() {
        window.open(EXAMPLE_CONTRACT_URL, "_blank");
    }
}

export default ExampleContract;
