import React from "react";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import NeoDropdown from "../Dropdown/NeoDropdown";
import ErrorBox from "../Components/ErrorBox";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import NeoButton from "../Button/NeoButton";
import Currency from "react-currency-formatter";

class Expertenrechner extends React.Component {
	state = {
		company_name: "",
		street: "",
		zip: "",
		city: "",
		hasCompanyGroup: false,

		rate: null,
		objectName: "",
		objectNumber: "",
		objectManufacturer: "",
		leasingDuration: 36,
		accessories: "",
		insurance: "business_and_private",

		useCompanyGroup: false,
		useGeneralAgreement: false,
		generalagreement: false,
		socialSecurityBilling: 10,
		delivery: "pickup",

		errors: [],
		errorMessage: null,
		isSent: false,
		isLoading: true,
		isLoadingProfile: true,
		noVendorFound: false,
		isSending: false,
		isProfileComplete: true,
		selectedStore: null,

		buying_price: null,
		buying_price_rest: null,
		buying_uvp: null,
		salary: null,
		extra: null,

		insurance_fee: null,
		insurance_available: null,

		childs: 0,
		childs_rate: 1,

		krankenversicherung: null,
		krankenversicherung_rate: null,
		krankenversicherung_extra: null,
		krankenversicherung_extra_rate: null,
		pflegeversicherung: null,
		pflegeversicherung_rate: null,
		children: null,
		children_rate: null,
		renten: null,
		renten_rate: null,
		arbeitslosenversicherung: null,
		arbeitslosenversicherung_rate: null,
		sozial: null,
		sozial_rate: null,
		new_slaray: null,
		new_slaray_rate: null,

		kirche: null,
		kirche_rate: null,
		lohnsteuer: null,
		lohnsteuer_rate: null,
		company_price: null,


		leasing_ma_rabatt: null,
		steuerklasse: 1,

		select_rentenversicherung: 1,
		select_kirche: 1,
		select_krankenkasse: 1,
		full_rabatt: 0
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onRadioSocialChange = this.onRadioSocialChange.bind(this);
		this.onRadioDeliverChange = this.onRadioDeliverChange.bind(this);
		this.calculate = this.calculate.bind(this);
	}

	componentDidMount() {
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	getCurrentStore(stores, id) {
		return stores.find((element) => {
			return element.id === parseInt(id, 10);
		});
	}

	handleChange(event) {
		let value = event.target.value;
		if(event.target.name== "childs") {
			value = value.replace(",", ".");
		}
		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
		if(event.target.name == "buying_price") {
			this.setState({
				"buying_price_rest": (value * 1.10) - value,
			});
		}

	}

	handleCheckbox(event) {
		const value = event.target.checked;

		this.setState({
			useCompanyGroup: value,
			errors: [],
		});
	}

	handleChangeDuration = (duration) => {
		this.setState({ leasingDuration: duration.value });

		//this.calculateLeasingRate(duration.value);
	};

	totalPrice() {
		let objectPrice = this.state.buying_price ? this.state.buying_price : "0";
		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");

		return parseFloat(objectPrice);
	}

	calculate() {
		if((this.state.salary) > 0) {

		}else{
			return false;
		}


		let krankenversicherung = 7.3 * this.state.salary.replace(",", ".") / 100;
		if(krankenversicherung > 769) {
			krankenversicherung = 769.16;
		}

		const krankenversicherung_extra =  this.state.extra != null ? (this.state.extra.replace(",", ".") * this.state.salary.replace(",", ".") / 100) / 2 : 0;
		let pflegeversicherung = (this.state.childs > 0 ? 1.525 : 1.875 ) * this.state.salary.replace(",", ".") / 100;
		if(pflegeversicherung > 384) {
			pflegeversicherung = 384.58;
		}

		const children = (this.state.childs * 0.25) * this.state.salary.replace(",", ".") / 100;
		const renten = 9.3 * this.state.salary.replace(",", ".") / 100;
		const arbeitslosenversicherung = 1.2 * this.state.salary.replace(",", ".") / 100;
		const kirche = 0.9 * this.state.salary.replace(",", ".") / 100;
		let leasing_ma_rabatt = 0.25 * this.state.buying_price.replace(",", ".") / 100;
		const leasing_ma_rabatt_new = (leasing_ma_rabatt).toFixed(0)
		leasing_ma_rabatt = leasing_ma_rabatt_new;

		axios
			.get(
				BASE_URL + "/user/calc_income_tax?salary="+( ((this.state.salary.replace(",", ".") * 12) * 100) )+"&price="+parseInt( ((this.state.buying_price.replace(",", ".")) * 100) / 1.19 )+"&stk="+this.state.steuerklasse,
			)
			.then((response) => {

				let new_slaray_rate = parseFloat(this.state.salary.replace(",", ".") - response.data.insurance - response.data.leasingRate);


				let krankenversicherung_rate = 7.3 * new_slaray_rate / 100;
				if(krankenversicherung_rate > 769) {
					krankenversicherung_rate = 769.16;
				}

				const krankenversicherung_extra_rate = this.state.extra != null ? (this.state.extra.replace(",", ".") * new_slaray_rate / 100) / 2 : 0;
				let pflegeversicherung_rate = (this.state.childs > 0 ? 1.525 : 1.875 ) * new_slaray_rate / 100;
				if(pflegeversicherung_rate > 384) {
					pflegeversicherung_rate = 384.58;
				}

				const children_rate = (this.state.childs * 0.25) * new_slaray_rate / 100;
				const renten_rate = 9.3 * new_slaray_rate / 100;

				const arbeitslosenversicherung_rate = 1.2 * new_slaray_rate / 100;
				const kirche_rate = 0.9 * new_slaray_rate / 100;


				let full = response.data.lohnsteuer + pflegeversicherung + children + arbeitslosenversicherung;
				let full_neu = response.data.lohnsteuer_rate + pflegeversicherung_rate + children_rate + arbeitslosenversicherung_rate;

				if(this.state.select_rentenversicherung == 1) {
					full_neu += renten_rate;
					full += renten;
				}

				if(this.state.select_kirche == 1) {
					full_neu += kirche_rate;
					full += kirche;
				}

				if(this.state.select_krankenkasse == 1) {
					full_neu += krankenversicherung_rate + krankenversicherung_extra_rate
					full += krankenversicherung + krankenversicherung_extra
				}
				//alert(response.data.lohnsteuer+' + '+pflegeversicherung + ' + ' + children + ' + ' + arbeitslosenversicherung + ' + ' + renten + ' + ' + kirche + ' + ' + krankenversicherung + ' + ' + krankenversicherung_extra)

				const new_slaray = this.state.salary - full;

				console.log(new_slaray_rate)
				console.log(new_slaray_rate +"-"+ full_neu)

				console.log(krankenversicherung_rate+"-"+krankenversicherung_extra_rate+"-"+pflegeversicherung_rate+"-"+renten_rate+"-"+	arbeitslosenversicherung_rate)
				new_slaray_rate = new_slaray_rate - full_neu;
				let rabatt = 0;

				rabatt = (new_slaray - new_slaray_rate);
				rabatt = rabatt*36;
				rabatt += this.state.buying_price_rest;

				//(() * 36) + this.state.buying_price_rest
				this.setState({
					krankenversicherung: (this.state.select_krankenkasse == 1) ? krankenversicherung+krankenversicherung_extra : 0,
					krankenversicherung_rate: (this.state.select_krankenkasse == 1) ? krankenversicherung_rate+krankenversicherung_extra_rate : 0,
					krankenversicherung_extra: (this.state.select_krankenkasse == 1) ? krankenversicherung_extra : 0,
					pflegeversicherung_rate: pflegeversicherung_rate,
					pflegeversicherung: pflegeversicherung,
					children_rate: children_rate,
					children: children,
					renten_rate: (this.state.select_rentenversicherung == 1) ? renten_rate : 0,
					renten: (this.state.select_rentenversicherung == 1) ? renten : 0,
					arbeitslosenversicherung: arbeitslosenversicherung,
					arbeitslosenversicherung_rate: arbeitslosenversicherung_rate,
					sozial: full,
					sozial_rate: full_neu,
					kirche: (this.state.select_kirche == 1) ? kirche : 0,
					kirche_rate: (this.state.select_kirche == 1) ? kirche_rate : 0,
					new_slaray: new_slaray,
					new_slaray_rate: new_slaray_rate,
					lohnsteuer: response.data.lohnsteuer,
					lohnsteuer_rate: response.data.lohnsteuer_rate,
					rate: response.data.leasingRate,
					leasing_ma_rabatt: leasing_ma_rabatt,
					insurance_fee: response.data.insurance,
					full_rabatt: rabatt
				})

			})


	}


	calculateLeasingRate(duration) {
		axios
			.post(BASE_URL + "/vendor/calc-rate", {
				leasing_duration: 36,
				total_price: this.totalPrice(),
				insurance: "business_and_private",
			})
			.then((result) => {
				const data = result.data;
				console.log(data);
				this.setState({
					rate: data.rate,
					insurance_fee: data.insurance,
					insurance_available: data.insurance_available,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});

		console.log({
			leasing_duration: duration,
			total_price: this.totalPrice(),
			insurance: this.state.insurance,
		});
	}


	render() {
		return (
			<div className="neo-app">
				<NavigationBar showBackButton={true} />

				<div className="neo-content">
					<p className="neo-bar-title">Einfacher Leasing Vorteilsrechner bei vorsteuerabzugsberechtigten Unternehmen</p>

					{this.OfferForm()}
				</div>

				<Footer />
			</div>
		);
	}

	onRadioDeliverChange(event) {
		this.setState({
			deliver: event.target.value,
		});
	}

	onRadioSocialChange(event) {
		this.setState({
			socialSecurityBilling: event.target.value,
		});
	}

	onRadioChange(event) {
		this.setState({
			insurance: event.target.value,
		});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	handleChangeStore = (item) => {
		this.setState({ steuerklasse: item["value"] });
	};

	handleChangeKirche = (item) => {

		this.setState({ select_kirche: item["value"] });
	};

	handleChangeKrankenkasse = (item) => {

		this.setState({ select_krankenkasse: item["value"] == "privat" ? 0 : 1 });
	};

	handleChangeRente = (item) => {

		this.setState({ select_rentenversicherung: item["value"] });
	};


	OfferForm() {
		const { id } = this.props.match.params;

		return (
			<div>


				<input
					type="text"
					placeholder="Kaufpreis brutto (€)"
					name="buying_price"
					value={this.state.buying_price}
					onChange={this.handleChange}
				/>

				<input
					type="text"
					placeholder="Unverbindliche Preisempfehlung (UVP) brutto (€)"
					name="buying_uvp"
					value={this.state.buying_uvp}
					onChange={this.handleChange}
				/>

				<div className="neo-spacer20" />

				<input
					type="text"
					placeholder="Bruttogehalt"
					name="salary"
					value={this.state.salary}
					onChange={this.handleChange}
				/>

				<input
					type="text"
					placeholder="individuell eintragbarem Anteil € vom Arbeitgeber"
					name="company_price"
					value={this.state.company_price}
					onChange={this.handleChange}
				/>

				<p>
					Steuerklasse
				</p>
				<NeoDropdown
					title="Steuerklasse"
					items={
						[
							{
								name: "I",
								value: 1,
							},
							{
								name: "II",
								value: 2,
							},
							{
								name: "III",
								value: 3,
							},
							{
								name: "IV",
								value: 4,
							},
							{
								name: "V",
								value: 5,
							},
							{
								name: "VI",
								value: 6,
							}
						]
					}
					value={
						this.state.selectedStore === null
							? "Auswählen..."
							: this.state.selectedStore.name
					}
					onChangeValue={this.handleChangeStore}
					trailingString=""
				/>

				<p>
					Kinderfreibetrag
				</p>
				<input
					type="text"
					placeholder="Kinderfreibetrag"
					name="childs"
					value={this.state.childs}
					onChange={this.handleChange}
				/>

				<p>
					Kirchensteuerpflichtig
				</p>
				<NeoDropdown
					title="Kirchensteuerpflichtig"
					items={
						[
							{
								name: "Ja",
								value: 1,
							},
							{
								name: "Nein",
								value: 0,
							}
						]
					}
					value={
						this.state.selectedStore === null
							? "Auswählen..."
							: this.state.selectedStore.name
					}
					onChangeValue={this.handleChangeKirche}
					trailingString=""
				/>

				<p>
					Krankenversicherung
				</p>
				<NeoDropdown
					title="Krankenversicherung"
					items={
						[
							{
								name: "Gesetzlich (14,6%)",
								value: "gesetzlich",
							},
							{
								name: "Privat (0)",
								value: "privat",
							}
						]
					}
					value={
						this.state.selectedStore === null
							? "Auswählen..."
							: this.state.selectedStore.name
					}
					onChangeValue={this.handleChangeKrankenkasse}
					trailingString=""
				/>

				{
					this.state.select_krankenkasse == 1 ?
						<input
							type="text"
							placeholder="Krankenversicherung Zusatz (%)"
							name="extra"
							value={this.state.extra}
							onChange={this.handleChange}
						/>
						:
						null
				}


				<div className="neo-spacer20" />

				<p>
					gesetzliche Rentenversicherung
				</p>
				<NeoDropdown
					title="Rentenversicherung"
					items={
						[
							{
								name: "Ja",
								value: 1,
							},
							{
								name: "Nein",
								value: 0,
							}
						]
					}
					value={
						this.state.selectedStore === null
							? "Auswählen..."
							: this.state.selectedStore.name
					}
					onChangeValue={this.handleChangeRente}
					trailingString=""
				/>

				<div className="neo-spacer20" />

				{this.ErrorsView()}

				<NeoButton
					title="Berechnen"
					showLoadingState={this.state.isSending}
					onClick={() => this.calculate()}
					disabled={
						false
					}
				/>

				<p>
					<small>Unser Vorteilsrechner basiert auf den neuesten veröffentlichten Steuerinformationen des Finanzministeriums. Es wird das aktuelle Einkommenssteuerjahr als Grundlage verwendet.
						<br/>German SmartLease garantiert keine zukünftigen Änderungen im Steuersystem. Diese Berechnung ist indikativ. Der tatsächlich zu zahlende Endbetrag kann daher abweichen.</small>
				</p>

				{
					this.state.rate == null ? null :
						<div>
							<p className="neo-bar-title">Ihre individuelle Ersparnisrechnung</p>
							<table className={"styled-table"}>
								<thead>
								<tr>
									<th></th>
									<th style={{textAlign: "right"}}>Barkauf</th>
									<th style={{textAlign: "right"}}>gslease-Leasing</th>
								</tr>
								</thead>
								<tbody>

								<tr>
									<td>Bruttogehalt</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.salary}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.salary}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								<tr>
									<td>Leasingrate</td>
									<td style={{textAlign: "right"}}></td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								<tr>
									<td>Versicherungsrate (Rundum-Schutz)</td>
									<td style={{textAlign: "right"}}></td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.insurance_fee}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>

								<tr>
									<td>Ihr geldwerter Vorteil</td>
									<td style={{textAlign: "right"}}></td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.leasing_ma_rabatt}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								</tbody>
								<thead>
								<tr>
									<td>Zwischensumme</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.salary}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={parseFloat(this.state.salary-this.state.rate-this.state.insurance_fee)+parseInt(this.state.leasing_ma_rabatt)}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>Lohnsteuer</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.lohnsteuer}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.lohnsteuer_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								<tr>
									<td>Kirchensteuer</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.kirche}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.kirche_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>

								<tr>
									<td>Rentenversicherung</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.renten}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.renten_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>

								<tr>
									<td>Arbeitslosenversicherung</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.arbeitslosenversicherung}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.arbeitslosenversicherung_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>

								<tr>
									<td>Krankenversicherung</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.krankenversicherung}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.krankenversicherung_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>

								<tr>
									<td>Pflegeversicherung</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.pflegeversicherung}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.pflegeversicherung_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>


								<tr>
									<td>Versteuerter geldwerter Vorteil</td>
									<td style={{textAlign: "right"}}></td>
									<td style={{textAlign: "right"}}>
										-<Currency
											quantity={this.state.leasing_ma_rabatt}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								</tbody>
								<thead>
								<tr>
									<td>Netto Auszahlung</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.new_slaray}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.new_slaray_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								</thead>
								<tbody>

								<tr>
									<td>Anschaffungspreis</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.buying_price}
											currency="EUR"
											decimal=","
										/>
									</td>

									<td style={{textAlign: "right"}}>


									</td>
								</tr>
								<tr>
									<td>Nettobelastung pro Monat</td>
									<td style={{textAlign: "right"}}>

									</td>

									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.new_slaray - this.state.new_slaray_rate}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								<tr>
									<td>Nettobelastung über 36 Monate</td>
									<td style={{textAlign: "right"}}>

									</td>

									<td style={{textAlign: "right"}}>
										<Currency
											quantity={(this.state.new_slaray - this.state.new_slaray_rate) * 36}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>

								<tr>
									<td>Kosten für Versicherung</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={this.state.insurance_fee * 36}
											currency="EUR"
											decimal=","
										/></td>
									<td style={{textAlign: "right"}}></td>
								</tr>

								<tr>
									<td>kalkulierter Restwert bei Übernahme</td>
									<td style={{textAlign: "right"}}></td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={(this.state.buying_price * 1.10) - this.state.buying_price}
											currency="EUR"
											decimal=","
										/>

									</td>
								</tr>

								</tbody>
								<tbody>
								<tr>
									<td>Zwischensumme Kosten</td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={parseFloat(this.state.buying_price) + (this.state.insurance_fee * 36)}
											currency="EUR"
											decimal=","
										/>
									</td>
									<td style={{textAlign: "right"}}>

										<Currency
											quantity={this.state.full_rabatt}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								</tbody>
								<thead>
								<tr>
									<td>Ihre indikative Ersparnisrechnung</td>
									<td style={{textAlign: "right"}}></td>
									<td style={{textAlign: "right"}}>
										<Currency
											quantity={(parseFloat(this.state.buying_price) + (this.state.insurance_fee * 36)) - this.state.full_rabatt}
											currency="EUR"
											decimal=","
										/>
									</td>
								</tr>
								</thead>
							</table>
						</div>
				}


			</div>
		);
	}
}

export default Expertenrechner;
