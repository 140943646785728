import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import './Stylesheets/NeoForm.css';

import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import axios from "axios";
import {BASE_URL} from "./Constants";
import ErrorBox from "./Components/ErrorBox";
import NeoButton from "./Button/NeoButton";

class WithdrawOffer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: "",

            errors: [],
            errorMessage: null,
            isSending: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.withdrawOffer = this.withdrawOffer.bind(this);
    }

    isVendorPage() {
        return this.props.history.location.pathname.includes("/vendor");
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: []
        });
    }

    withdrawOffer() {
        const { id } = this.props.match.params

        this.setState({ isSending: true })

        axios.post(BASE_URL + '/vendor/offer-withdraw', {
            reason: this.state.message,
            offer_id: id
        }).then(() => {
            this.props.history.push("/vendor/offers");
        }).catch(error => {
            if (error.response) {
                this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
            }
        });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    Form() {
        return (
            <div>
                <div className="neo-iconCircle" style={{ backgroundImage: "url('/withdraw.png')"}}/>

                <p className="neo-text">Geben Sie hier eine Begründung für die Rücknahme des Angebots an. Diese wird dem Interessenten übermittelt.</p>

                <textarea placeholder="Begründung" name="message" value={this.state.message} onChange={this.handleChange} />

                {
                    this.ErrorsView()
                }

                <NeoButton title="Senden" onClick={ this.withdrawOffer } showLoadingState={ this.state.isSending } disabled={this.state.message.length === 0} />
            </div>
        );
    }

    render() {
        return (
            <div className="neo-app">
                <NavigationBar/>

                <div className="neo-content">
                    <p className="neo-bar-title">Angebot zurückziehen</p>

                    {
                        this.Form()
                    }
                </div>

                <Footer/>
            </div>
        );
    }
}

export default WithdrawOffer;
