import React from "react";
import { Route, Switch } from "react-router-dom";
import ShopsMapView from "./ShopsMapView";
import ShopDetails from "./ShopDetails";

class ShopsList extends React.Component {
	render() {
		return (
			<Switch>
				<Route path="/shops/:id" component={ShopDetails} />
				<Route path="/shops" component={ShopsMapView} />
			</Switch>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}
}

export default ShopsList;
