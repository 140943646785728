import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "./Constants";
import { useState, useEffect } from "react";
import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Components/Footer";
import LoadingSpinner from "./LoadingSpinner";
import ErrorView from "./ErrorView";

function ScanResultView_QR(props) {
	var QRCode = require("qrcode.react");

	const [dashboard, setDashboard] = useState(null);
	const [shop, setShop] = useState(null);

	const [isLoadingShop, setIsLoadingShop] = useState(true);
	const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
	const [shopNotFound, setShopNotFound] = useState(false);

	useEffect(() => {
		loadDashboard();
		loadShop();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadDashboard() {
		axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
			"AccessToken"
		)}`;

		axios
			.get(BASE_URL + "/user/dashboard")
			.then((result) => {
				const dashboard = result.data;

				setDashboard(dashboard);
				setIsLoadingDashboard(false);
			})
			.catch((error) => {
				console.log(error);
				setIsLoadingDashboard(false);
			});
	}

	function loadShop() {
		const { id } = props.match.params;

		axios
			.get(BASE_URL + "/user/vendor/" + id)
			.then((result) => {
				const shop = result.data.data;

				setShop(shop);
				setIsLoadingShop(false);
			})
			.catch(() => {
				setShopNotFound(true);
				setIsLoadingShop(false);
			});
	}

	function LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	function MenuView() {
		return (
			<div>
				<div className="neo-card">
					<p className="neo-largeTitle">{shop.company_name}</p>
					<p className="neo-callout">{shop.address.street}</p>
					<p className="neo-callout">
						{shop.address.zip} {shop.address.city}
					</p>

					<p className="neo-text" style={{ maxWidth: "400px" }}>
						Willkommen bei der German SmartLease, Ihrem digitalen Leasingpartner
					</p>
				</div>

				<div className="neo-card">
					<div className="neo-qr-container2">
						<div className="neo-qr-container">
							<QRCode
								className="neo-qr"
								value={dashboard.uuid ?? ""}
								renderAs="svg"
							/>
						</div>
					</div>

					<p
						className="neo-text"
						style={{ marginBottom: 0, maxWidth: "400px" }}
					>
						Lassen Sie diesen Code von einem Verkäufer scannen, um ein
						Leasingangebot zu erhalten.
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="neo-app">
			<NavigationBar showBackButton={true} />

			<div className="neo-content">
				{isLoadingDashboard || isLoadingShop ? (
					LoadingView()
				) : shopNotFound ? (
					<ErrorView message="Dieser Händler konnte nicht gefunden werden." />
				) : (
					MenuView()
				)}
			</div>

			<Footer />
		</div>
	);
}

export default ScanResultView_QR;
