import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import ShopCell from "./Shop/ShopCell";
import axios from "axios";
import {
	BASE_URL,
	titleForStatus,
	colorCodeForStatus,
	iconForStatus,
	nameForInsurance,
} from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import DividerText from "./Components/DividerText";
import ConfirmationView from "./ConfirmationView";
import NeoButton from "./Button/NeoButton";
import ErrorView from "./ErrorView";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

class OfferDetails extends React.Component {
	state = {
		isLoading: true,
		isLoadingProfile: true,
		offer: [],
		isProfileComplete: null,
		id_card_front: "",
		id_card_back: "",
		displayProfileCompletionView: false,
		isSending: false,
		isSending2: false,
		offerNotFound: false,
	};

	constructor(props) {
		super(props);

		this.acceptOffer = this.acceptOffer.bind(this);
		this.declineOffer = this.declineOffer.bind(this);
		this.deleteOffer = this.deleteOffer.bind(this);
		this.loadIDCards = this.loadIDCards.bind(this);
		this.downloadContract = this.downloadContract.bind(this);
		this.chooseFile = this.chooseFile.bind(this);
		this.onChange = this.onChange.bind(this);
		this.showUploadData = this.showUploadData.bind(this);
	}

	isDirectOffers() {
		return this.props.history.location.pathname.includes("/direct");
	}

	isCompanyGroupOffer() {
		return this.props.history.location.pathname.includes("/company_group");
	}

	componentDidMount() {
		if (this.isDirectOffers()) {
			this.loadDirectOfferDetails();
		} else {
			this.loadOfferDetails();
			this.loadProfile();
		}
	}

	loadProfile() {
		axios
			.get(BASE_URL + "/user/profile")
			.then((result) => {
				const profile = result.data.data;

				this.setState({
					isProfileComplete: profile.profile !== null,
					isLoadingProfile: false,
				});
			})
			.catch(() => {});
	}

	loadOfferDetails() {
		const { id } = this.props.match.params;

		axios
			.get(
				BASE_URL +
					(this.isCompanyGroupOffer()
						? "/user/company/offer/"
						: "/user/offer/") +
					id
			)
			.then((result) => {
				const offer = result.data.data;
				console.log(offer);
				this.setState({
					offer,
					isLoading: false,
				});

				if (
					offer.status === "id_card_check" ||
					offer.status === "credit_check"
				) {
					this.loadIDCards();
				}
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.status === 404) {
						this.setState({ offerNotFound: true, isLoading: false });
					}
				}
			});
	}

	loadDirectOfferDetails() {
		const { id } = this.props.match.params;

		axios
			.get(BASE_URL + "/user/offer-request/" + id)
			.then((result) => {
				const offer = result.data.data;

				this.setState({
					offer,
					isLoading: false,
					isLoadingProfile: false,
				});
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.status === 404) {
						this.setState({
							offerNotFound: true,
							isLoading: false,
							isLoadingProfile: false,
						});
					}
				}
			});
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Anfragedetails" />

				<div className="neo-content">
					{this.state.isLoading || this.state.isLoadingProfile ? (
						this.LoadingView()
					) : this.state.displayProfileCompletionView ? (
						<ConfirmationView
							text="Um dieses Angebot anzunehmen, muss Ihr Profil vollständig ausgefüllt sein."
							icon="credit_check.png"
							route="/profile"
							buttonTitle="Profil öffnen"
						/>
					) : this.state.offerNotFound ? (
						<ErrorView message="Dieses Angebot konnte nicht gefunden werden." />
					) : (
						this.OfferDetails()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	OfferDetails() {
		return (
			<div>
				<p className="neo-largeTitle">{this.state.offer.object_name}</p>
				<p className="neo-callout">
					{this.state.offer.object_manufacturer}

					{this.state.offer.object_number
						? " - Leasingobjekt-ID: " + this.state.offer.object_number
						: null}
				</p>

				{this.state.offer.equipment ? (
					<p className="neo-callout">
						Inklusive Zubehör:
						<br />
						{this.state.offer.equipment}
					</p>
				) : null}

				{this.isDirectOffers() === false ? (
					<div>
						<div className="neo-card-cell" style={{ marginTop: "20px" }}>
							<label for="none" className="neo-text">
								<span>Leasingrate</span>
								<span style={{ color: "gray" }}>
									{this.state.offer.leasing_rate} € mtl.*
								</span>
							</label>
						</div>

						<div className="neo-card-cell">
							<label for="none" className="neo-text">
								<span>Leasingdauer</span>
								<span style={{ color: "gray" }}>
									{this.state.offer.leasing_duration} Monate
								</span>
							</label>
						</div>

						<div className="neo-card-cell">
							<label for="none" className="neo-text">
								<span>
									Versicherung: {nameForInsurance(this.state.offer.insurance)}
								</span>
								{this.state.offer.insurance !== "none" && (
									<span style={{ color: "gray" }}>
										{this.state.offer.insurance_fee} € mtl.
									</span>
								)}
							</label>
						</div>

						<div className="neo-card-cell">
							<label htmlFor="none" className="neo-text">
								<span>Lieferung</span>
								<span style={{color: "gray"}}>
									{this.state.offer.delivery == "pickup" ? "Abholung" : "Versand"}
								</span>
							</label>
						</div>

						<p
							className="neo-callout"
							style={{ marginTop: "15px", fontSize: "10px" }}
						>
							zzgl. Andienungspreis: {this.state.offer.residual}%
						</p>

						<div
							className="neo-statusIndicator"
							style={{ color: colorCodeForStatus(this.state.offer.status) }}
						>
							<div
								className="neo-statusIcon"
								style={{
									backgroundImage:
										"url('/" + iconForStatus(this.state.offer.status) + "')",
								}}
							/>
							Status: {titleForStatus(this.state.offer.status)}
						</div>
					</div>
				) : (
					<div>
						<p className="neo-smallTitle">
							Leasingdauer: {this.state.offer.leasing_duration ?? 0} Monate
						</p>
						<div className="neo-card-cell">
							<label htmlFor="none" className="neo-text">
								<span>Lieferung</span>
								<span style={{color: "gray"}}>
									{this.state.offer.delivery == "pickup" ? "Abholung" : "Versand"}
								</span>
							</label>
						</div>
						<p className="neo-smallTitle">
							Versicherung: {nameForInsurance(this.state.offer.insurance)}
						</p>
						<div className="neo-spacer20" />
						<p className="neo-callout">
							Ihre Anfrage wird derzeit geprüft. Sie werden benachrichtigt,
							sobald sich der Status ändert. Sie finden diese Anfrage dann unter
							"Meine Angebote".
						</p>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />
					</div>
				)}

				{this.isDirectOffers() === false && this.StatusArea()}

				{
					this.state.offer.branch == null ? <ShopCell
						disableClick={false}
						title={this.state.offer.vendor.company_name}
						subtitle1={this.state.offer.vendor.address.street}
						subtitle2={
							this.state.offer.vendor.address.zip +
							" " +
							this.state.offer.vendor.address.city
						}
						uuid={this.state.offer.vendor.id}
					/> : <ShopCell
						disableClick={false}
						title={this.state.offer.branch.name}
						subtitle1={this.state.offer.branch.address.street}
						subtitle2={
							this.state.offer.branch.address.zip +
							" " +
							this.state.offer.branch.address.city
						}
						uuid={this.state.offer.branch.id}
					/>
				}


				{this.IDCards()}

				{this.Contract()}

				{this.isDirectOffers() === false ? (
					this.ButtonArea()
				) : (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />
					</div>
				)}
			</div>
		);
	}

	StatusArea() {
		if (this.state.offer.company !== null) {
			if (this.state.offer.company.is_company_admin === false) {
				return (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />
					</div>
				);
			}
		}

		if (this.state.offer.status === "withdrawn_by_vendor") {
			return (
				<div>
					<p className="neo-callout">
						Der Händler hat das Angebot mit folgender Begründung zurückgezogen:
					</p>
					<p className="neo-smallTitle">
						{'"' + this.state.offer.withdraw_reason + '"'}
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		} else if (this.state.offer.status === "credit_denied") {
			return (
				<div>
					<p className="neo-callout">
						Die Bonitätsprüfung ist fehlgeschlagen. Die Anfrage wurde
						abgebrochen. Kontaktieren Sie bei Fragen den Händler.
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		} else if (this.state.offer.status === "id_card_check") {
			return (
				<div>
					<p className="neo-callout">
						Ihr Personalausweis wird auf Lesbarkeit geprüft. Sie erhalten eine
						Benachrichtigung, sobald die Bonitätsprüfung startet.
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		} else if (this.state.offer.status === "id_card_not_readable") {
			return (
				<div>
					<p className="neo-callout">
						Ihr Personalausweis ist nicht ausreichend lesbar. Bitte laden Sie
						erneut die Vorder- und Rückseite hoch.
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		} else if (this.state.offer.status === "credit_check") {
			return (
				<div>
					<p className="neo-callout">
						Ihre Bonität wird geprüft. Sie erhalten eine Benachrichtigung,
						sobald die Bonitätsprüfung abgeschlossen ist.
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		} else if (this.state.offer.status === "completed") {
			return (
				<div>
					<p className="neo-callout">
						Die Anfrage ist abgeschlossen. Drucken Sie den untenstehenden
						Leasingvertrag aus und senden Sie ihn unterschrieben an uns zurück.
						Optinal können Sie für einen beschleunigten Prozess den Vertrag
						zusätzlich unterschrieben wieder hochladen.
					</p>
					<div className="neo-spacer20" />
					<div className="neo-spacer20" />
				</div>
			);
		}
	}

	RemainingTime() {
		var remainingTime = Math.floor(this.state.offer.expires_at / 60 / 60);
		remainingTime += remainingTime === 1 ? " Stunde" : " Stunden";

		return <DividerText text={"Noch " + remainingTime + " gültig"} />;
	}

	onChange(event) {
		let file = event.target.files[0];

		const { id } = this.props.match.params;

		const url = BASE_URL + "/user/offer-upload-contract";
		const formData = new FormData();
		formData.append("contract", file);
		formData.append("offer_id", id);

		const config = {
			headers: {
				Accept: "application/json",
				"content-type": "multipart/form-data",
			},
		};

		this.setState({ isLoading: true });

		return axios
			.post(url, formData, config)
			.then(() => {
				this.loadOfferDetails();
			})
			.catch((error) => {
				this.setState({ isLoading: false });

				if (error.response) {
					this.setState({
						errors: error.response.data.errors ?? null,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	chooseFile() {
		this.state.inputElement.click();
	}

	showUploadData() {
		const { id } = this.props.match.params;

		this.props.history.push("/offers/" + id + "/data");
	}

	Contract() {
		if (this.state.offer.company !== null) {
			if (this.state.offer.company.is_company_admin === false) {
				return (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />
					</div>
				);
			}
		}

		if (this.state.offer.status === "completed") {
			return (
				<div style={{ width: "100%", float: "left", textAlign: "center" }}>
					<DividerText text="Mein Leasingvertrag" />

					<br />
					<br />
					<br />
					<br />

					{
						this.state.offer.contract == null ? null :
							<>
								<Document
									className="neo-contractPreview"
									file={this.state.offer.contract.url}
								>
									<Page width={180} pageNumber={1} />
								</Document>

								{this.state.offer.contract.type === "signed" ? (
									<p className="neo-text" style={{ color: "#60be0c" }}>
										Unterschrieben hochgeladen
									</p>
								) : null}

								<button className="neo-button" onClick={this.downloadContract}>
									Vertrag öffnen
								</button>

								{this.state.offer.contract.type !== "signed" ? (
									<button className="neo-button" onClick={this.chooseFile}>
										Unterschriebenen Vertrag hochladen
									</button>
								) : null}
							</>
					}

					<form>
						<input
							type="file"
							className="neo-hiddenUpload"
							accept=".pdf"
							onChange={this.onChange}
							ref={(input) => (this.state.inputElement = input)}
						/>
					</form>

					{
						(this.state.offer.status === "completed" && this.state.offer.delivery == "deliver") ?
							this.state.offer.handing_hover.handover_date == null ?
									<NeoButton
										title="Leasingübergabe bestätigen"
										style={{marginTop: 20}}
										onClick={this.showUploadData}
									/>
								: null
							: null
					}
				</div>
			);
		}
	}

	downloadContract() {
		axios
			.get(this.state.offer.contract.url, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "Vertrag.pdf");
				document.body.appendChild(link);
				link.click();
			})
			.catch(function (error) {});
	}

	ButtonArea() {
		if (this.state.offer.company !== null) {
			if (this.state.offer.company.is_company_admin === false) {
				return (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />
					</div>
				);
			}
		}

		if (this.state.offer.status === "open") {
			return (
				<div>
					{this.RemainingTime()}
					<NeoButton
						title="Angebot annehmen"
						onClick={this.acceptOffer}
						showLoadingState={this.state.isSending}
					/>
					<NeoButton
						title="Angebot ablehnen"
						onClick={this.declineOffer}
						showLoadingState={this.state.isSending2}
					/>
				</div>
			);
		} else if (this.state.offer.status === "id_card_not_readable") {
			return (
				<div>
					<DividerText text="Mein Personalausweis" />
					<NeoButton
						title="Ausweis hochladen"
						onClick={this.acceptOffer}
						showLoadingState={this.state.isSending}
					/>
				</div>
			);
		} else {
			if (
				this.state.offer.status !== "id_card_check" &&
				this.state.offer.status !== "credit_check" &&
				this.state.offer.status !== "id_card_not_readable" &&
				this.state.offer.status !== "completed"
			) {
				return (
					<div>
						<NeoButton
							title="Anfrage löschen"
							isDestructive={true}
							onClick={this.deleteOffer}
							showLoadingState={this.state.isSending}
						/>
					</div>
				);
			}
		}
	}

	IDCards() {
		if (this.state.offer.company !== null) {
			if (this.state.offer.company.is_company_admin === false) {
				return (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />
					</div>
				);
			}
		}

		if (
			this.state.offer.status === "id_card_check" ||
			this.state.offer.status === "credit_check"
		) {
			return (
				<div>
					<DividerText text="Mein Personalausweis" />

					<div style={{ position: "relative", zIndex: 1 }}>
						<img
							className="neo-idCardPreview"
							src={`data:image/jpeg;base64,${this.state.id_card_front}`}
							alt=""
						/>
					</div>

					<div style={{ position: "relative", zIndex: 1 }}>
						<img
							className="neo-idCardPreview"
							src={`data:image/jpeg;base64,${this.state.id_card_back}`}
							alt=""
						/>
					</div>
				</div>
			);
		}
	}

	loadIDCards() {
		axios
			.get(this.state.offer.id_cards[0].url, {
				responseType: "arraybuffer",
			})
			.then((response) => {
				let buffer = Buffer.from(response.data, "binary").toString("base64");

				this.setState({
					id_card_front: buffer,
				});
			})
			.catch(function (error) {});

		axios
			.get(this.state.offer.id_cards[1].url, {
				responseType: "arraybuffer",
			})
			.then((response) => {
				let buffer = Buffer.from(response.data, "binary").toString("base64");

				this.setState({
					id_card_back: buffer,
				});
			})
			.catch(function (error) {});
	}

	deleteOffer() {
		this.setState({ isSending: true });

		axios
			.post(BASE_URL + "/user/offer-delete", {
				offer_id: this.state.offer.id,
			})
			.then(() => {
				this.props.history.push("/offers");
			})
			.catch(() => {
				this.setState({ isSending: false });
			});
	}

	acceptOffer() {
		const { id } = this.props.match.params;

		if (this.state.isProfileComplete) {
			this.props.history.push("/offers/" + id + "/accept");
		} else {
			this.setState({ displayProfileCompletionView: true });
		}
	}

	declineOffer() {
		this.setState({ isSending2: true });

		axios
			.post(BASE_URL + "/user/offer-decline", {
				offer_id: this.state.offer.id,
			})
			.then(() => {
				this.props.history.push("/offers");
			})
			.catch(() => {
				this.setState({ isSending2: false });
			});
	}
}

export default OfferDetails;
