import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import NeoButton from "./Button/NeoButton";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

function ConfirmationView(props) {
    let history = useHistory();

    function showMainMenu() {
        if(history.location.pathname.includes("/rahmenvertrag")){
            history.push("/profile/id-card");
            return true;
        }
        history.push(props.route ? props.route : (isVendorPage() ? "/vendor" : "/"));
    }

    function isVendorPage() {
        return history.location.pathname.includes("/vendor");
    }

    return (
        <div className="neo-messageContainer">
            <div className="neo-iconCircle" style={{ backgroundImage: "url('/" + props.icon + "')"}}/>

            <p className="neo-text">{ props.text }</p>

            <NeoButton title={ props.buttonTitle ? props.buttonTitle : "Fertig" } onClick={ showMainMenu } />
        </div>
    );
}

ConfirmationView.propTypes = {
    text: PropTypes.string.isRequired,
    route: PropTypes.string,
    buttonTitle: PropTypes.string
};

export default ConfirmationView;
