import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import NeoButton from "./Button/NeoButton";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

function ErrorView(props) {
    let history = useHistory();

    function showMainMenu() {
        history.push(props.route ? props.route : (isVendorPage() ? "/vendor" : "/"));
    }

    function isVendorPage() {
        return history.location.pathname.includes("/vendor");
    }

    return (
        <div className="neo-messageContainer">

                <p className="neo-mediumTitle" style={{marginBottom: "80px" }}>
                    {
                        props.message ? props.message : "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                    }
                </p>

            <NeoButton title="Zurück zur Startseite" onClick={ showMainMenu } />
        </div>
    );
}

ErrorView.propTypes = {
    message: PropTypes.string,
};

export default ErrorView;
