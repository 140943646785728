import React from "react";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import ShopCell from "./Shop/ShopCell";
import NeoDropdown from "./Dropdown/NeoDropdown";
import ErrorBox from "./Components/ErrorBox";
import axios from "axios";
import { BASE_URL, CLEAR_BASE_URL } from "./Constants";
import Currency from "react-currency-formatter";
import LoadingSpinner from "./LoadingSpinner";
import ErrorView from "./ErrorView";
import NeoButton from "./Button/NeoButton";
import ConfirmationView from "./ConfirmationView";
import NeoCheckbox from "./Checkbox/NeoCheckbox";
import { nameForInsurance } from "./Constants";
import Cookies from "js-cookie";
import NeoEditField from "./EditField/NeoEditField";

class CreateOffer extends React.Component {
	inter = null;
	state = {
		company_name: "",
		street: "",
		zip: "",
		city: "",
		hasCompanyGroup: false,

		rate: 0,
		insurance_fee: 0,
		insurance_available: null,
		objectName: "",
		objectNumber: "",
		objectManufacturer: "",
		objectPrice: "",
		leasingDuration: 36,
		accessories: "",
		accessoriesPrice: "",
		insurance: "none",

		profile: {},

		errorsMaxSonde: [],
		errorMessageMaxSonde: null,

		errors: [],
		errorMessage: null,
		isSent: false,
		isLoading: true,
		noUserFound: false,
		isSending: false,
		privacy: 0,

		/**
		 * Objekt
		 */
		leadingObject: "",
		leasingCondition: "new",
		leasingCalculationType: "rate",
		special_rent_payment: "",
		provision: 0,

		alternative_delievery_address: false,
		saved: false,

		calcLoading: false,
		showRate: false,
		customerLoading: false,
		showCustomer: false,
		restwert: "",
		bj: "",
		ok: "",


		rates: [],
		selected_insurance: "",
		id: typeof this.props.match.params.id != "undefined" ? this.props.match.params.id : null,

		all_objects: [],
		all_conditions: [],
		all_insurens: [],
		object_art: [],
		note: "",
		reverse_calc: 0
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleNoteChange = this.handleNoteChange.bind(this);
		this.searchUser = this.searchUser.bind(this);
		this.sendOffer = this.sendOffer.bind(this);
        this.createDraftOffer = this.createDraftOffer.bind(this);
		this.loadUserInfo = this.loadUserInfo.bind(this);
		this.calculateLeasingRate = this.calculateLeasingRate.bind(this);
		this.recalculateLeasingRate = this.recalculateLeasingRate.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onRadioDatenschutzChange = this.onRadioDatenschutzChange.bind(this);
		this.handleDeliveryAddress = this.handleDeliveryAddress.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.handleChangeFilterRestwert = this.handleChangeFilterRestwert.bind(this);
		this.handleChangeFilterObject = this.handleChangeFilterObject.bind(this);
		this.handleChangeFilterProvision = this.handleChangeFilterProvision.bind(this);
		this.handleChangeFilterArt = this.handleChangeFilterArt.bind(this);
		this.handleChangeFilterInsurance = this.handleChangeFilterInsurance.bind(this);
		this.handleChangeData = this.handleChangeData.bind(this);

	}

	componentWillUnmount() {
		if(this.inter != null){clearInterval(this.inter);}
	}

	componentDidMount() {
		if(this.state.id == null) {
			this.createDraftOffer();
		}else {
			this.loadDraftOffer();
		}

		this.inter = setInterval(() => {
			this.setState({saved: true})

			this.updateDraftOffer();
		}, "10000")
	}

	ErrorsViewMaxSonder() {
		const { errorsMaxSonde } = this.state;
		const { errorMessageMaxSonde } = this.state;

		return errorsMaxSonde !== null ? (
			<ErrorBox errorMessage={errorMessageMaxSonde} errors={errorsMaxSonde} />
		) : null;
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	loadUserInfo() {
		/*
		const { id } = this.props.match.params;

		axios
			.get(BASE_URL + "/vendor/user/" + id + "/base-info")
			.then((result) => {
				const profile = result.data.data;

				if (profile.address === null) {
					this.setState({
						company_name: profile.company_name ?? profile.email,
						isLoading: false,
					});
				} else {
					this.setState({
						company_name: profile.company_name ?? profile.email,
						street: profile.address.street ?? "",
						zip: profile.address.zip ?? "",
						city: profile.address.city ?? "",
						isLoading: false,
					});
				}

				if (profile.company !== undefined)
					this.setState({
						hasCompanyGroup: true,
					});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ noUserFound: true, isLoading: false });
				}
			});*/
	}

	sendOffer() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		let branchID = Cookies.get("CurrentStoreID");

		axios
			.post(
				BASE_URL +
					(this.state.useCompanyGroup
						? "/vendor/offer-create/company"
						: "/vendor/offer-create"),
				{
					object_name: this.state.objectName,
					object_price: this.objectPrice(),
					object_number: this.state.objectNumber,
					object_manufacturer: this.state.objectManufacturer,
					equipment: this.state.accessories,
					equipment_price: this.accessoriesPrice(),
					leasing_duration: this.state.leasingDuration,
					insurance_fee: this.state.insurance_fee,
					object_art: this.state.art,
					restwert: this.state.restwert,
					total_price: this.totalPrice(),
					user: this.state.profile,
					rate: this.state.rate,
					user_uuid: id,
					insurance: this.state.selected_insurance,
					branch_id: branchID,
					id: this.state.id,
					note: this.state.note
				}
			)
			.then(() => {
				this.setState({ isSending: false, isSent: true });
				if(this.inter != null){clearInterval(this.inter);}
				let klo = this.props;
				let inernid = this.state.id;
				setTimeout(function (){klo.history.push("/vendor/offers/" + inernid + "/upload")}.bind(),1400);
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	onRadioChange(event) {

		this.setState({
			insurance: event.target.value,
			rate: 0,
			insurance_fee: 0,
			insurance_available: null,
		});
	}

	onRadioDatenschutzChange(event) {

		this.setState({
			privacy: event.target.value
		});
	}

	updateDraftOffer() {
		axios
			.post(BASE_URL + "/vendor/offers/draft/update", {
				id: this.state.id,
				leadingData: this.state.leadingObject,
				objectName: this.state.objectName,
				objectPrice: this.totalPrice(),
				leadingObject: this.state.object_type,
				leasingCondition: this.state.leasingCondition,
				leasingCalculationType: this.state.leasingCalculationType,
				special_rent_payment: this.state.special_rent_payment,
				provision: this.state.provision,
				rate: this.state.rate,
				restwert: this.state.restwert,
				bj: this.state.bj,
				user: (this.state.profile),
				leasing_duration: this.state.leasingDuration,
				selected_insurance: this.state.selected_insurance,
				isLoading: false
			})
			.then((result) => {
				setTimeout(() => {
					this.setState({saved: false})
				}, "1000")

			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
						isLoading: false
					});
				}
				setTimeout(() => {
					this.setState({saved: false})
				}, "1000")
			});
	}

	loadDraftOffer() {
		let branchID = Cookies.get("CurrentStoreID");
		axios
			.get(BASE_URL + "/vendor/offer/"+this.state.id)
			.then((result) => {
				const data = result.data.offer;

				const all_objects = [];
				result.data.objects.map(item => all_objects.push({value: item.id, name: item.name}));

				const object_art = [];
				const object_art_string = ["VA", "VT", "Mietkauf"];
				result.data.object_art.map(item => object_art.push({value: item.object_id, index: object_art_string[item.object_id-1].toLowerCase(), name: object_art_string[item.object_id-1]}));

console.log("asdasda111", data)
				this.setState({
					all_objects: all_objects,
					object_art: object_art,
					objectName: data.object_name,
					leadingObject: data.object_type,
					leasingCondition: data.object_state,
					bj: data.bj,
					leasingCalculationType: "rate",
					objectPrice: data.object_price == null ? data.leasing_rate == null ? null : data.leasing_rate : data.object_price,
					special_rent_payment: "",
					provision: data.provision,
					insurance: data.insurance,
					profile: data.profile,
					reverse_calc: data.reverse_calc,
					isLoading: false
				});

			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
						isLoading: false
					});
				}
			});
	}

    createDraftOffer() {
        let branchID = Cookies.get("CurrentStoreID");
		let o = this;
        axios
            .post(BASE_URL + "/vendor/offers/draft", {
                branch_id: branchID,
            })
            .then((result) => {
                const data = result.data;

				const all_objects = [];
				result.data.objects.map(item => all_objects.push({value: item.id, name: item.name}));

				const object_art = [];
				const object_art_string = ["VA", "VT", "Mietkauf"];
				result.data.object_art.map(item => object_art.push({value: item.object_id, index: object_art_string[item.object_id-1].toLowerCase(), name: object_art_string[item.object_id-1]}));

				this.setState({reverse_calc: result.data.offer.reverse_calc, isLoading: false, id: data.offer.id, all_objects: all_objects, object_art: object_art});
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
						isLoading: false,
						errors: error.response.data.errors,
                        errorMessage: error.response.data.message,
                    });
                }
            });
    }

	calculateLeasingRate(duration) {
		axios
			.post(BASE_URL + "/vendor/calc-rate-new", {
				total_price: this.totalPrice(),
				insurance: this.state.insurance,
				obj: this.state.leadingObject,
				provision: this.state.provision,
				special_rent_payment: this.state.special_rent_payment,
				art: this.state.art,
				restwert: this.state.restwert,
				type: this.state.leasingCalculationType
			})
			.then((result) => {
				let ratesData = [];
				result.data.rates.map(item => ratesData.push({
					name: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format((Math.round(item.rate * 100) / 100)),
					value: item.rate,
					sub: item.laufzeit + " Monate",
					duration: item.laufzeit,
					insurance_fee: 0,
					insurance_available: false,
				}));

				let insurance = [];

				result.data.insurance.map(item => insurance.push({
					name: item.name,
					value: item.id,
					sub: (item.price).toLocaleString()+" €",
				}));


				this.setState({rates: ratesData, all_insurens: insurance});

				/*
				this.setState({
					rate: data.rate,
					insurance_fee: data.insurance,
					insurance_available: data.insurance_available,
				});*/
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});

		console.log({
			leasing_duration: duration,
			total_price: this.totalPrice(),
			insurance: this.state.insurance,
		});
	}

	recalculateLeasingRate() {
		//this.calculateLeasingRate(this.state.leasingDuration);


			this.setState({rates: [],calcLoading: true})

		this.calculateLeasingRate(12);


		setTimeout(() => {
			this.setState({calcLoading: false, showRate: true})
		}, "2000")

	}

	handleDeliveryAddress() {
		if(this.state.alternative_delievery_address) {
			this.setState({delievery_city: "", delievery_zip: "", delievery_street: "", delievery_name: "", alternative_delievery_address: !this.state.alternative_delievery_address})
		}else {
			this.setState({alternative_delievery_address: !this.state.alternative_delievery_address})
		}

	}

	searchUser() {
		axios
			.get(BASE_URL + "/vendor/offers/find?email="+this.state.profile.contact_email)
			.then((result) => {
				const profile = result.data;

				if(typeof result.data.birthday != "undefined") {
					profile.gf_birthdate = profile.birthday;
					this.setState({profile: profile})
				}
			});
	}

	handleNoteChange(event) {
		this.setState({
			note: event.target.value
		});
	}

	handleChangeFilterObject(event) {
		this.setState({
			...this.state,
			leadingObject: event.value,
			showRate: false, showCustomer: false,
			errors: [],
		});
	}

	handleChangeFilterRestwert(event) {
		this.setState({
			...this.state,
			restwert: event.value,
			errors: [],
		});
	}

	handleChangeFilterInsurance(event) {
		this.setState({
			...this.state,
			selected_insurance: event.value,
			insurance_fee: event.sub,
			errors: [],
		});
	}

	handleChangeFilterProvision(event) {
		this.setState({
			...this.state,
			provision: event.value,
			errors: [],
		});
	}

	handleChangeFilterArt(event) {
		this.setState({
			...this.state,
			art: event.index,
			restwert: "",
			showRate: false,
			showCustomer: false,
			errors: [],
		});
	}

	handleChangeFilter(event) {

		this.setState({
			...this.state,
			rate: event.value,
			leasingDuration: event.duration,
			errors: [],
		});
	}

	handleChange(event) {
		const value = event.target.value;
		const profile = this.state.profile;
		profile[event.target.name] = value;

		this.setState({
			...this.state,
			profile: profile,
			errors: [],
		});


		if (event.target.name === "objectPrice") {
			this.setState({ rate: 0, insurance_fee: 0, insurance_available: null });
		}
	}

	handleChangeData(event) {
		let value = event.target.value;
		let errorMessageMaxSonde = null;
		if(event.target.name == "special_rent_payment") {

			let calcMaxRange = (this.state.objectPrice / 100) * 30;
			if(calcMaxRange < value) {
				value = calcMaxRange.toFixed(2).replace(".", ",");
				errorMessageMaxSonde = "Max. 30% vom Kaufpreis möglich";
			}


		}
		this.setState({
			...this.state,
			[event.target.name]: value,
			showRate: false,
			showCustomer: false,
			errors: [],
			errorMessageMaxSonde: errorMessageMaxSonde
		});

		if (event.target.name === "objectPrice") {
			this.setState({ rate: 0, insurance_fee: 0, insurance_available: null });
		}
	}

	handleChangeDuration = (duration) => {
		this.setState({ leasingDuration: duration.value });

		this.calculateLeasingRate(duration.value);
	};



	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					<p className="neo-bar-title">Neue Leasinganfrage</p>
					{	this.state.isLoading ? (
						this.LoadingView()
					) : this.state.isSent ? (
						<ConfirmationView
							text="Sie haben das Angebot erfolgreich übermittelt. Sie werden gleich zum Datenupload geleitet."
							icon="confirmation.png"
						/>
					) : (
						this.OfferForm()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	addCustomer() {
		this.setState({customerLoading: true, showCustomer: true})
		setTimeout(() => {
			this.setState({customerLoading: false})
		}, "2000")
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	OfferForm() {
		return (
			<div>

				<div className="neo-card">
					<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Leasingobjekt</p>

					<NeoDropdown
						title="Bezeichnung"

						items={this.state.all_objects}
						value={this.state.leadingObject}
						onChangeValue={this.handleChangeFilterObject}
						trailingString=""
					/>
				</div>

				<div className="neo-card">
					<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Zustand</p>

					<div
						className="neo-card-cell"
						onClick={() => this.setState({showRate: false, showCustomer: false, leasingCondition: "new"})}
					>
						<input
							type="radio"
							id="leasing_condition_new"
							name="leasingCondition"
							value="new"
							checked={this.state.leasingCondition === "new"}
							onChange={this.onRadioChange}
						/>
						<label htmlFor="none" className="neo-text">
							<span>Neu</span>
						</label>
					</div>
					<div
						className="neo-card-cell"
						onClick={() => this.setState({showRate: false, showCustomer: false, leasingCondition: "second_hand"})}
					>
						<input
							type="radio"
							id="leasing_condition_second_hand"
							name="leasingCondition"
							value="second_hand"
							checked={this.state.leasingCondition === "second_hand"}
							onChange={this.onRadioChange}
						/>
						<label htmlFor="business_and_private" className="neo-text">
							<span>Gebraucht</span>
						</label>
					</div>

				</div>


				{
					this.state.leasingCondition === "second_hand"
					? <input
							type="text"
							placeholder={"Baujahr"}
							name="bj"
							value={this.state.bj}
							onChange={this.handleChangeData}
						/> : null
				}


				<div className="neo-card">
					<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Berechnungsart</p>
					<div
						className="neo-card-cell"
						onClick={() => this.setState({showRate: false, showCustomer: false, objectPrice: "", leasingCalculationType: "rate"})}
					>
						<input
							type="radio"
							id="rate"
							name="leasingCalculationType"
							value="rate"
							checked={this.state.leasingCalculationType === "rate"}
							onChange={this.onRadioChange}
						/>
						<label htmlFor="rate" className="neo-text">
							<span>Rate berechnen</span>
						</label>
					</div>
					{
						this.state.reverse_calc === 1 ? <div
							className="neo-card-cell"
							onClick={() => this.setState({showRate: false, showCustomer: false, objectPrice: "", leasingCalculationType: "reverse_calc"})}
						>
							<input
								type="radio"
								id="reverse_calc"
								name="leasingCalculationType"
								value="reverse_calc"
								checked={this.state.leasingCalculationType === "reverse_calc"}
								onChange={this.onRadioChange}
							/>
							<label htmlFor="reverse_calc" className="neo-text">
								<span>Rückwärtsrechner</span>
							</label>
						</div> : null
					}


				</div>

				<div className="neo-card">
					<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Vertragsart</p>

					<NeoDropdown
						title="Bezeichnung"

						items={this.state.object_art}
						value={
							this.state.art
						}
						onChangeValue={this.handleChangeFilterArt}
						trailingString=""
					/>
				</div>

				{
					this.state.art == "vt" ? <div className="neo-card" style={{    paddingBottom: 1}}>
						<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Restwert des Objektes</p>

						<NeoDropdown
							title="Restwert des Objektes"

							items={[
								{ name: "5", value: 5 },
								{ name: "10", value: 10 },
								{ name: "15", value: 15 },
								{ name: "20", value: 20 },
								{ name: "25", value: 25 },
								{ name: "30", value: 30 }
							]}
							value={
								this.state.restwert
							}
							allowdropdown={true}
							onChangeValue={this.handleChangeFilterRestwert}
							trailingString="%"
						/>
					</div>: null
				}


				<input
					type="text"
					placeholder={this.state.leasingCalculationType === "reverse_calc" ? "Wunschrate netto" : "Kaufpreis netto"}
					name="objectPrice"
					value={this.state.objectPrice}
					onChange={this.handleChangeData}
				/>

				<input
					type="text"
					placeholder="Sonderzahlung (optional)"
					name="special_rent_payment"
					value={this.state.special_rent_payment}
					onChange={this.handleChangeData}
				/>

				{
					this.state.errorMessageMaxSonde != null ? this.ErrorsViewMaxSonder() : null
				}


				<input
					type="text"
					placeholder="Bezeichnung"
					name="objectName"
					value={this.state.objectName}
					onChange={this.handleChangeData}
				/>

				<div className="neo-card" style={{    paddingBottom: 1}}>
					<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Händlerprovision</p>

					<NeoDropdown
						title="Bezeichnung"

						items={[
							{ name: "1", value: 1 },
							{ name: "2", value: 2 },
							{ name: "3", value: 3 },
							{ name: "4", value: 4 },
							{ name: "5", value: 5 },
							{ name: "keine", value: 0 },
						]}
						value={
							this.state.provision
						}
						allowdropdown={true}
						onChangeValue={this.handleChangeFilterProvision}
						trailingString="%"
					/>

				</div>



				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<NeoButton title="Berechnen" onClick={this.recalculateLeasingRate} />

				{this.ErrorsView()}

				{
					this.state.calcLoading ? (
						this.LoadingView()
					) : this.state.showRate ? <>
						<div className="neo-card">
							<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Rate & Laufzeit wählen</p>

							<NeoDropdown
								title="Bezeichnung"

								items={this.state.rates}
								value={
									(this.state.rate)
								}
								onChangeValue={this.handleChangeFilter}
								trailingString=""
							/>
						</div>

						{this.state.all_insurens.length == 0 ? null: <div className="neo-card">
							<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Versicherung</p>

							<NeoDropdown
								title="Bezeichnung"

								items={this.state.all_insurens}
								value={
									(this.state.selected_insurance)
								}
								onChangeValue={this.handleChangeFilterInsurance}
								trailingString=""
							/>
						</div>}


						<NeoButton
							title="Weiter"
							disabled={this.state.rate < 1}
							onClick={() => this.addCustomer()}
						/>

					</> : null
				}


				{
					this.state.customerLoading ? (
						this.LoadingView()
					) : this.state.showCustomer ? <>
						{/* Kundendaten */}
						<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Kundendaten</p>


						<input
							type="text"
							placeholder="E-Mail"
							name="contact_email"
							value={this.state.profile.contact_email ?? ""}
							onChange={this.handleChange}
							onBlur={() => this.searchUser()}
						/>

						<input
							type="text"
							placeholder="Firma"
							name="company_name"
							value={this.state.profile.company_name ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="HR-Nummer (optional)"
							name="cr_number"
							value={this.state.profile.cr_number ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="Ust-ID (optional)"
							name="sti_number"
							value={this.state.profile.sti_number ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="Straße"
							name="street"
							value={this.state.profile.street ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="PLZ"
							name="zip"
							value={this.state.profile.zip ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="Ort"
							name="city"
							value={this.state.profile.city ?? ""}
							onChange={this.handleChange}
						/>


						<div className="neo-spacer20" />

						<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Angaben zum Geschäftsführer</p>


						<input
							type="text"
							placeholder="Vorname"
							name="firstname"
							value={this.state.profile.firstname ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="Nachname"
							name="lastname"
							value={this.state.profile.lastname ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="Straße"
							name="gf_street"
							value={this.state.profile.gf_street ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="PLZ"
							name="gf_zip"
							value={this.state.profile.gf_zip ?? ""}
							onChange={this.handleChange}
						/>

						<input
							type="text"
							placeholder="Ort"
							name="gf_city"
							value={this.state.profile.gf_city ?? ""}
							onChange={this.handleChange}
						/>

						<div>
							<p class={"neo-smallTitle"} style={{textAlign: "left",paddingBottom: 10}}>Geburtstag</p>
							<input
								type="date"
								placeholder="Geburtstag"
								name="gf_birthdate"
								value={this.state.profile.gf_birthdate ?? ""}
								onChange={this.handleChange}
							/>
						</div>


						<NeoCheckbox
							style={{marginTop: 10}}
							showTermsButton={true}
							name="alternative_delievery_address"
							label="Abweichender  Lieferant"
							value={this.state.alternative_delievery_address}
							onChange={this.handleDeliveryAddress}
						/>

						<div className="neo-spacer20" />

						{this.state.alternative_delievery_address ? <>
							<input
								type="text"
								placeholder="Firmenname"
								name="delievery_name"
								value={this.state.profile.delievery_name ?? ""}
								onChange={this.handleChange}
							/>

							<input
								type="text"
								placeholder="Straße"
								name="delievery_street"
								value={this.state.profile.delievery_street ?? ""}
								onChange={this.handleChange}
							/>

							<input
								type="text"
								placeholder="PLZ"
								name="delievery_zip"
								value={this.state.profile.delievery_zip ?? ""}
								onChange={this.handleChange}
							/>

							<input
								type="text"
								placeholder="Ort"
								name="delievery_city"
								value={this.state.profile.delievery_city ?? ""}
								onChange={this.handleChange}
							/>

							<div className="neo-spacer20" />
						</>: null}


						<p className="neo-smallTitle" style={{marginTop: 0, marginBottom: 15, textAlign: "left"}}>Bankdaten</p>

						<input
							type="text"
							placeholder="IBAN (optional)"
							name="iban"
							value={this.state.profile.iban ?? ""}
							onChange={this.handleChange}
						/>
						<input
							type="text"
							placeholder="BIC (optional)"
							name="bic"
							value={this.state.profile.bic ?? ""}
							onChange={this.handleChange}
						/>

						<div className="neo-spacer20" />
						<div className="neo-spacer20" />

						<textarea
							type="text"
							placeholder="Notizen (optional)"
							name="note"
							value={this.state.note}
							onChange={this.handleNoteChange}
						>{this.state.note}</textarea>

						<div className="neo-card">


							<div
								className="neo-card-cell"
								onClick={() => this.setState({ok: "done"})}
							>
								<input
									type="radio"
									id="ok_new"
									name="ok"
									value="done"
									checked={this.state.ok === "done"}
									onChange={this.onRadioDatenschutzChange}
								/>
								<label htmlFor="none" className="neo-text">
									<span>Hiermit bestätige ich, dass mir die Einwilligung zur Verarbeitung personenbezogener Daten einschließlich Bonitätsprüfung und Übermittlung einer Ausweisskopie vorliegt.</span>
								</label>

								<a className="termsButton" href={CLEAR_BASE_URL+"/Einwilligung.pdf"} target={"_blank"}>
									Ansehen
								</a>
							</div>
						</div>
						<NeoButton
							title="Antrag senden & Dokumente hochladen"
							showLoadingState={this.state.isSending}
							onClick={this.sendOffer}
							disabled={this.state.ok != "done"}
						/>
						{this.ErrorsView()}

						{/* Kundendaten Ende */}
					</> : null
				}





				<div className="neo-spacer20" />

				{
					this.state.saved ? <div style={{position: "fixed", right: 10, bottom: 10}}>
						<NeoButton
							style={{width: "auto", paddingLeft: 10, paddingRight: 10}}
							title="speichern" />
					</div> : null
				}

			</div>
		);
	}

	totalPrice() {
		let objectPrice = this.state.objectPrice ? this.state.objectPrice : "0";
		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");


		return parseFloat(objectPrice);
	}

	objectPrice() {
		let objectPrice = this.state.objectPrice ? this.state.objectPrice : "0";
		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");

		return parseFloat(objectPrice);
	}

	accessoriesPrice() {
		let accessoriesPrice = this.state.accessoriesPrice
			? this.state.accessoriesPrice
			: "0";
		accessoriesPrice = accessoriesPrice.replace(".", "");
		accessoriesPrice = accessoriesPrice.replace(",", ".");

		return parseFloat(accessoriesPrice);
	}
}

export default CreateOffer;
