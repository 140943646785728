import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoForm.css";

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import ConfirmationView from "../ConfirmationView";
import ErrorBox from "../Components/ErrorBox";
import NeoButton from "../Button/NeoButton";

class SetNewPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pin: "",
			newPassword: "",
			newPasswordConfirmation: "",

			errors: [],
			errorMessage: null,
			isSent: false,
			isSending: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.changePassword = this.changePassword.bind(this);
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
	}

	changePassword() {
		this.setState({ isSending: true });

		axios
			.post(BASE_URL + "/globals/password-reset", {
				pin: this.state.pin,
				new_password: this.state.newPassword,
				new_password_confirmation: this.state.newPasswordConfirmation,
			})
			.then(() => {
				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	Form() {
		return (
			<div>
				<input
					id="fake_user_name"
					name="fake_user[name]"
					style={{ position: "absolute", top: "-100px", display: "none" }}
					type="text"
					value="Safari Autofill Me"
				/>

				<input
					type="text"
					placeholder="PIN"
					name="pin"
					value={this.state.pin}
					autocomplete="new-password"
					onChange={this.handleChange}
				/>
				<input
					type="password"
					placeholder="Neues Passwort"
					autocomplete="new-password"
					name="newPassword"
					value={this.state.newPassword}
					onChange={this.handleChange}
				/>
				<input
					type="password"
					placeholder="Neues Passwort wiederholen"
					autocomplete="new-password"
					name="newPasswordConfirmation"
					value={this.state.newPasswordConfirmation}
					onChange={this.handleChange}
				/>

				<p className="neo-text">
					Das Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie
					Buchstaben enthalten.
				</p>

				{this.ErrorsView()}

				<NeoButton
					title="Passwort ändern"
					showLoadingState={this.state.isSending}
					onClick={this.changePassword}
					disabled={
						this.state.pin.length === 0 ||
						this.state.newPassword.length === 0 ||
						this.state.newPasswordConfirmation.length === 0
					}
				/>
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Neues Passwort erstellen" />

				<div className="neo-content">
					{this.state.isSent ? (
						<ConfirmationView
							text="Sie haben Ihr Passwort erfolgreich geändert."
							icon="confirmation.png"
						/>
					) : (
						this.Form()
					)}
				</div>

				<Footer />
			</div>
		);
	}
}

export default SetNewPassword;
