import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "./NeoDropdown.css";

import PropTypes from "prop-types";

class NeoDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {isExpanded: false, current: props.value, allowdropdown: typeof props.allowdropdown != "undefined" ? true : false}


		this.toggleExpansion = this.toggleExpansion.bind(this);
		this.changeSelection = this.changeSelection.bind(this);
	}

	render() {
		let c = typeof this.state.current == "string" ? this.state.current.toLowerCase().replace(" %", "").replace(" €", "") : this.state.current;
		console.log("sadsadas123123",c)
		return (
			<>
				{
					this.state.allowdropdown ? <div
						className="neo-dropdown" style={{ height: this.state.isExpanded ? 300 : 60 }}

						onClick={this.toggleExpansion}
					>
						<p className="neo-dropdown-title">{ this.props.value == "" ? "Keine": this.props.value+" "+this.props.trailingString }</p>

						{this.props.items.map((item) => (
							<p
								key={item.value}
								className="neo-dropdown-title"
								style={{verticalAlign: "middle", color: item.value.toString().replace(" %", "").replace(" €", "").toLowerCase() == (c) ? "#fff" : "#587faa",backgroundColor: item.value.toString().toLowerCase() == (c)  ? "#587faa" : "rgba(255,255,255,.5)", width: window.innerWidth < 800 ? "46.5%" :"48%", display: "inline-block"}}
								onClick={() => this.changeSelection(item)}
							>
								<span style={{fontSize: window.innerWidth < 800 ? 12 : 14,paddingTop: typeof item.sub != "undefined" ? 8 : 0, display: "block"}}>{item.name == "VT" ? "TA": item.name} {item.name == "keine" ? "": this.props.trailingString}</span>
								{typeof item.sub != "undefined" ? <span style={{fontSize: window.innerWidth < 800 ? 12 : 14,display: "block"}}>{item.sub}</span>: null}
							</p>
						))}
					</div> : <div style={{ flexDirection: "row" }} onClick={this.toggleExpansion}>
						{this.props.items.map((item) => (
							<p
								key={item.value}
								className="neo-dropdown-title"
								style={{verticalAlign: "middle", color: item.value.toString().replace(" %", "").replace(" €", "").toLowerCase() == (c) ? "#fff" : "#587faa",backgroundColor: item.value.toString().toLowerCase() == (c)  ? "#587faa" : "rgba(255,255,255,.5)", width: window.innerWidth < 800 ? "46.5%" :"48%", display: "inline-block"}}
								onClick={() => this.changeSelection(item)}
							>
								<span style={{fontSize: window.innerWidth < 800 ? 12 : 14,paddingTop: typeof item.sub != "undefined" ? 8 : 15, display: "block"}}>{item.name == "VT" ? "TA": item.name} {item.name == "keine" ? "": this.props.trailingString}</span>
								{typeof item.sub != "undefined" ? <span style={{fontSize: window.innerWidth < 800 ? 12 : 14,display: "block"}}>{item.sub}</span>: null}
							</p>
						))}
					</div>
				}
			</>
		);
	}

	changeSelection(item) {
		this.setState({current: item.value})

		this.props.onChangeValue(item);
	}

	toggleExpansion() {
		this.setState({ isExpanded: !this.state.isExpanded });
	}
}

NeoDropdown.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array,
	labeledItems: PropTypes.array,
	trailingString: PropTypes.string.isRequired,
};

export default NeoDropdown;
