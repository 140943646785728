import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoCell.css";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

function ShopCell(props) {
	let history = useHistory();

	function showDetails() {
		if (props.disableClick === false) {
			history.push("/shops/" + props.uuid);
		}
	}

	return (
		<div className="neo-cell" onClick={showDetails}>
			<div className="neo-cell-horizontalContainer">
				<div className="neo-cell-verticalContainer">
					<p className="neo-cell-title">{props.title}</p>
					<p className="neo-cell-subtitle">{props.subtitle1}</p>
					<p className="neo-cell-subtitle">{props.subtitle2}</p>
				</div>

				{props.badge ? <p className="neo-cell-badge">{props.badge}</p> : null}
			</div>
		</div>
	);
}

ShopCell.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle1: PropTypes.string.isRequired,
	subtitle2: PropTypes.string.isRequired,
	badge: PropTypes.string,
	disableClick: PropTypes.bool.isRequired,
};

export default ShopCell;
