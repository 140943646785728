import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import NeoButton from "../Button/NeoButton";
import { useState, useEffect } from "react";

function AcceptInviteView(props) {
	let history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		acceptInvite();
	}, []);

	function acceptInvite() {
		setIsLoading(true);

		const { id } = props.match.params;

		axios
			.get(BASE_URL + "/user/company/invite-accept/" + id)
			.then((data) => {
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);

				if (error.response) {
					setError(error.response.data.message);
					console.log(error.response);
				}
			});
	}

	function showMainMenu() {
		history.push("/");
	}

	function LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	function SuccessView() {
		return (
			<div>
				<p className="neo-text" style={{ maxWidth: "500px" }}>
					Erfolgreich beigetreten. Sie können nun beim Erstellen von neuen
					Anfragen auswählen, ob Sie Angebote persönlich oder über Ihren
					Arbeitgeber erhalten möchten.
				</p>

				<NeoButton title="Zurück zur Startseite" onClick={showMainMenu} />
			</div>
		);
	}

	function ErrorView() {
		return (
			<div>
				<p className="neo-text" style={{ maxWidth: "500px" }}>
					Einladung konnte nicht angenommen werden.
				</p>

				<NeoButton title="Zurück zur Startseite" onClick={showMainMenu} />
			</div>
		);
	}

	return (
		<div className="neo-app">
			<NavigationBar title="Teilnehmer" />
			<div className="neo-content">
				{isLoading ? LoadingView() : error ? ErrorView() : SuccessView()}
			</div>

			<Footer />
		</div>
	);
}

export default AcceptInviteView;
