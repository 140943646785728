import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";

import axios from "axios";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import OfferCell from "./OfferCell";
import LoadingSpinner from "./LoadingSpinner";
import { BASE_URL } from "./Constants";
import NeoDropdown from "./Dropdown/NeoDropdown";
import EmptyStateView from "./EmptyStateView";
import moment from "moment";
import Cookies from "js-cookie";

class VendorOffers extends React.Component {
	state = {
		isLoading: true,
		offers: [],
		status: "open",
		search: "",
	};

	constructor(props) {
		super(props);

		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.loadOffers = this.loadOffers.bind(this);
		this.loadHandovers = this.loadHandovers.bind(this);
	}

	componentDidMount() {
		this.loadOffers(this.state.status);
	}

	isHandoverOffers() {
		return this.props.history.location.pathname.includes("/handover");
	}

	isDirectOffers() {
		return this.props.history.location.pathname.includes("/direct");
	}

	loadHandovers(status) {
		let branchID = Cookies.get("CurrentStoreID");

		let offers = null;
		if(this.isDirectOffers()){
			offers = "/vendor/offer-request?branch=" + branchID;
		}else if(this.isHandoverOffers()){
			offers = "/vendor/offers?branch=" + branchID;
		}else{
			offers = "/vendor/offers?branch=" + branchID;
		}

		let parameters =
			offers +
			"&status=" +
			status +
			"&search=" +
			this.state.search;

		axios
			.get(BASE_URL + parameters)
			.then((result) => {
				const offers = result.data.data;

				this.setState({ offers, isLoading: false });
			})
			.catch(() => {});
	}

	loadOffers(status) {
		let branchID = Cookies.get("CurrentStoreID");

		let parameters =
			(this.isDirectOffers()
				? "/vendor/offer-request?branch=" + branchID
				: "/vendor/offers?branch=" + branchID) +
			"&status=" +
			status +
			"&search=" +
			this.state.search;

		axios
			.get(BASE_URL + parameters)
			.then((result) => {
				const offers = result.data.data;

				this.setState({ offers, isLoading: false });
			})
			.catch(() => {});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	showDetails(id) {
		this.props.history.push("/vendor/offers/review/" + id);
	}

	Cell(offer) {
		return this.isDirectOffers() ? (
			<div className="neo-cell" onClick={() => this.showDetails(offer.id)}>
				<div className="neo-cell-horizontalContainer">
					<div className="neo-cell-verticalContainer">
						<p className="neo-cell-title">{offer.object_manufacturer}</p>
						<p className="neo-cell-subtitle">{offer.object_name}</p>
						<p className="neo-cell-subtitle">
							{offer.user.profile.firstname} {offer.user.profile.lastname}
						</p>
						<p className="neo-cell-subtitle">
							{moment(offer.created_at).format("DD.MM.YYYY HH:mm")} Uhr
						</p>
					</div>
				</div>
			</div>
		) : (
			<OfferCell
				key={offer.id}
				offer={offer}
				title={offer.object_name}
				subtitle1={offer.user.company_name}
			/>
		);
	}

	handleChangeFilter = (status) => {
		this.setState({ status: status.value });

		this.loadOffers(status.value);
	};

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.loadOffers(this.state.status);
		}
	}

	handleSearchFieldChange(event) {
		this.setState({
			search: event.target.value,
		});
	}

	OffersList() {
		return (
			<div>
				{this.isDirectOffers() === false && (
					<div>
						<input
							type="text"
							placeholder="Suchen"
							value={this.state.search}
							onChange={this.handleSearchFieldChange}
							onKeyPress={this.handleKeyPress}
						/>

						<NeoDropdown
							title="Status"
							items={[
								{ name: "Offen", value: "open" },
								{ name: "Abgeschlossen", value: "completed" },
							]}
							value={this.state.status === "open" ? "Offen" : "Abgeschlossen"}
							onChangeValue={this.handleChangeFilter}
							trailingString=""
						/>
					</div>
				)}

				{this.state.offers.length > 0 ? (
					this.state.offers.map((offer) => this.Cell(offer))
				) : (
					<EmptyStateView message="Keine Anfragen" />
				)}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					<p className="neo-bar-title">
						{this.isDirectOffers()
							? "Direktanfragen von Kunden" :
							this.isHandoverOffers() ?
								"Leasingübergaben"
									: "Leasinganfragen"}
					</p>

					{this.state.isLoading ? this.LoadingView() : this.OffersList()}
				</div>

				<Footer />
			</div>
		);
	}
}

export default VendorOffers;
