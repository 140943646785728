import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import "./Button/NeoButton.css";
import Footer from "./Components/Footer";
import MenuButton from "./MenuButton";
import AuthManager from "./Auth/AuthManager";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL, INSURANCE_URL } from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import NavigationBar from "./NavigationBar/NavigationBar";
import NeoButton from "./Button/NeoButton";
import MultiStoreBanner from "./Components/MultiStoreBanner";
import {useHistory} from "react-router-dom";
import NeoDropdown from "./Dropdown/NeoDropdown";
import EmptyStateView from "./EmptyStateView";
import moment from 'moment';

class Main extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isLoadingData: false,
			invites: [],
			data: [],
			drafts: [],
			data_backup: [],
			drafts_backup: [],
			status: "done"
		};

		this.showOffers = this.showOffers.bind(this);
		this.showVendorDirectOffers = this.showVendorDirectOffers.bind(this);
		this.showVendorDirectHandovers = this.showVendorDirectHandovers.bind(this);
		this.showCustomerDirectOffers = this.showCustomerDirectOffers.bind(this);
		this.showShops = this.showShops.bind(this);
		this.showContact = this.showContact.bind(this);
		this.showProfile = this.showProfile.bind(this);
		this.showMyCompany = this.showMyCompany.bind(this);
		this.showCalc = this.showCalc.bind(this);
		this.showQRScan = this.showQRScan.bind(this);
		this.logout = this.logout.bind(this);
		this.showIDCheck = this.showIDCheck.bind(this);
		this.loadInvites = this.loadInvites.bind(this);
		this.acceptInvite = this.acceptInvite.bind(this);
		this.declineInvite = this.declineInvite.bind(this);

		this.handleChangeFilter = this.handleChangeFilter.bind(this);
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	componentDidMount() {
		this.loadDashboard();

		this.setState({ isLoadingInvites: false });
		/*
		if (this.isVendorPage()) {
			this.setState({ isLoadingInvites: false });
		} else {
			this.loadInvites();
		}*/
	}

	handleChangeFilter = (status) => {
		this.setState({ isLoadingData: true, status: status.value });

		setTimeout(function() {
			this.setState({isLoadingData: false});
		}.bind(this),2000);

		//this.loadOffers(status.value);
	};

	loadDashboard() {
		axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
			"AccessToken"
		)}`;

		let branchID = Cookies.get("CurrentStoreID") ?? "";

		axios
			.get(
				BASE_URL + "/vendor/dashboard?branch=" + branchID
			)
			.then((result) => {
				const dashboard = result.data;

				this.setState({ drafts_backup: dashboard.offers_draft, data_backup: dashboard.offers,  drafts: dashboard.offers_draft, data: dashboard.offers, isLoading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	loadInvites() {
		axios
			.get(BASE_URL + "/user/company/invites")
			.then((result) => {
				const invites = result.data.invites ?? [];
				this.setState({ invites, isLoadingInvites: false });
			})
			.catch((error) => {
				this.setState({ isLoadingInvites: false });
				console.log(error.response);
			});
	}


	showDrafts() {
		return this.state.isLoadingData ? this.LoadingView() : this.state.drafts.length > 0 ? (
			<table className={"styled-table"} style={{borderRadius: 10, border: "1px solid gainsboro", borderCollapse: "collapse"}}>
				<thead style={{borderRadius: 10}}>

				<tr>
					<th>Nr.</th>
					<th>Datum</th>
					<th>Kunde</th>
					<th>Objekt</th>
					<th>Wert (€)</th>
					<th>Versicherung</th>
					<th>Rate (€)</th>
					<th>Laufzeit</th>
					<th></th>
				</tr>



				</thead>
				<tbody>
				{
					this.state.drafts.map((item, key) => <tr>
						<td>{item.id}</td>
						<td>{moment(item.created_at).format("DD.MM.Y")}<br />{moment(item.created_at).format("HH:MM")} Uhr</td>
						<td>{typeof item.profile == "undefined" || item.profile == null ? "-" : item.profile.company_name}</td>
						<td>{item.object_name == null ? "-" : item.object_name}</td>
						<td>{item.object_price == null ? "-" : (item.object_price / 100).toLocaleString()}</td>
						<td>{item.insurance_fee != null ? "Nein" : "Ja"}</td>
						<td>{item.leasing_rate == null ? "-" : ((item.leasing_rate).toLocaleString())}</td>
						<td>{item.leasing_rate == null ? "-" : (item.leasing_duration)}</td>
						<td>
							<NeoButton
								style={{marginTop: 10, cursoir: "pointer"}}
								title="bearbeiten"
								onClick={() => this.props.history.push("/vendor/offers/"+ item.id+"/update")}
							/>
						</td>
					</tr>)
				}

				</tbody>
			</table>
		) : (
			<div className="neo-card" style={{paddingTop: 1}}><EmptyStateView message="Keine Daten gefunden" /></div>
		)
	}

	showData() {
		return this.state.isLoadingData ? this.LoadingView() : this.state.data.length > 0 ? (
			<table className={"styled-table"} style={{borderRadius: 10, border: "1px solid gainsboro", borderCollapse: "collapse"}}>
				<thead style={{borderRadius: 10}}>

				<tr>
					<th>Nr.</th>
					<th>Datum</th>
					<th>Kunde</th>
					<th>Objekt</th>
					<th>Wert (€)</th>
					<th>Versicherung</th>
					<th>Rate (€)</th>
					<th>Laufzeit</th>
					<th></th>
				</tr>
				</thead>
				<tbody>

				{
					this.state.data.map((item, key) => <tr>
						<td>{item.id}</td>
						<td>{moment(item.created_at).format("DD.MM.Y")}<br />{moment(item.created_at).format("HH:MM")} Uhr</td>
						<td>{item.profile == null ? "-" : item.profile.company_name}</td>
						<td>{item.object_name == null ? "-" : item.object_name}</td>
						<td>{item.object_price == null ? "-" : (item.object_price).toLocaleString()}</td>
						<td>{item.insurance_fee == null ? "Nein" : "Ja"}</td>
						<td>{item.leasing_rate == null ? "-" : ((item.leasing_rate).toLocaleString())}</td>
						<td>{item.leasing_rate == null ? "-" : (item.leasing_duration)}</td>
						<td>
							<NeoButton
								style={{marginTop: 10}}
								onClick={() => this.props.history.push("/vendor/offers/" + item.id + "/upload")}
								title="Datei uploaden"
							/>
						</td>
					</tr>)
				}

				</tbody>
			</table>
		) : (
			<div className="neo-card" style={{paddingTop: 1}}><EmptyStateView message="Keine Daten gefunden" /></div>
		)
	}

	MainVendor() {
		return (
			<div>
				<div style={{marginBottom: 20}}>
					<NeoDropdown
						title="Status"
						items={[
							{ name: "Anträge", value: "done" },
							{ name: "Entwürfe", value: "draft" },
						]}
						value={this.state.status === "done" ? "Anträge" : "Entwürfe"}
						onChangeValue={this.handleChangeFilter}
						trailingString=""
					/>
				</div>

				<div style={{paddingBottom: window.innerWidth < 1000 ? 10 : 0, display: window.innerWidth < 1000 ? "block" : "flex", flexDirection: window.innerWidth < 1000 ? "column" : "row"}}>
					<div style={{flex: 1}}><p className="neo-bar-title">{this.state.status === "done" ? "Anträge" : "Entwürfe"}</p></div>
					<div style={{flex: 1, textAlign: window.innerWidth < 1000 ? "center" : "right"}}>
						<NeoButton
							style={{width: "auto", paddingLeft: 20, paddingRight: 20, marginRight: 20}}
							title="Neuer Antrag"
							onClick={() => this.createOffer()}
						/>

					</div>
				</div>
				<input
					type="text"
					placeholder="Suchen"
					onKeyUp={(e) => {
						let foundDrafts = this.state.drafts_backup;
						let foundData = this.state.data_backup
						if(e.target.value.length > 0) {
						//foundDrafts = foundDrafts.filter(x => x.object_name.toLowerCase().indexOf(e.target.value) != -1);
						foundData = foundData.filter(x =>
							x.object_name.toLowerCase().indexOf(e.target.value) != -1
						);
						}else{}
						this.setState({drafts: foundDrafts, data: foundData})
					}}
				/>
				<div>

					{
						this.state.status !== "done" ? this.showDrafts() : null
					}

					{
						this.state.status === "done" ? this.showData() : null
					}

				</div>
			</div>
		);
		return (
			<div>


				<MenuButton
					title="Leasinganfragen"
					icon="Menu_Offers.png"
					onClick={this.showOffers}
					badge={this.state.dashboard.offerCount.toString()}
				/>
				<MenuButton
					title="Direktanfragen von Kunden"
					icon="Menu_Offers.png"
					onClick={this.showVendorDirectOffers}
					badge={this.state.dashboard.offerRequestCount.toString()}
				/>
				<MenuButton
					title="Übergaben"
					icon="Menu_Offers.png"
					onClick={this.showVendorDirectHandovers}
					badge={this.state.dashboard.offerRequestCount.toString()}
				/>
				<MenuButton
					title="Ausweisprüfung"
					icon="Menu_IDCheck.png"
					onClick={this.showIDCheck}
					badge={this.state.dashboard.idCardCheckCount.toString()}
				/>

				<MenuButton
					title="Versicherung / Inspektionen / Schadensmeldung"
					icon="Menu_Contact.png"
					onClick={this.showInsurancePage}
				/>

				<MenuButton
					title="Versicherungsbedingungen Dienstfahrrad"
					icon="Menu_Contact.png"
					onClick={() => this.showInsuranceBedingungenPage("dienstfahrrad")}
				/>
				<MenuButton
					title="Versicherungsbedingungen Poolfahrräder"
					icon="Menu_Contact.png"
					onClick={() => this.showInsuranceBedingungenPage("poolfahrrad")}
				/>

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<MenuButton
					title="Mein Profil"
					icon="Menu_Profile.png"
					onClick={this.showProfile}
				/>
				<MenuButton
					title="Kontakt"
					icon="Menu_Contact.png"
					onClick={this.showContact}
				/>

				<MenuButton
					title="Leasing Vorteilsrechner"
					icon="Menu_Offers.png"
					onClick={this.showCalc}
				/>

				<div className="neo-spacer20" />

				<button className="neo-button" onClick={this.logout}>
					Abmelden
				</button>

				<MultiStoreBanner />
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar showBackButton={false} />

				<div className="neo-content">
					{this.state.isLoading || this.state.isLoadingInvites
						? this.LoadingView()
						: this.isVendorPage()
						? this.MainVendor()
						: null}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	logout() {
		let authManager = new AuthManager();
		authManager.logout();

		/*
		this.props.history.push(
			this.isVendorPage() ? "/vendor/welcome" : "/welcome"
		);*/

		this.props.history.push("/vendor/welcome");
	}

	createOffer() {
		this.props.history.push("/vendor/offers/create");
	}

	showOffers() {
		this.props.history.push(this.isVendorPage() ? "/vendor/offers" : "/offers");
	}

	showVendorDirectHandovers() {
		this.props.history.push("/vendor/offers/handover");
	}

	showVendorDirectOffers() {
		this.props.history.push("/vendor/offers/direct");
	}

	showCustomerDirectOffers() {
		this.props.history.push("/offers/direct");
	}

	showShops() {
		this.props.history.push("/shops");
	}

	showCalc() {
		this.props.history.push("/rechner/expertenrechner");
	}

	showContact() {
		this.props.history.push(
			this.isVendorPage() ? "/vendor/contact" : "/contact"
		);
	}

	showProfile() {
		this.props.history.push(
			this.isVendorPage() ? "/vendor/profile" : "/profile"
		);
	}

	showMyCompany() {
		this.props.history.push("/profile/companygroup");
	}

	showQRScan() {
		this.props.history.push("/vendor/scan");
	}

	showIDCheck() {
		this.props.history.push("/vendor/idCheck");
	}

	showInsurancePage() {
		window.open(INSURANCE_URL, "_blank");
	}

	showInsuranceBedingungenPage(comein) {
		window.open("https://system.gslease.de/versicherung_"+comein+".pdf", "_blank");
	}

	CompanyInvitationCard(invite) {
		return (
			<div
				className="neo-card"
				key={invite.id}
				style={{ paddingBottom: "10px" }}
			>
				<p className="neo-text" style={{ maxWidth: "500px" }}>
					Sie wurden eingeladen, dem Unternehmen {invite.company.name}{" "}
					beizutreten.
				</p>

				<NeoButton
					title="Beitreten"
					onClick={() => this.acceptInvite(invite)}
				/>
				<NeoButton
					title="Löschen"
					isDestructive={true}
					onClick={() => this.declineInvite(invite)}
				/>
			</div>
		);
	}

	acceptInvite(invite) {
		this.props.history.push("/invite/" + invite.id);
	}

	declineInvite(invite) {
		axios
			.get(BASE_URL + "/user/company/invite-decline/" + invite.id)
			.then((data) => {
				this.loadInvites();
			})
			.catch((error) => {});
	}
}

export default Main;
