import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";

import axios from "axios";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import OfferCell from "./OfferCell";
import LoadingSpinner from "./LoadingSpinner";
import { BASE_URL } from "./Constants";
import EmptyStateView from "./EmptyStateView";
import Cookies from "js-cookie";

class VendorIDChecks extends React.Component {
	state = {
		isLoading: true,
		offers: [],
		status: "id_card_check",
	};

	constructor(props) {
		super(props);

		this.loadOffers = this.loadOffers.bind(this);
	}

	componentDidMount() {
		this.loadOffers(this.state.status);
	}

	loadOffers(status) {
		let branchID = Cookies.get("CurrentStoreID");

		axios
			.get(BASE_URL + "/vendor/offers?status=" + status + "&branch=" + branchID)
			.then((result) => {
				const offers = result.data.data;

				this.setState({ offers, isLoading: false });
			})
			.catch(() => {});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	Cell(offer) {
		return (
			<OfferCell
				key={offer.id}
				offer={offer}
				title={offer.object_name}
				subtitle1={offer.vendor.company_name}
			/>
		);
	}

	OffersList() {
		return (
			<div>
				{this.state.offers.map((offer) => this.Cell(offer))}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					<p className="neo-bar-title">Ausweisüberprüfung</p>

					{this.state.isLoading ? (
						this.LoadingView()
					) : this.state.offers.length > 0 ? (
						this.OffersList()
					) : (
						<EmptyStateView message="Keine Anfragen" />
					)}
				</div>

				<Footer />
			</div>
		);
	}
}

export default VendorIDChecks;
