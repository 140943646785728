import React from "react";
import "../Stylesheets/neo.css";
import { Link, useHistory } from "react-router-dom";
import { DEBUG_MODE_ENABLED } from "../Constants";

function Footer() {
	let history = useHistory();

	function isVendorPage() {
		return history.location.pathname.includes("/vendor");
	}
	let sh = window.location.href.indexOf("expertenrechner") != -1 ? true : false;
	return (
		<div className="neo-footer">
			{
				!sh ? <p className="neo-callout" style={{ marginBottom: "20px", marginTop: 0 }}>
					* Alle ausgeschriebenen Preise sind Nettopreise zzgl. USt.
				</p> : null
			}

			<Link to={isVendorPage() ? "/vendor/imprint" : "/imprint"}>
				Impressum, Datenschutz & AGB
			</Link>
			{DEBUG_MODE_ENABLED ? (
				<p
					className="neo-callout"
					style={{ marginBottom: 0, marginTop: "20px" }}
				>
					DEBUG MODE ACTIVE
				</p>
			) : null}
		</div>
	);
}

export default Footer;
