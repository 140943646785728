import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import PropTypes from "prop-types";

function LoadingSpinner(props) {
    return (
        <div className="neo-loadingSpinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
        </div>
    );
}

LoadingSpinner.propTypes = {
    title: PropTypes.string.isRequired,
};

export default LoadingSpinner;
