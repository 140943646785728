import React from 'react';
import '../Stylesheets/App.css';
import '../Stylesheets/neo.css';
import './NeoEditField.css';

import PropTypes from "prop-types";

class NeoEditField extends React.Component {

    isDateSupported() {
        var input = document.createElement('input');
        var value = 'a';
        input.setAttribute('type', 'date');
        input.setAttribute('value', value);
        return (input.value !== value);
    };

    render() {
        return (
            <div className="neo-editField-container">
                <p className="neo-editField-label">{ this.props.label }</p>

                {
                    this.props.name === "birthdate" ?
                        this.DateField()
                        :
                        <input type={ this.props.type ? this.props.type : "text" } placeholder={ this.props.disabled ? "Keine Angabe" : this.props.label } className="neo-editField-textField"
                               name={ this.props.name } value={ this.props.value }
                               onChange={ this.props.onChange } disabled={ this.props.disabled }
                        />
                }
            </div>
        );
    }

    DateField() {
        return (
            <input type="date" placeholder={ this.props.disabled ? "Keine Angabe" : this.props.label } className="neo-editField-textField"
                   name={ this.props.name } value={ this.props.value }
                   onChange={ this.props.onChange } disabled={ this.props.disabled }
            />
        );
    }
}

NeoEditField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};

export default NeoEditField;
