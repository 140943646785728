export const CLEAR_BASE_URL = "https://smartleasing.api.app-entwickler.hamburg";
export const BASE_URL = "https://smartleasing.api.app-entwickler.hamburg/api";

//export const CLEAR_BASE_URL = "https://portal.gslease.de";
//export const BASE_URL = "https://portal.gslease.de/api";

export const CUSTOMER_OFFER_SHARE_URL = "https://portal.gslease.de/direct/";
export const INSURANCE_URL = "https://ebike.schaden.alteos.com";

export const GOOGLE_MAPS_API_KEY = "AIzaSyAj0MvZjFEoLjRCyoM6KlCR3X_Yat5wPnA";
export const DEBUG_MODE_ENABLED = false;

export const EXAMPLE_CONTRACT_URL =
	"https://system.gslease.de/globals/contract";
export const CONTRACT_URL = "https://system.gslease.de/haendlervertrag.pdf";

export function titleForStatus(status) {
	switch (status) {
		case "open":
			return "Offen";
		case "declined_by_user":
			return "Abgelehnt";
		case "expired":
			return "Abgelaufen";
		case "id_card_not_readable":
			return "Personalausweis abgelehnt";
		case "withdrawn_by_vendor":
			return "Zurückgezogen";
		case "credit_check":
			return "Bonität in Prüfung";
		case "completed":
			return "Abgeschlossen";
		case "credit_denied":
			return "Bonität negativ";
		case "id_card_check":
			return "Personalausweis in Prüfung";
		default:
			return "Kein Status";
	}
}

export function nameForInsurance(insurance) {
	switch (insurance) {
		case "business_and_private":
			return "Fahrradversicherung für Dienstradleasing";
		case "only_business":
			return "Fahrradversicherung für Poolfahrzeuge zur gewerblichen Nutzung";
		default:
			return "Ich versichere selbst (Ist der Leasinggesellschaft nachzuweisen)";
	}
}

export function colorCodeForStatus(status) {
	switch (status) {
		case "open":
			return "#f4b816";
		case "declined_by_user":
			return "#d82828";
		case "expired":
			return "#d82828";
		case "id_card_not_readable":
			return "#d82828";
		case "withdrawn_by_vendor":
			return "#663df8";
		case "credit_check":
			return "#f36815";
		case "completed":
			return "#60be0c";
		case "credit_denied":
			return "#d82828";
		case "id_card_check":
			return "#f36815";
		default:
			return "black";
	}
}

export function iconForStatus(status) {
	switch (status) {
		case "open":
			return "Status_Open.png";
		case "declined_by_user":
			return "Status_Declined.png";
		case "expired":
			return "Status_Expired.png";
		case "id_card_not_readable":
			return "Status_ID_Card_Not_Readable.png";
		case "withdrawn_by_vendor":
			return "Status_Withdrawn.png";
		case "credit_check":
			return "Status_Credit_Check.png";
		case "completed":
			return "Status_Completed.png";
		case "credit_denied":
			return "Status_Credit_Denied.png";
		case "id_card_check":
			return "Status_ID_Card_Check.png";
		default:
			return "Status_Open.png";
	}
}
