import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import PropTypes from "prop-types";

function EmptyStateView(props) {

    return (
        <div className="neo-messageContainer">
            <p className="neo-text">
                {
                    props.message ? props.message : "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                }
            </p>
        </div>
    );
}

EmptyStateView.propTypes = {
    message: PropTypes.string,
};

export default EmptyStateView;
