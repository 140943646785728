import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoForm.css";

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import ConfirmationView from "../ConfirmationView";
import ErrorBox from "../Components/ErrorBox";
import NeoButton from "../Button/NeoButton";

class ChangePassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			oldPassword: "",
			newPassword: "",
			newPasswordConfirmation: "",

			errors: [],
			errorMessage: null,
			isSent: false,
			isSending: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.changePassword = this.changePassword.bind(this);
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});
	}

	changePassword() {
		this.setState({ isSending: true });

		var accountType = this.isVendorPage() ? "Vendor" : "User";

		axios
			.post(BASE_URL + "/globals/password-change", {
				old_password: this.state.oldPassword,
				new_password: this.state.newPassword,
				new_password_confirmation: this.state.newPasswordConfirmation,
				provider: accountType,
			})
			.then(() => {
				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	Form() {
		return (
			<div>
				<input
					type="password"
					placeholder="Aktuelles Passwort"
					name="oldPassword"
					value={this.state.oldPassword}
					onChange={this.handleChange}
				/>
				<input
					type="password"
					placeholder="Neues Passwort"
					name="newPassword"
					value={this.state.newPassword}
					onChange={this.handleChange}
				/>
				<input
					type="password"
					placeholder="Neues Passwort wiederholen"
					name="newPasswordConfirmation"
					value={this.state.newPasswordConfirmation}
					onChange={this.handleChange}
				/>

				<p className="neo-text">
					Das Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie
					Buchstaben enthalten.
				</p>

				{this.ErrorsView()}

				<NeoButton
					title="Passwort ändern"
					showLoadingState={this.state.isSending}
					onClick={this.changePassword}
					disabled={
						this.state.oldPassword.length === 0 ||
						this.state.newPassword.length === 0 ||
						this.state.newPasswordConfirmation.length === 0
					}
				/>
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Mein Passwort ändern" />

				<div className="neo-content">
					<p className="neo-bar-title">Passwort ändern</p>

					{this.state.isSent ? (
						<ConfirmationView
							text="Das Passwort wurde erfolgreich geändert."
							icon="confirmation.png"
						/>
					) : (
						this.Form()
					)}
				</div>

				<Footer />
			</div>
		);
	}
}

export default ChangePassword;
