import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import {BASE_URL} from "./Constants";
import ErrorView from "./ErrorView";

class Imprint extends React.Component {

    state = {
        isLoading: true,
        content: null
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        axios.get(BASE_URL + "/globals/content")
            .then(result => {
                const content = result.data;

                this.setState({ content, isLoading: false });
            })
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }
    
    ImprintView() {
        return (
            this.props.showNavBar ?? true ?
                <div className="neo-app">
                    <NavigationBar/>

                    <div className="neo-content">
                        {
                            this.state.isLoading ? this.LoadingView() : this.state.content.data != null ? this.Content() : <ErrorView/>
                        }
                    </div>

                    <Footer/>
                </div>
                :
                <div className="neo-content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.state.content.data != null ? this.Content() : <ErrorView/>
                    }
                </div>
        );
    }

    Content() {
        return (
            <div>
                <p className="neo-mediumTitle" id="leading">Impressum</p>
                <p className="neo-text" id="leading">
                    { this.state.content.data.imprint[0].value }
                </p>

                <div className="neo-divider" />

                <p className="neo-mediumTitle" id="leading">AGB</p>
                <p className="neo-text" id="leading">
                    { this.state.content.data.terms[0].value }
                </p>

                <div className="neo-divider" />

                <p className="neo-mediumTitle" id="leading">Datenschutz</p>
                <p className="neo-text" id="leading">
                    { this.state.content.data.privacy[0].value }
                </p>
            </div>
        )
    }


    render() {
        return (
            this.ImprintView()
        );
    }
}

export default Imprint;
