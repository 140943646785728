import React from 'react';
import './Stylesheets/App.css';
import './Stylesheets/neo.css';
import NeoButton from "./Button/NeoButton";
import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Components/Footer";
import {BASE_URL} from "./Constants";
import axios from "axios";
import ErrorBox from "./Components/ErrorBox";
import AuthManager from "./Auth/AuthManager";

class DeleteAccount extends React.Component {

    state = {
        password: "",

        errors: null,
        errorMessage: null,
        isSending: false
    }

    isVendorPage() {
        return this.props.history.location.pathname.includes("/vendor");
    }

    constructor(props) {
        super(props);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    handleFieldChange(event) {
        this.setState({ password: event.target.value });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    render() {
        return (
            <div className="neo-app">
                <NavigationBar title="Konto löschen" />

                <div className="neo-content">
                    <div className="neo-iconCircle" style={{ backgroundImage: "url('/trash.png')"}}/>

                    <p className="neo-text">Geben Sie Ihr Passwort ein, um das Löschen Ihres Kontos zu bestätigen. Beachten Sie, dass alle Daten von Ihnen gelöscht und alle offenen Anfragen abgebrochen werden. Bestehende Leasing-Verträge sind davon nicht betroffen.</p>
                    <input type="password" placeholder="Mein Passwort" value={ this.state.password } onChange={ this.handleFieldChange } />

                    {
                        this.ErrorsView()
                    }

                    <div className="neo-spacer20" />

                    <NeoButton
                        title="Konto löschen"
                        showLoadingState={ this.state.isSending }
                        isDestructive={ true }
                        onClick={ this.deleteAccount }
                        disabled={ this.state.password.length === 0 }
                    />
                </div>

                <Footer/>
            </div>
        );
    }

    deleteAccount() {
        this.setState({ isSending: true })

        axios.post(BASE_URL + (this.isVendorPage() ? '/vendor/profile-delete' : '/user/profile-delete'), {
            password: this.state.password,
        })
            .then(() => {
                let authManager = new AuthManager();
                authManager.logout();

                this.props.history.push(this.isVendorPage() ? '/vendor' : '/');
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }
}

export default DeleteAccount;
